import React, { Component } from 'react';
import SideMenu from './sideMenu.js';
import graph1 from './images/graph1.png';
import graph3 from './images/sentimentGraph.png';
import blGirl from './images/photo.png';
import vdoChat from './images/vdo_chat.png';

import g1 from './images/g1.png';
import g2 from './images/g2.png';
import g3 from './images/g3.png';
import g4 from './images/g4.png';
import au from './images/au.png';
import sort from './images/sort1.png';
import prev from './images/arrow_prev.png';
export default class LiveMonitoring2 extends Component {
    onClick_elBack= (ev) =>{
        this.props.history.push("/livemonitoring");
      }
    render() {
        return (
            <div id="Live_monitoring2" className="commonWidthHeight">
                <SideMenu/>
                <div className="middlePanelOthers">
                <div id="HeaderOthers" style={{marginTop:'14px'}}>
                    <img src={prev} alt="prev" className="backlm2 type_a" onClick={this.onClick_elBack}/>
                    <div id="Interaction_Boardlm2">
                        <ul className="ulForlm2">
                            <li>
                                <span id="SentimentSubHeaderTxt">Live Monitoring </span>
                                <div id="SentimentHeaderTxt">Whatsapp #06</div>
                            </li>
                            <li><div id="todaylm2">TODAY <i className="fa fa-caret-right caret-right"></i></div></li>
                            <li className="ml20 mt12" style={{color:'#FF3153'}}>
                                <span style={{fontSize:'24px'}}>06</span>
                                <label id="SentimentSubHeaderTxt" style={{fontSize:'10px',color:'#FF3153'}} className="ml10">Need Agent<br/> Attention </label>
                            </li>
                            <li className="ml20 mt12" style={{color:'#87B0E6'}}>
                                <span style={{fontSize:'24px'}}>03</span>
                                <label id="SentimentSubHeaderTxt" style={{fontSize:'10px',color:'#87B0E6'}} className="ml10">Resolved By<br/> Agent </label>
                            </li>
                            <li className="ml20 mt12" style={{color:'#008CFF'}}>
                                <span style={{fontSize:'24px'}}>01</span>
                                <label id="SentimentSubHeaderTxt" style={{fontSize:'10px',color:'#008CFF'}} className="ml10">Being<br/> Handled </label>
                            </li>
                            <li className="ml20 mt12" style={{color:'#CEE2FD'}}>
                                <span style={{fontSize:'24px'}}>413</span>
                                <label id="SentimentSubHeaderTxt" style={{fontSize:'10px',color:'#CEE2FD'}} className="ml10">No Action<br/> Required</label>
                            </li>
                            <li className="ml20 mt12">
                                <span style={{fontSize:'24px'}}>483</span>
                                <label id="SentimentSubHeaderTxt" style={{color:'#24527E'}} className="ml10">Live<br/> Interactions </label>
                            </li>
                            <li className="ml20 mt12">
                                <span style={{fontSize:'24px'}}>12</span>
                                <label id="SentimentSubHeaderTxt" style={{color:'#24527E'}} className="ml10">Active<br/> Bots </label>
                            </li>
                            <li className="ml10 mt10"><img src={sort} alt="sort"/></li>
                            <li className="ml10 mt10"><img src={au} alt="au"/></li>
                        </ul>
                    </div>
                </div>
                
                
                <div id="Group_2_A1_Group_58">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-2">
                                <img src={graph1} alt="graph1"/>
                            </div>
                            <div className="col-md-4 sentiment-align_1 white-background">
                                <div className="col-md-12">
                                    <img src={blGirl} className="imgHeaderIcon_sentiment_social_blGirl" alt="happy"/>
                                    <div className="divHeaderTxt">
                                        <div id="SentimentHeaderTxt">Ryan Landy</div>
                                        <p id="SentimentSubHeaderTxt">Last seen 43 minutes ago</p>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <img src={vdoChat} className="text-left margin-align" alt="vdoChat"/>
                                </div>
                                <div className="col-md-12 margin-align">
                                    <div className="chat-message-bot">I found a flaw here</div>
                                </div>
                                <div className="col-md-12">
                                    <div className="chat-message-user margin-align">Hey Ryan
                                        I can see you are not
                                        satisfied with your product.
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="chat-message-user margin-align">
                                        Have you tried this option?
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="chat-message-user margin-align">
                                        Please do
                                    </div>
                                </div>
                                <div className="col-md-12 margin-align_30">
                                    <textarea className="txtTypeChat"></textarea>
                                </div>
                            </div>
                            <div className="col-md-4 sentiment-align_2 white-background">
                                <div className="col-md-12">
                                    <div className="divHeaderTxt">
                                        <div id="SentimentHeaderTxt">Sentiment</div>
                                        <p id="SentimentSubHeaderTxt">Last seen 43 minutes ago</p>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <img src={graph3} className="margin-align" alt="graph3" style={{width:'300px'}}/>
                                </div>
                                <div className="col-md-12 margin-align">
                                    <div className="divHeaderTxt">
                                        <div id="SentimentHeaderTxt">Current State : Angry</div>
                                    </div>
                                </div>
                                <div className="col-md-12 margin-align" style={{clear:'both'}}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <img src={g2} alt="g2"/>
                                        </div>
                                        <div className="col-md-6">
                                            <img src={g4} alt="g4"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 margin-align">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <img src={g1} alt="g1"/>
                                        </div>
                                        <div className="col-md-6">
                                            <img src={g3} alt="g3"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                </div>
            </div>
        )
    }
}