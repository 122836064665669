import React, { Component } from 'react';
import SideMenu from './sideMenu.js';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { default as Amplify, Auth, Storage } from "aws-amplify";
import axios from 'axios';

import Carousel from "react-multi-carousel";
import "./css/carousel.css";

import img_loader from './images/BL-PreLoader.gif';
var AWS = require("aws-sdk");
const jsonConfig = require("./Config.json");
const responsive = {
  desktop: {
      breakpoint: { max: 4000, min: 0 },
      items: 3
    },
};
export default class Profile extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
          userName:'',
          userPhone:'',
          userNick:'',
          userOrg:'',
          userRole:'',
          name:'',
          defaultOrgName:'',
          defaultOrgId:'',
          defaultRoleName:'',
          getCognitoId:'',
          defaultCognitoId:'',

          userProfilePicture:jsonConfig.s3BucketUrl+'no-preview-available.png',
          files:[],
          previewSrc:null,
          stateFile:[],
          stateFileName:'',
          defaultValue:'checked',
          oldPassword:'',
          newPassword:'',
          matchPasswordStatus:'',
          verifyPassword:'',
          activeProfImg:0,
          overlay:'block',
          changeOpacity:1
        };
        this.updateProfile = this.updateProfile.bind(this); 
        this.selectImg = this.selectImg.bind(this);
        this.updatePassword = this.updatePassword.bind(this);
        
      }
      componentDidMount()
      {
        AWS.config.update({
          credentials: new AWS.CognitoIdentityCredentials({
            IdentityPoolId: jsonConfig.awsConfigCredentials.IdentityPoolId,
            RoleArn: jsonConfig.awsConfigCredentials.RoleArn,
          }),
          region: jsonConfig.awsConfigRegion,
        });
        this.getUserDetails();
        var tempClass=document.getElementsByClassName('react-multi-carousel-list')[0];
        var tempLeftArrowCarousel=document.getElementsByClassName('react-multiple-carousel__arrow--left')[0];
        //console.log(tempLeftArrowCarousel);
        //tempClass.style.marginLeft = "0px";
        if(tempLeftArrowCarousel!==undefined)
        {
          console.log(tempLeftArrowCarousel);
          tempLeftArrowCarousel.style.Left = "calc(-7% + 2px)";
        }
        
      }
    abc()
    {
      
      
    }
    async getUserDetails()
    {
      this.setState({
        isLoading:true,
        overlay:'block',
        changeOpacity:1
      })
        var user = await Auth.currentAuthenticatedUser();
        var getUserById = await axios.get(jsonConfig.rdsApi+"users/"+user.attributes.sub,{
            headers: {
              'X-API-KEY': jsonConfig.ApiKey,
              'Content-Type': 'application/json'
            }});
        this.setState({
          defaultOrgName:getUserById.data.data[0].organization.name,
          defaultOrgId:user.attributes["custom:CompanyName"],
          defaultRoleName:getUserById.data.data[0].role.name,
          getCognitoId:user.username,
          defaultCognitoId:user.username,
          userName:user.attributes.email,
          name:user.attributes.name,
          userPhone:user.attributes.phone_number,
          
        })
        if(user.attributes.nickname===undefined || user.attributes.nickname===null)
        {
          this.setState({userNick:''})
        }
        else{
          this.setState({userNick:user.attributes.nickname})
        }
        if(user.attributes.picture===undefined || user.attributes.picture===null)
        {
          this.setState({userProfilePicture:jsonConfig.s3BucketUrl+'public/no_photo.png'})
        }
        else{
          this.setState({userProfilePicture:user.attributes.picture})
        }

        this.setState({
          overlay:'none',
          changeOpacity:0
        })
        
    }
  createLoader()
    {
        return(
        <div className="overlayLoader" style={{display:this.state.overlay}}>
          <img src={img_loader} className="loaderClass" alt="loader"/>
        </div>
        )
    }
    createCarousel()
    {
        if(this.state.userProfilePicture==='' || this.state.userProfilePicture===null)
        {
            var profPics=jsonConfig.s3BucketUrl+'public/no_photo.png'
        }
        else{
            profPics=this.state.userProfilePicture;
        }
        var cards=[
            {"image":profPics},
            {"image":jsonConfig.s3BucketUrl+'public/photo.png'},
            {"image":jsonConfig.s3BucketUrl+'public/Man.png'},
            {"image":jsonConfig.s3BucketUrl+'public/Robot.png'},
            {"image":jsonConfig.s3BucketUrl+'public/Cat.png'},  
        ]
        return(
            <Carousel 
                responsive={responsive}
                >
                {cards.map((value, index) => (
                    <img src={value.image} 
                    className={this.state.activeProfImg===index?'imgAv type_a profile_img selectedProfImg':'imgAv type_a profile_img'} 
                    alt={index+'_img'} 
                    key={index}
                    style={{marginLeft:'0px'}}
                    onClick={(e)=>this.selectDefaultImg(value.image,index)}
                    />
                ))}
            </Carousel>
        )
    }
    selectDefaultImg=(defaultimgurl,key)=>
    {
          this.setState({
              userProfilePicture:defaultimgurl,
              activeProfImg:key
          })
      }
      nameChange= event => {
        this.setState({name: event.target.value});
      }
      phoneChange= event => {
        this.setState({userPhone: event.target.value});
      }
      nickChange= event => {
        this.setState({userNick: event.target.value});
      }
      selectImg(imgUrl)
      {
        
        this.setState({
          userImg:imgUrl,
          previewImg:imgUrl
        });
        this.setState({
          stateFile:undefined,
          stateFileName:undefined
        });
        document.getElementById("InputFile").value='';
        
      }
async fileChange(e)
  {
    AWS.config.update({
      region: jsonConfig.awsConfigRegion, // Put your aws region here
      accessKeyId: jsonConfig.accessKeyId,
      secretAccessKey: jsonConfig.secretAccessKey
    })
    Amplify.configure({
      Storage: {
          AWSS3: {
              bucket: jsonConfig.userProfileBucket, //REQUIRED -  Amazon S3 bucket
          }
      }
    });
    const file = e.target.files[0];
    this.setState({
      stateFile:e.target.files[0],
      stateFileName:e.target.files[0].name
    });
    try {
      let uploadResult = await Storage.put(file.name, file, {
        contentType: file.type
      })
      
      let getImgS3=await Storage.get(uploadResult.key);
      var picSplit=getImgS3.split("?");
      this.setState({
        userImg:picSplit[0],
        userProfilePicture:picSplit[0]
      });
    } catch (err) {
      console.log(err)
    }
  }
  async updateProfile()
  {
    try {
      const user = await Auth.currentAuthenticatedUser();
      var userData={
        name: this.state.name,
        phone_number:this.state.userPhone,
        nickname:this.state.userNick,
        picture:this.state.userProfilePicture,
      }
      await Auth.updateUserAttributes(user, userData);
      await Auth.currentAuthenticatedUser({bypassCache: true});
      NotificationManager.success('Profile sucessfully updated','Success');
    } catch (e) {
        NotificationManager.error('Profile updation failed','Error');
    }
  }
  //---------------PASSWORD---------------//
  oldPasswordChange= event => {
    this.setState({oldPassword: event.target.value});
  }
  newPasswordChange= event => {
    this.setState({newPassword: event.target.value});
  }
  putCrossmarks=event=>
  {
    this.setState({matchPasswordStatus: 'cross'});
  }
  verifyPasswordChange= event => {
    this.setState({verifyPassword: event.target.value});
    if(event.target.value===this.state.newPassword)
    {
      this.setState({matchPasswordStatus:'tick'})
    }
    else{
      this.setState({matchPasswordStatus:'cross'})
    }
  }
  async updatePassword()
  {  
      try {
        var currentUser = await Auth.currentAuthenticatedUser({bypassCache: true});
        await Auth.changePassword(currentUser,this.state.oldPassword,this.state.newPassword);
        NotificationManager.success('Password sucessfully changed','Success');
      } catch (err) {
        console.log(err);
        NotificationManager.error(err.message,'Error');
        this.setState({ isChanging: false });
      }
  }
    render() {
        return (
            <div id="Smart_Feed___Profile" className="commonWidthHeight">
              <SideMenu/>
              <div className="middlePanelOthers">
                <div id="HeaderOthers">
                    <div id="Interaction_Board">
                        <span>Edit Profile</span>
                    </div>
                </div>
                
                <div id="Group_2_A1_Group_55">
                        
                        <div className="col-md-12 margin-align">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="col-md-12 mt55 carouselforProfile">
                                        {this.createCarousel()}
                                    </div>
                                    <div className="col-md-12">
                                    <div>
                                        <p className="labelText ml10 text-center w-205">Choose an Avatar OR</p>
                                        <div className="upload-btn-wrapper ml10 mt20">
                                            <button className="upload_button w-205">Upload Photo</button>
                                            <input 
                                            type="file" 
                                            className="txtFileType"
                                            style={{width: '273px'}}
                                            onChange={(e) => this.fileChange(e)}
                                            />
                                        </div>
                                        
                                      </div>
                                        
                                    </div>
                                    <div className="col-md-12 margin-align ml10">
                                        <p className="labelText">Email</p>
                                        <input 
                                        type="text"
                                        className="txtType"
                                        defaultValue={this.state.userName} 
                                        readOnly
                                        style={{width: '337px',height:'36px'}}
                                        />
                                    </div>
                                    <div className="col-md-12 margin-align ml10">
                                        <p className="labelText">Name</p>
                                        <input 
                                        type="text"
                                        className="txtType"
                                        value={this.state.name}
                                        onChange={this.nameChange}
                                        style={{width: '337px',height:'36px'}}
                                        />
                                    </div>
                                    <div className="col-md-12 margin-align ml10">
                                        <p className="labelText">Nick Name</p>
                                        <input 
                                        type="text"
                                        className="txtType"
                                        value={this.state.userNick}
                                        onChange={this.nickChange}
                                        style={{width: '337px',height:'36px'}}
                                        />
                                    </div>
                                    <div className="col-md-12 margin-align ml10">
                                        <p className="labelText">Phone</p>
                                        <input 
                                        type="text"
                                        className="txtType"
                                        value={this.state.userPhone}
                                        onChange={this.phoneChange}
                                        style={{width: '337px',height:'36px'}}
                                        />
                                    </div>
                                    <div className="col-md-12 margin-align ml10">
                                        <p className="labelText">Orgnaization</p>
                                        <input 
                                        type="text"
                                        className="txtType"
                                        value={this.state.defaultOrgName}
                                        readOnly
                                        style={{width: '337px',height:'36px'}}
                                        />
                                    </div>
                                    <div className="col-md-12 margin-align ml10">
                                        <p className="labelText">Role</p>
                                        <input 
                                        type="text"
                                        className="txtType"
                                        value={this.state.defaultRoleName}
                                        readOnly
                                        style={{width: '337px',height:'36px'}}
                                        />
                                    </div>
                                    <div className="col-md-12 margin-align ml10">
                                        <button className="button" onClick={this.updateProfile}> Update</button>
                                    </div>
                                </div>
                                <div className="col-md-6 border-left">
                                  <div className="col-md-12" style={{marginTop:'175px'}}>
                                      <div className="ml10">
                                          <div id="SentimentHeaderTxt">Change Password</div>
                                      </div>
                                    </div>
                                    <div className="col-md-12 margin-align ml10">
                                        <p className="labelText">Old Password</p>
                                        <input 
                                        type="password"
                                        className="txtType"
                                        value={this.state.oldPassword} 
                                        onChange={this.oldPasswordChange}
                                        style={{width: '270px',height:'36px'}}
                                        />
                                    </div>
                                    <div className="col-md-12 margin-align ml10">
                                        <p className="labelText">New Password</p>
                                        <input 
                                        type="password"
                                        className="txtType"
                                        value={this.state.newPassword} 
                                        onChange={this.newPasswordChange}
                                        style={{width: '270px',height:'36px'}}
                                        />
                                    </div>
                                    <div className="col-md-12 margin-align ml10">
                                        <p className="labelText">Verify Password</p>
                                        <input 
                                        type="password"
                                        className="txtType"
                                        value={this.state.verifyPassword}
                                        onFocus={this.putCrossmarks}
                                        onChange={this.verifyPasswordChange}
                                        style={{width: '270px',height:'36px'}}
                                        />
                                        {this.state.matchPasswordStatus==='tick'?
                                              <span className="mt10" style={{position:'absolute',left:'257px',top:'16px'}}>
                                                  <i className="fa fa-check-circle checkIconColor" aria-hidden="true"></i>
                                              </span>
                                          :''
                                          }
                                          {this.state.matchPasswordStatus==='cross'?
                                              <span className="mt10" style={{position:'absolute',left:'257px',top:'16px'}}>
                                                  <i className="fa fa-times-circle checkCrossColor" aria-hidden="true"></i>
                                              </span>

                                          :''
                                          }
                                          {this.state.matchPasswordStatus==='cross'?
                                              <div className="mt5 passwordNotMatchTxt">Password does not match</div>
                                              
                                          :''
                                          }
                                    </div>
                                    
                                    <div className="col-md-12 margin-align ml10">
                                        <button className="button" onClick={this.updatePassword}> Update</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
                </div>
                <NotificationContainer/>
                {this.createLoader()}
            </div>
        )
    }
}