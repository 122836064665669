import React, { Component } from 'react';
import SideMenu from './sideMenu.js';
//import userIcon from './images/userIcon.png';
import roles from './images/roles.png';
//import ImgNext from './images/arrow_next.png'
import {NotificationContainer, NotificationManager} from 'react-notifications';
import ReactTooltip from 'react-tooltip';
import axios from 'axios';
//import Loader from 'react-loader-spinner';
import img_loader from './images/BL-PreLoader.gif';
import img_prLoader from './images/provision.gif';
import { default as Amplify, Auth, Storage } from "aws-amplify";
import Carousel from "react-multi-carousel";
import { CountryDropdown, RegionDropdown} from 'react-country-region-selector';
//import "react-multi-carousel/lib/styles.css";
import "./css/carousel.css";

var AWS = require("aws-sdk");
const jsonConfig = require("./Config.json");
const countryCodes = require("./CountryCodes.json");
const responsive = {
    desktop: {
        breakpoint: { max: 4000, min: 0 },
        items: 3
      },
  };
export default class CreateUser extends Component {
    constructor(props) {
        super(props);
        this.state={
            defaultOrgName:'',
            defaultOrgId:'',
            defaultRoleName:'',
            defaultCognitoId:'',
            getCognitoId:'',
            rolesList:[],
            rolesListforUsers:[],
            userList:[],
            orgList:[],
            userUpdateDisabled:true,
            userCreateDisabled:false,
            readonlyEmail:false,
            readonlyPassword:false,
            roleVal:'',
            editableRoleId:'',
            orgVal:'',
            editableOrgId:'',
            profilePictureUrl:'',
            name:'',
            userEmail:'',
            userPhone:'',
            userPassword:'',
            confirmPassword:'',
            matchPasswordStatus:'',
            selectRoleId:'',
            selectOrgId:'',
            editableCognitoId:'',
            filterUserRole:'all',
            filterUserOrg:'all',
            screenList:[],
            userRoleList:[],
            noSuperRoleList:[],
            permissionList:[],
            activeList:-1,
            alterPanel:false,
            isSystemOrNot:true,
            headerRoleName:'System',
            activeUser:-1,
            activeOrg:-1,
            clickedOrgName:'',
            totalBoards:'',
            totalActiveBoards:'',
            totalInactiveBoards:'',
            totalRolesforthisOrg:'',
            totalUserforthisOrg:'',
            userStat:'',
            isLoading:true,
            overlay:'block',
            changeOpacity:1,
            changeOrgval:0,
            settingsVal:false,
            analyseResultVal:true,
            activeProfImg:0,
            tempProfPics:'',
            userHeading:'Create New User',
            userSubHeading:'Feed in all the details here for your New User',
            imgUploadingState:false,
            updateUserStatus:false,
            profileImgUpdt:'',
            countryForOrg:'',
            cityForOrg:'',
            addressForOrg:'',
            pinForOrg:'',
            orgButtonStatus:true,
            editableOrgId:'',
            orgStatusEnableDisable:false,
            orgFilterValue:0,
            passwordMsg:'',
            passwordStatus:false,
            userCreationDate:'',
            orgStatusMsg:'',
            transactionsLimit:8000,
            //-------SMS--------//
            globalAccountSid:'',
            globalAuthToken:'',
            subAcName:0,
            phoneNumber:'',
            dialCode:'',
            countryCode:'',
            wholecode:0,
            countryJson:[],
            twilioResultList:[],
            smsSubAccName:'',
            phoneNumberJson:[],
                           
            
        }
        this.editRoles = this.editRoles.bind(this);
        this.createRole = this.createRole.bind(this);
        this.updateRole = this.updateRole.bind(this);
        this.createOrg = this.createOrg.bind(this);
        this.selectCountry = this.selectCountry.bind(this);
        this.selectRegion = this.selectRegion.bind(this);
        
        this.createProfile = this.createProfile.bind(this);
        this.editUser = this.editUser.bind(this);
        this.updateProfile = this.updateProfile.bind(this);
        
        this.getScreenList = this.getScreenList.bind(this);
        this.changeStatus = this.changeStatus.bind(this);
        this.openCreateRole = this.openCreateRole.bind(this);
        this.filterRolesByOrg = this.filterRolesByOrg.bind(this);
        this.filterUserOrgHandler = this.filterUserOrgHandler.bind(this);
        this.filterUserRoleHandler = this.filterUserRoleHandler.bind(this);
        this.changeUserStat = this.changeUserStat.bind(this);
        this.resetProfile = this.resetProfile.bind(this);
        this.selectDefaultImg = this.selectDefaultImg.bind(this);
        this.generateCarousel = this.generateCarousel.bind(this);
        this.changeSettingsStatus = this.changeSettingsStatus.bind(this);
        this.updateSmsSettings = this.updateSmsSettings.bind(this);
        this.updateOrg = this.updateOrg.bind(this);
        this.disableOrg = this.disableOrg.bind(this);
        this.orgFilterHandler = this.orgFilterHandler.bind(this);
        
        
    }
    componentDidMount()
    {
        this.getPageAccess();
        this.getGlobalSettings();
        
        AWS.config.update({
            credentials: new AWS.CognitoIdentityCredentials({
              IdentityPoolId: jsonConfig.awsConfigCredentials.IdentityPoolId,
              RoleArn: jsonConfig.awsConfigCredentials.RoleArn,
            }),
            region: jsonConfig.awsConfigRegion,
          });
        
    }
    getGlobalSettings()
    {
        var globalSettingsTable="globalSettings"
        var docClient = new AWS.DynamoDB.DocumentClient();
        var getParams = {
            TableName : globalSettingsTable,
            FilterExpression : "environment = :val",
            ExpressionAttributeValues: {
                ":val": jsonConfig.environment
            }
          };
          docClient.scan(getParams, (err, data)=> {
            if (err) {
                console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
            } else {
                if(data.Items.length>0)
                {
                    if(data.Items[0].checkmarkEnforcement!==undefined)
                    {
                        this.setState({settingsVal:data.Items[0].checkmarkEnforcement})
                    }
                    else{
                        this.setState({settingsVal:false})
                    }
                    if(data.Items[0].displayAnalyseResult!==undefined)
                    {
                        this.setState({analyseResultVal:data.Items[0].displayAnalyseResult})
                    }
                    else{
                        this.setState({analyseResultVal:true})
                    }
                    
                }
                else{
                    this.setState({
                        settingsVal:false,
                        analyseResultVal:true
                    })
                }
            }
          });
    }
    async getPageAccess()
    {
        var user = await Auth.currentAuthenticatedUser();
        var getUserById = await axios.get(jsonConfig.rdsApi+"users/"+user.attributes.sub,{
            headers: {
              'X-API-KEY': jsonConfig.ApiKey,
              'Content-Type': 'application/json'
            }});
        this.setState({
          defaultOrgName:getUserById.data.data[0].organization.name,
          defaultOrgId:getUserById.data.data[0].organizationId,
          defaultRoleName:getUserById.data.data[0].role.name,
          getCognitoId:user.username,
          defaultCognitoId:user.username
        })
        if(getUserById.data.data[0].role.name==='Admin')
        {
            this.setState({headerRoleName:'Admin'})
        }
        else{
            this.setState({headerRoleName:'System'})
        }
        if(getUserById.data.data[0].role.name==='System')
        {
            //this.getAllRoles();
            this.getAllUsers();
            this.getAllOrgs();
            this.getScreen();
            this.getRolesforScreen();
            this.setState({isSystemOrNot:true})
            this.getTwilioAccount();
            this.getSmsSettings();
            this.getSmsDetails();
        }
        else
        {
            this.getRolesbyOrg();
            this.getRolesByOrgForEditUser();
            this.getUserbyOrg();
            this.getRolesforScreenbyOrg();
            this.setState({isSystemOrNot:false})
            this.getRolesforAdminUser();
        }
    }


    generateCarousel()
    {
        this.componentDidMount();
    }
    createCarousel()
    {
        if(this.state.tempProfPics==='' || this.state.tempProfPics===null)
        {
            var profPics=jsonConfig.s3BucketUrl+'public/no_photo.png'
        }
        else{
            profPics=this.state.tempProfPics;
        }
        var cards=[
            {"image":profPics},
            {"image":jsonConfig.s3BucketUrl+'public/photo.png'},
            {"image":jsonConfig.s3BucketUrl+'public/Man.png'},
            {"image":jsonConfig.s3BucketUrl+'public/Robot.png'},
            {"image":jsonConfig.s3BucketUrl+'public/Cat.png'},  
        ]
        return(
            <Carousel 
                responsive={responsive}
                >
                {cards.map((value, index) => (
                    <img src={value.image} 
                    className={this.state.activeProfImg===index?'imgAv type_a profile_img selectedProfImg':'imgAv type_a profile_img'} 
                    alt={index+'_img'} 
                    key={index}
                    onClick={(e)=>this.selectDefaultImg(value.image,index)}
                    />
                ))}
            </Carousel>
        )
    }
    createLoader()
    {
        return(
            <div className="overlayLoader" style={{display:this.state.overlay}}>
                <img src={img_loader} className="loaderClass" alt="loader"/>
            </div>
        )
    }
    disableFunction()
    {
        NotificationManager.error("You don't have permission to access this tab",'Warning');
    }
    async getAllRoles()
    {
        var res = await axios.get(jsonConfig.rdsApi+"roles",{
            headers: {
              'X-API-KEY': jsonConfig.ApiKey,
              'Content-Type': 'application/json'
            }});
        this.setState({
            rolesList:res.data.data
        })/**/
    }
    getAdminCreationRoles(orgId)
    {
        axios.get(jsonConfig.rdsApi+"rolesByOrganization?organizationId="+orgId,{
            headers: {
              'X-API-KEY': jsonConfig.ApiKey,
              'Content-Type': 'application/json'
            }})
        .then((response) => {
            this.setState({
                rolesList:response.data.data,
                rolesListforUsers:response.data.data
            })
                    }, (error) => {
                        console.log(error);
                    });
    }
    async getRolesforScreen()
    {
        var res = await axios.get(jsonConfig.rdsApi+"getUserRoles",{
            headers: {
              'X-API-KEY': jsonConfig.ApiKey,
              'Content-Type': 'application/json'
            }});
            this.setState({
                userRoleList:res.data.data
            })
    }
    async getAllUsers()
    {
        var resUsers = await axios.get(jsonConfig.rdsApi+"users",{
            headers: {
              'X-API-KEY': jsonConfig.ApiKey,
              'Content-Type': 'application/json'
            }});
        console.log("USERLIST",resUsers.data.data);
        this.setState({
            userList:resUsers.data.data,
            isLoading:false,
            overlay:'none',
            changeOpacity:0
        })
    }
    async getAllOrgs()
    {
        var resOrg = await axios.get(jsonConfig.rdsApi+"organizations",{
            headers: {
              'X-API-KEY': jsonConfig.ApiKey,
              'Content-Type': 'application/json'
            }});
        console.log("ORG LIST",resOrg);
        this.setState({
            orgList:resOrg.data.data
        })
    }
    async getScreen()
    {
        var resScreen = await axios.get(jsonConfig.rdsApi+"screens",{
            headers: {
              'X-API-KEY': jsonConfig.ApiKey,
              'Content-Type': 'application/json'
            }});
        this.setState({
            screenList:resScreen.data.data
        })
    }
    //-----FOR ADMIN USER---------//
    async getRolesbyOrg()
    {
        var res=await axios.get(jsonConfig.rdsApi+"getNoSuperRolesByOrganization?organizationId="+this.state.defaultOrgId,{
            headers: {
              'X-API-KEY': jsonConfig.ApiKey,
              'Content-Type': 'application/json'
            }});
        this.setState({
            noSuperRoleList:res.data.data,
        })
        
    }
    async getRolesByOrgForEditUser()
    {
        var resAll = await axios.get(jsonConfig.rdsApi+"rolesByOrganization?organizationId="+this.state.defaultOrgId,{
            headers: {
              'X-API-KEY': jsonConfig.ApiKey,
              'Content-Type': 'application/json'
            }});
        this.setState({
            rolesList:resAll.data.data,
            rolesListforUsers:resAll.data.data
        })/**/
    }
    async getRolesForAdmin(orgName)
    {
        var res=await axios.get(jsonConfig.rdsApi+"getNoSuperRolesByOrganization?organizationId="+orgName,{
            headers: {
              'X-API-KEY': jsonConfig.ApiKey,
              'Content-Type': 'application/json'
            }});
        this.setState({rolesList:res.data.data})
    }
    async getRolesforScreenbyOrg()
    {
        var res = await axios.get(jsonConfig.rdsApi+"rolesByOrganization?organizationId="+this.state.defaultOrgId,{
            headers: {
              'X-API-KEY': jsonConfig.ApiKey,
              'Content-Type': 'application/json'
            }});
        this.setState({
            userRoleList:res.data.data
        })
    }
    async getUserbyOrg()
    {
        var res = await axios.get(jsonConfig.rdsApi+"filterUsers?organizationId="+this.state.defaultOrgId+'&roleName=all',{
            headers: {
              'X-API-KEY': jsonConfig.ApiKey,
              'Content-Type': 'application/json'
            }});
        this.setState({
            userList:res.data.data,
            isLoading:false,
            overlay:'none',
            changeOpacity:0
        })
    }
    async getRolesforAdminUser()
    {
        var res = await axios.get(jsonConfig.rdsApi+"getSuperRolesByOrganization?organizationId="+this.state.defaultOrgId,{
            headers: {
              'X-API-KEY': jsonConfig.ApiKey,
              'Content-Type': 'application/json'
            }});
        this.setState({
            rolesListforUsers:res.data.data
        })
    }
    //-----------USER ACCOUNTS-----------//
    
      nameChange= event => {
        this.setState({name: event.target.value});
      }
      emailChange= event => {
        this.setState({userEmail: event.target.value});
      }
      phoneChange= event => {
        this.setState({userPhone: event.target.value});
      }
      passwordChange= event => {
        this.setState({userPassword: event.target.value});
      }
      putCrossmarks=event=>
      {
        if(this.state.readonlyPassword===true)
        {
            this.setState({matchPasswordStatus: ''});
        }
        else{
            this.setState({matchPasswordStatus: 'cross'});
        }
        
      }
      confirmPasswordChange= event => {
        this.setState({confirmPassword: event.target.value});
        if(event.target.value===this.state.userPassword)
        {
            this.setState({matchPasswordStatus:'tick'})
        }
        else{
            this.setState({matchPasswordStatus:'cross'})
        }
      }
      userRoleHandler= event => {
        this.setState({
            selectRoleId: event.target.value
          });
      }
      userOrgHandler= event => {
        this.setState({
            selectOrgId: event.target.value
          });
      }
      userOrgHandlerforUser= event => {
        this.setState({
            selectOrgId: event.target.value
          });
        var index = event.nativeEvent.target.selectedIndex;
        var orgName=event.nativeEvent.target[index].text
        if(event.target.value==='0')
        {
            this.setState({
                rolesList:[],
                rolesListforUsers:[]
            })
        }else{
            axios.get(jsonConfig.rdsApi+"rolesByOrganization?organizationId="+event.target.value,{
                headers: {
                  'X-API-KEY': jsonConfig.ApiKey,
                  'Content-Type': 'application/json'
                }})
        .then((response) => {
            this.setState({
                rolesList:response.data.data,
                rolesListforUsers:response.data.data
            })
                    }, (error) => {
                      console.log(error);
                    });
        }
      }
      async filterUserRoleHandler(ev) {
        this.setState({
            filterUserRole: ev.target.value
          });
          if(ev.target.value==='' || ev.target.value===null)
            {
                var roleName='all';
            }
            else{
                roleName=ev.target.value;
            }
            if(this.state.isSystemOrNot===true)
            {
                var resUsers = await axios.get(jsonConfig.rdsApi+"filterUsers?organizationId="+this.state.filterUserOrg+'&roleName='+roleName,{
                    headers: {
                      'X-API-KEY': jsonConfig.ApiKey,
                      'Content-Type': 'application/json'
                    }});
            }
            else{
                resUsers = await axios.get(jsonConfig.rdsApi+"filterUsers?organizationId="+this.state.defaultOrgId+'&roleName='+roleName,{
                    headers: {
                      'X-API-KEY': jsonConfig.ApiKey,
                      'Content-Type': 'application/json'
                    }});
            }
            this.setState({
                userList:resUsers.data.data
            })
      }
      async filterUserOrgHandler(ev) {
          console.log("ALL ORG");
        this.setState({
            filterUserOrg: ev.target.value
          });
            if(ev.target.value==='' || ev.target.value===null ||  ev.target.value==='all')
            {
                var orgName='all';
                this.setState({
                    rolesList:[]
                })
                console.log("FILTER ORG VAL",orgName);
            }
            else{
                orgName=ev.target.value;

                var resRoles = await axios.get(jsonConfig.rdsApi+"rolesByOrganization?organizationId="+orgName,{
                    headers: {
                      'X-API-KEY': jsonConfig.ApiKey,
                      'Content-Type': 'application/json'
                    }});
                    console.log("FILTER ORG VAL DATA RETURN",resRoles);
                this.setState({
                    rolesList:resRoles.data.data
                })
            }
            var resUsers = await axios.get(jsonConfig.rdsApi+"filterUsers?organizationId="+orgName+'&roleName=all',{
                headers: {
                  'X-API-KEY': jsonConfig.ApiKey,
                  'Content-Type': 'application/json'
                }});
                console.log("FILTER ORG VAL DATA ALL TEST",resUsers);
            this.setState({
                userList:resUsers.data.data
            })
            
      }
      selectDefaultImg(defaultimgurl,key)
      {
            this.setState({
                profilePictureUrl:defaultimgurl,
                activeProfImg:key
            })
      }
      async uploadPhoto(e)
      {
        this.setState({imgUploadingState:true})
        AWS.config.update({
          region: jsonConfig.awsConfigRegion, // Put your aws region here
          accessKeyId: jsonConfig.accessKeyId,
          secretAccessKey: jsonConfig.secretAccessKey
        })
        Amplify.configure({
          Storage: {
              AWSS3: {
                  bucket: jsonConfig.userProfileBucket, //REQUIRED -  Amazon S3 bucket
              }
          }
        });
        const file = e.target.files[0];
        this.setState({
          stateFile:e.target.files[0],
          stateFileName:e.target.files[0].name
        });
        try {
          let uploadResult = await Storage.put(file.name, file, {
            contentType: file.type
          })
          let getImgS3=await Storage.get(uploadResult.key);
          var picSplit=getImgS3.split("?");
          this.setState({
            profilePictureUrl:picSplit[0],
            tempProfPics:picSplit[0],
            imgUploadingState:false
          });
        } catch (err) {
          console.log(err)
        }
      }
    
      async createProfile()
      {
        var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        var chkMail=mailformat.test(this.state.userEmail);
        /**/if(this.state.isSystemOrNot===true)
        {
            var tempOrgid=this.state.selectOrgId
        }
        else{
            tempOrgid=this.state.defaultOrgId;
        }
        AWS.config.update({region: jsonConfig.awsConfigRegion});
        var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
        if(this.state.profilePictureUrl==='' || this.state.profilePictureUrl===null)
        {
            var params = {
                ClientId: jsonConfig.cognitoAppclientID,
                Password: this.state.userPassword,
                Username: this.state.userEmail,
                UserAttributes:[
                    {
                        Name: 'name', 
                        Value: this.state.name
                    }, 
                    {
                        Name: 'email', 
                        Value: this.state.userEmail
                    },
                    {
                      Name: 'phone_number', 
                      Value: this.state.userPhone
                    },
                    
                    {
                      Name: 'custom:CompanyName', 
                      Value: tempOrgid
                  },
                  {
                      Name: 'custom:CompanyRole', 
                      Value: this.state.selectRoleId
                  },
                  
                ],
              };
        }
        else{
            params = {
                ClientId: jsonConfig.cognitoAppclientID,
                Password: this.state.userPassword,
                Username: this.state.userEmail,
                UserAttributes:[
                    {
                        Name: 'name', 
                        Value: this.state.name
                    }, 
                    {
                        Name: 'email', 
                        Value: this.state.userEmail
                    },
                    {
                      Name: 'phone_number', 
                      Value: this.state.userPhone
                    },
                    {
                        Name: 'picture', 
                        Value: this.state.profilePictureUrl
                    },
                    {
                      Name: 'custom:CompanyName', 
                      Value: tempOrgid
                  },
                  {
                      Name: 'custom:CompanyRole', 
                      Value: this.state.selectRoleId
                  },
                  
                ],
              };
        }
        if(this.state.name==='' || chkMail===false || this.state.userPhone==='' || tempOrgid==0 || tempOrgid==='' || this.state.selectRoleId==0 || this.state.selectRoleId==='')
        {
            NotificationManager.error('Please fill up all the field or Invalid email address','Error');
        }
        else
        {
            if(this.state.userPassword===this.state.confirmPassword)
            {
                var decimal=  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,99}$/;
            
            if(this.state.userPassword.match(decimal))
            {
                this.setState({
                    isLoading:true,
                    overlay:'block',
                    changeOpacity:1
                })
                cognitoidentityserviceprovider.signUp(params,(err, data)=> {
                    if (err)
                    {
                      NotificationManager.error(err.message,'Error');
                      this.setState({
                          isLoading:false,
                          overlay:'none',
                          changeOpacity:0
                      })
                    }
                    else
                    { 
                      NotificationManager.success('User successfully created','Success');
                      this.setState({
                          isLoading:false,
                          overlay:'none',
                          changeOpacity:0
                      })
                      if(this.state.isSystemOrNot===true)
                      {
                          this.getAllUsers();
                          this.setState({
                              name:'',
                              userEmail:'',
                              userPhone:'',
                              userPassword:'',
                              selectRoleId:'',
                              selectOrgId:'',
                              filterUserOrg:'all',
                              filterUserRole:'all',
                              confirmPassword:'',
                          })
                      }
                      else{
                          this.getUserbyOrg();
                          this.setState({
                              name:'',
                              userEmail:'',
                              userPhone:'',
                              userPassword:'',
                              selectRoleId:'',
                              filterUserRole:'all',
                              confirmPassword:'',
                          })
                      }
                      
                    }
                  });
            }
            else{
                
                //NotificationManager.error('Password not match','Error');
                NotificationManager.error('1.Password contain a capital letter 2.Password contain number 3.Password contain # or @ 4.Password length should be 8-99','Error');
                }
            }
            else{
                NotificationManager.error('Password not match retype password again','Error');
            }

            
            
        }
        
        
      }
      editUser(key,userid,name,email,phoneNumber,organizationId,roleId,role,profilePictureUrl,userStat,createdate)
      {
        this.setState({matchPasswordStatus:''})
        if(phoneNumber===null)
        {
            var phNoUser='';
        }
        else{
            phNoUser=phoneNumber;
        }
        if(organizationId===null)
        {
            this.setState({selectOrgId:'all'})
        }
        else{
            this.setState({selectOrgId:organizationId})
        }
        if(name===null)
        {
            this.setState({name:''})
        }
        else{
        this.setState({name:name})
        }
        if(roleId===null)
        {
            this.setState({selectRoleId:'all'})
        }
        else{
        this.setState({selectRoleId:roleId})
        }
        var options={day: '2-digit',year: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit'}
          if(this.state.isSystemOrNot===false && roleId!==null && role.name==='System')
          {
            NotificationManager.error("You don't have permission to edit system user","Error");
          }
          else{
          //this.getAdminCreationRoles(orgName)
          
            if(this.state.isSystemOrNot===true)
            {
                this.getAdminCreationRoles(organizationId)
            }
            else{
                //this.getRolesForAdmin(orgName);
                this.getRolesforAdminUser();
            }
            var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({region:"us-east-1"});
            var params = {
                UserPoolId: jsonConfig.userPoolID, /* required */
                Username: userid /* required */
              };
              cognitoidentityserviceprovider.adminGetUser(params, function(err, data) {
                if (err){
                    console.log(err, err.stack);
                }
                else {
                    console.log(data)
                }
              });
              
            this.setState({
                userUpdateDisabled:false,
                userCreateDisabled:true,
                readonlyEmail:true,
                readonlyPassword:true,
                userEmail:email,
                userPhone:phNoUser,
                
                
                
                editableCognitoId:userid,
                activeUser:key,
                profilePictureUrl:profilePictureUrl,
                tempProfPics:profilePictureUrl,
                userStat:userStat,
                userHeading:'Edit User',
                userSubHeading:'Edit user here of your user',
                userCreationDate:new Date(createdate).toLocaleTimeString([],options)
            })
        }
      }
      resetProfile()
      {
        this.setState({
            userUpdateDisabled:true,
            userCreateDisabled:false,
            readonlyEmail:false,
            readonlyPassword:false,
            userEmail:'',
            userPhone:'',
            name:'',
            selectOrgId:'',
            selectRoleId:'',
            editableCognitoId:'',
            activeUser:-1,
            profilePictureUrl:'',
            userCreationDate : ''
        })
      }
      async updateProfile()
      {
        if(this.state.isSystemOrNot===true)
        {
            var tempOrgid=this.state.selectOrgId
        }
        else{
            tempOrgid=this.state.defaultOrgId;
        }
        var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({region:"us-east-1"});
        if(this.state.profilePictureUrl==='' || this.state.profilePictureUrl===null)
        {
            var params = {
                UserAttributes: [ /* required */
                  {
                    Name: 'name', /* required */
                    Value: this.state.name
                  },
                  {
                    Name: 'phone_number', 
                    Value: this.state.userPhone
                  },
                  
                  {
                    Name: 'custom:CompanyName', 
                    Value: tempOrgid
                  },
                  {
                    Name: 'custom:CompanyRole', 
                    Value: this.state.selectRoleId
                  },
                  /* more items */
                ],
                UserPoolId: jsonConfig.userPoolID, /* required */
                Username: this.state.editableCognitoId, /* required */
              };
        }
        else{
        params = {
            UserAttributes: [ /* required */
              {
                Name: 'name', /* required */
                Value: this.state.name
              },
              {
                Name: 'phone_number', 
                Value: this.state.userPhone
              },
              {
                Name: 'picture', 
                Value: this.state.profilePictureUrl
              },
              {
                Name: 'custom:CompanyName', 
                Value: tempOrgid
              },
              {
                Name: 'custom:CompanyRole', 
                Value: this.state.selectRoleId
              },
              /* more items */
            ],
            UserPoolId: jsonConfig.userPoolID, /* required */
            Username: this.state.editableCognitoId, /* required */
          };
        }
        
        if(this.state.name==='' || this.state.userPhone==='' || this.state.selectOrgId==0 || this.state.selectOrgId==='' || this.state.selectRoleId==0 || this.state.selectRoleId==='')
        {
            NotificationManager.error('Please fill up all the field','Error');
        }
        else{
            cognitoidentityserviceprovider.adminUpdateUserAttributes(params,(err, data)=> {
                if (err) {
                    NotificationManager.error(err.message,'Error');
                }
                
                else{
                    this.setState({
                        overlay:'block',
                        changeOpacity:1
                    })
                    var dataCreate={
                        "name": this.state.name,
                        "phoneNumber":this.state.userPhone,
                        "organizationId": this.state.selectOrgId,
                        "roleId": this.state.selectRoleId,
                        "profilePictureUrl":this.state.profilePictureUrl,
                        "createdBy": this.state.getCognitoId,
                        "updatedBy": this.state.getCognitoId
                    }
                    
                    axios.put(jsonConfig.rdsApi+"users/"+this.state.editableCognitoId, dataCreate,{
                        headers: {
                          'X-API-KEY': jsonConfig.ApiKey,
                          'Content-Type': 'application/json'
                        }})
                    .then((response)=> {
                       
                        this.setState({
                            isLoading:false,
                            overlay:'none',
                            changeOpacity:0,
                            
                        })
                        NotificationManager.success('User successfully updated','Success');
                        if(this.state.getCognitoId===this.state.editableCognitoId)
                        {
                            this.setState({
                                updateUserStatus:true,
                                profileImgUpdt:this.state.profilePictureUrl
                            })
                        }
                        
                        if(this.state.isSystemOrNot===true)
                        {
                            this.getAllUsers();
                        }
                        else{
                            this.getUserbyOrg();
                        }
                    })
                    .catch(function (error) {
                      console.log(error);
                    });
                    
                    
                }
              });
        }
          
      }
      async changeUserStat(userid,status,roleId)
      {
        if(this.state.isSystemOrNot===false)
        {
            NotificationManager.error("You don't have permission to edit system user","Error");
        }
        else{
        var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({region:"us-east-1"});
        var params = {
            UserPoolId: jsonConfig.userPoolID, /* required */
            Username: userid, /* required */
        }
        var dataCreate={
            "isActive": status,
        }
        if(status===false)
        {
            cognitoidentityserviceprovider.adminDisableUser(params, (err, data)=> {
                if (err) 
                {
                    console.log(err, err.stack);
                } 
                else {
                    console.log(data)
                }; 
                });
        }
        else{
            cognitoidentityserviceprovider.adminEnableUser(params,(err, data)=> {
                if (err)
                {
                    console.log(err, err.stack);
                }
                else {
                    console.log(data);
                }    
              });
        }
        
        var changeStatus=await axios.put(jsonConfig.rdsApi+"users/"+userid, dataCreate,{
            headers: {
              'X-API-KEY': jsonConfig.ApiKey,
              'Content-Type': 'application/json'
            }});
        if(changeStatus.status===200)
        {
            NotificationManager.success('User successfully updated','Success');
            if(this.state.isSystemOrNot===true)
            {
                this.getAllUsers();
                this.getAllOrgs();
                this.setState({
                    filterUserOrg: 'all',
                    filterUserRole:'all'
                  });
            }
            else{
                this.getUserbyOrg();
                this.setState({
                    filterUserRole:'all'
                })
            }
        }
    }
}
    //--------ROLES MANAGE------------//
    openCreateRole()
    {
        this.setState({
            alterPanel:true,
            activeList:-1,
            roleVal:'',
            selectOrgId:''
        })
    }
    roleHandler = event => {
        this.setState({
            roleVal: event.target.value
        });
      }
    async editRoles(roleId,roleName,orgID)
    {
        this.setState({
            roleVal:roleName,
            editableRoleId:roleId,
            selectOrgId:orgID
        })
    }
    async createRole()
    {
        if(this.state.isSystemOrNot===true)
        {
            var tempOrgid=this.state.changeOrgval
        }
        else{
            tempOrgid=this.state.defaultOrgId;
        }
        
        if(this.state.roleVal==='' || tempOrgid===0 || tempOrgid==='' || tempOrgid===undefined)
        {
            NotificationManager.error('Please enter role or organazitaion','Error');
        }
        else{        
        
        var dataCreate={
                "name": this.state.roleVal,
                "organizationId":tempOrgid,
                "createdBy": this.state.defaultCognitoId,
                "updatedBy": this.state.defaultCognitoId
          }
          
          if(this.state.roleVal.toLowerCase()==='system' || this.state.roleVal.toLowerCase()==='admin' || this.state.roleVal.toLowerCase()==='administrator')
          {
            NotificationManager.error('The role name cannot be "System" or Admin"','Error');
          }
          else{
            var createResp=await axios.post(jsonConfig.rdsApi+"roles/", dataCreate,{
                headers: {
                  'X-API-KEY': jsonConfig.ApiKey,
                  'Content-Type': 'application/json'
                }});
            console.log("CREATE ROLES",createResp)
            if(createResp.status===200 || createResp.status===201)
            {  
                if(createResp.data.message===undefined)
                {
                    NotificationManager.success('Roles successfully created','Success');
                    this.setState({
                        roleVal:'',
                        alterPanel:false,
                    });
                    if(this.state.isSystemOrNot===false)
                    {
                        this.getRolesbyOrg();
                    }
                    else{
                        this.getAllRoles();
                        this.setState({
                            changeOrgval:0,
                            selectOrgId:0,
                            noSuperRoleList:[]
                        })
                    }
                }
                else{
                    NotificationManager.error('Roles already exsists','Error');
                } 
            }
            else{
                NotificationManager.error('Roles creation failed','Error');
            }
          }
        }
          
    }
    async updateRole()
    {
        if(this.state.roleVal==='' || this.state.selectOrgId===0 || this.state.selectOrgId==='')
        {
            NotificationManager.error('Please enter role or organaization','Error');
        }
        else{

        if(this.state.isSystemOrNot===true)
        {
            var tempOrgid=this.state.changeOrgval

        }
        else{
            tempOrgid=this.state.defaultOrgId;
        }
        var dataUpdate={
            "name": this.state.roleVal,
            "organizationId": tempOrgid,
            "updatedBy": this.state.getCognitoId
      }
      if(this.state.roleVal.toLowerCase()==='system' || this.state.roleVal.toLowerCase()==='admin')
      {
        NotificationManager.error('You cant create system or admin','Error');
      }else{
        var updateResp=await axios.put(jsonConfig.rdsApi+"roles/"+this.state.editableRoleId, dataUpdate,{
            headers: {
              'X-API-KEY': jsonConfig.ApiKey,
              'Content-Type': 'application/json'
            }});
        if(updateResp.status===200)
          {
            NotificationManager.success('Roles successfully updated','Success');
            this.setState({
                roleVal:'',
                //alterPanel:true,
                activeList:-1,
                permissionList:[]
            });
            if(this.state.isSystemOrNot===false)
            {
                this.getRolesbyOrg();
            }
            else{
                this.getAllRoles();
                this.setState({
                    changeOrgval:0,
                    selectOrgId:0,
                    noSuperRoleList:[]
                })
            }
          }
          else{
            NotificationManager.error('Roles updation failed','Error');
          }
      }
    }
        
    }
    async filterRolesByOrg(event)
    {
        let index = event.nativeEvent.target.selectedIndex;
        let label = event.nativeEvent.target[index].text;
        var orgName=event.target.value;
        this.setState({
            changeOrgval:event.target.value
        })
        if(orgName==='0')
        {
            this.setState({
                noSuperRoleList:[]
            })
        }
        else{
            var filteredData=await axios.get(jsonConfig.rdsApi+"getNoSuperRolesByOrganization?organizationId="+orgName,{
                headers: {
                  'X-API-KEY': jsonConfig.ApiKey,
                  'Content-Type': 'application/json'
                }});
            this.setState({noSuperRoleList:filteredData.data.data})
        }
        
    }
    //------------ORGANIZATION --------------//
    orgHandler = event => {
        this.setState({
            orgVal: event.target.value
        });
      }
      selectCountry (val) {
        this.setState({ countryForOrg: val });
      }
     
      selectRegion (val) {
        this.setState({ cityForOrg: val });
      }
      addressHandler = event => {
        this.setState({
            addressForOrg: event.target.value
        });
      }
      postalHandler = event => {
        this.setState({
            pinForOrg: event.target.value
        });
      }
      transactionsLimitHandler = event => {
        this.setState({
            transactionsLimit: event.target.value
        });
      }
      createOrg()
      {
        var letters = /^[A-Za-z]+$/;
        if(this.state.orgVal.match(letters)===null || this.state.orgVal.match(letters)==='null')
        {
            NotificationManager.error('Organization must start with a letter and contain letters.Organization can only use English characters','Error');
        }
        else{
            var dataCreate={
                "name": this.state.orgVal,
                "addressLine1": this.state.addressForOrg,
                "addressLine2": "",
                "city": this.state.cityForOrg,
                "country": this.state.countryForOrg,
                "countryCode": "",
                "transactionsLimit":this.state.transactionsLimit,
                "postalCode": this.state.pinForOrg,
                "createdBy": this.state.defaultCognitoId,
                "updatedBy": this.state.defaultCognitoId,
                "environment": jsonConfig.environment
          }
          
          axios.post(jsonConfig.createOrgApi, dataCreate,{
            headers: {
              'X-API-KEY': jsonConfig.ApiKey,
              'Content-Type': 'application/json'
            }
          })
          .then((response) => {
            if(response.status===200 || response.status===201)
            {
                NotificationManager.success('Organization successfully created','Success');
                this.setState({
                    orgVal:'',
                    addressForOrg:'',
                    cityForOrg:'',
                    countryForOrg:'',
                    pinForOrg:'',
                    orgFilterValue:0,
                })
                this.getAllOrgs();
              
            }
            else{
                NotificationManager.error('Organization already exsists','Error');
            }
            
          }, (error) => {
            NotificationManager.error('Organization creation failed','Error');
            console.log(error);
          });
          
        }
        
      }
      getOrgDetails(event,orgName,orgid,isactive,key)
      {
        this.setState({
            activeOrg:key,
            editableOrgId:orgid,
            orgStatusEnableDisable:isactive
        })
        let sendOrgParam={
            "org": orgid
          }
          axios.post(jsonConfig.orgDetailsApi,sendOrgParam,{
            headers: {
              'X-API-KEY': jsonConfig.ApiKey,
            }
          }).then((response) => {
              console.log("GET ORG DETAILS",response.data.body);
            this.setState({
                clickedOrgName:orgName,
                totalBoards:response.data.body.totalBoard,
                totalActiveBoards:response.data.body.totalActiveBoard,
                totalInactiveBoards:response.data.body.totalInactiveBoard,
                totalRolesforthisOrg:response.data.body.totalRoles,
                totalUserforthisOrg:response.data.body.totalUsers,
                transactionsLimit:response.data.body.transactionsLimit,
                orgVal:response.data.body.organiazation,
                orgButtonStatus:false
            })
            if(response.data.body.addressLine1===null || response.data.body.addressLine1==='null')
            {
                this.setState({addressForOrg:''})
            }
            else{
                this.setState({addressForOrg:response.data.body.addressLine1})
            }
            if(response.data.body.country===null || response.data.body.country==='null')
            {
                this.setState({countryForOrg:''})
            }
            else{
                this.setState({countryForOrg:response.data.body.country})
            }
            if(response.data.body.city===null || response.data.body.city==='null')
            {
                this.setState({cityForOrg:''})
            }
            else{
                this.setState({cityForOrg:response.data.body.city})
            }
            if(response.data.body.postalCode===null || response.data.body.postalCode==='null')
            {
                this.setState({pinForOrg:''})
            }
            else{
                this.setState({pinForOrg:response.data.body.postalCode})
            }
          }, (error) => {
            console.log(error);
          });
      }
      async updateOrg()
      {
        var letters = /^[A-Za-z]+$/;
        if(this.state.orgVal.match(letters)===null || this.state.orgVal.match(letters)==='null')
        {
            NotificationManager.error('Organization must start with a letter and contain letters.Organization can only use English characters','Error');
        }
        else{
            var orgUpdateData={
                "name": this.state.orgVal,
                "addressLine1": this.state.addressForOrg,
                "city": this.state.cityForOrg,
                "country": this.state.countryForOrg,
                "postalCode": this.state.pinForOrg,
                "createdBy": this.state.defaultCognitoId,
                "updatedBy": this.state.defaultCognitoId,
                "transactionsLimit":this.state.transactionsLimit,
          }
          var updateResp=await axios.put(jsonConfig.rdsApi+"organizations/"+this.state.editableOrgId, orgUpdateData,{
            headers: {
              'X-API-KEY': jsonConfig.ApiKey,
              'Content-Type': 'application/json'
            }});
          if(updateResp.status===200)
          {
            this.setState({
                orgVal:'',
                addressForOrg:'',
                cityForOrg:'',
                countryForOrg:'',
                pinForOrg:'',
                orgButtonStatus:true,
                orgFilterValue:0,
                transactionsLimit:8000,
                clickedOrgName:'',
                totalUserforthisOrg:'',
                totalRolesforthisOrg:'',
                totalBoards:'',
                totalActiveBoards:'',
                totalInactiveBoards:''
            })
            this.getAllOrgs();
            NotificationManager.success('Organization successfully updated','Success');
          }
          else{
            NotificationManager.error('Organization updation failed','Error');
          }
          
        }
      }
    async orgFilterHandler(e)
    {
        var getVal=e.target.value;
        this.setState({orgFilterValue:e.target.value})
        var FilterOrg='';
        if(getVal==2)
        {
            FilterOrg=await axios.get(jsonConfig.rdsApi+"deactivatedOrganizations/",{
                headers: {
                  'X-API-KEY': jsonConfig.ApiKey,
                  'Content-Type': 'application/json'
                }});
        }
        else{
            FilterOrg=await axios.get(jsonConfig.rdsApi+"organizations/",{
                headers: {
                  'X-API-KEY': jsonConfig.ApiKey,
                  'Content-Type': 'application/json'
                }});
        }
        this.setState({
            orgList:FilterOrg.data.data
        })
    }
    async disableOrg(event,stat)
    {
        var orgDisableeData={
            "isActive": stat
        }
        if(stat===true)
        {
            this.setState({orgStatusMsg:'enabled'})
        }
        else{
            this.setState({orgStatusMsg:'disabled'})
        }
        
      var disableResp=await axios.put(jsonConfig.rdsApi+"organizations/"+this.state.editableOrgId, orgDisableeData,{
        headers: {
          'X-API-KEY': jsonConfig.ApiKey,
          'Content-Type': 'application/json'
        }});
      if(disableResp.status===200)
      {
        var interactionTable = "interactions";
        var docClient = new AWS.DynamoDB.DocumentClient();

        var params = {
            TableName : interactionTable,
            FilterExpression : "organizationId = :orgName",
            ExpressionAttributeValues: {
                ":orgName": this.state.editableOrgId
            }
        };
        docClient.scan(params, (err, data)=> {

            if(data.Items.length>0)
            {
                for(var s=0;s<data.Items.length;s++)
                {
                    var updtParams = {
                        TableName:interactionTable,
                        Key:{
                            "interactionId": data.Items[s].interactionId,
                        },
                        "UpdateExpression": "set boardStatus = :val1",
                        "ExpressionAttributeValues": {
                            ":val1": "Inactive",
                        },
                    };
                    docClient.update(updtParams, (err, data)=> {
                    if (err) {
                        console.error("Unable to update item. Error JSON:", JSON.stringify(err, null, 2));
                    } else {
                        console.log("Status change");
                    }
                    });
                }
            }
            else{
                console.log("No board available");
            }
        })
        this.setState({
            orgVal:'',
            addressForOrg:'',
            cityForOrg:'',
            countryForOrg:'',
            pinForOrg:'',
            orgButtonStatus:true,
            orgFilterValue:0,

            clickedOrgName:'',
            totalUserforthisOrg:'',
            totalRolesforthisOrg:'',
            totalBoards:'',
            totalActiveBoards:'',
            totalInactiveBoards:''
        })
        this.getAllOrgs();
        NotificationManager.success('Organization successfully '+this.state.orgStatusMsg,'Success');
      }
      else{
        NotificationManager.error('Failed to '+this.state.orgStatusMsg+ ' organization','Error');
      }
    }
    //----------ROLE PERMISSION---------------//
    
    async getScreenList(key,roleid,roleName,orgId)
    {
        this.setState({
            roleidforpermission:roleid,
            activeList:key,
            alterPanel:false,
            roleVal:roleName,
            editableRoleId:roleid,
            selectOrgId:orgId
        });
        var resScreen = await axios.get(jsonConfig.rdsApi+"screenPermissionByRole/"+roleid,{
            headers: {
              'X-API-KEY': jsonConfig.ApiKey,
              'Content-Type': 'application/json'
            }});
        this.setState({
            permissionList:resScreen.data.data
        })
    }
    async changeStatus(event,chkid,screenid)
    {
    var tempArr = this.state.permissionList
        tempArr.forEach(item => {
            if (item.screenId === event.target.value)
                item.status =  event.target.checked
            })
        this.setState({permissionList: tempArr})
        if(event.target.checked===true)
        {
            var stat='enabled';
        }
        else{
            stat='disabled';
        }
        if(chkid===null || chkid==='null')
        {
            var dataCreate={
                "screenId": screenid,
                "roleId": this.state.roleidforpermission,
                "status": stat,
                "createdBy": this.state.defaultCognitoId,
                "updatedBy": this.state.defaultCognitoId
            }
            
            var resStatus=await axios.post(jsonConfig.rdsApi+"rolesPermissionByScreens", dataCreate,{
                headers: {
                  'X-API-KEY': jsonConfig.ApiKey,
                  'Content-Type': 'application/json'
                }});
            if(resStatus.status===200)
            {
                NotificationManager.success('Roles permission updated','Success');
            }
        }
        else{
            var dataUpdt={
                "screenId": screenid,
                "roleId": this.state.roleidforpermission,
                "status": stat,
                "updatedBy": this.state.defaultCognitoId
            }
            var resStatusupdt=await axios.put(jsonConfig.rdsApi+"rolesPermissionByScreens/"+chkid, dataUpdt,{
                headers: {
                  'X-API-KEY': jsonConfig.ApiKey,
                  'Content-Type': 'application/json'
                }});
            if(resStatusupdt.status===200)
            {
                NotificationManager.success('Roles permission updated','Success');
            }
        }
    }
    changeAnalyseResultStatus=event=>
    {
        this.setState({analyseResultVal:event.target.checked});

        var globalSettingsTable="globalSettings"
        var docClient = new AWS.DynamoDB.DocumentClient();
        var globalSettingsParams = {
                TableName:globalSettingsTable,
                Item:{
                    environment: jsonConfig.environment,
                    displayAnalyseResult:event.target.checked,
                }
          };
          docClient.put(globalSettingsParams, (err, data) => {
            if (err) {
              console.error("Unable to add item messenger table. Error JSON:", JSON.stringify(err, null, 2));
              NotificationManager.error('Failed to change settings','Error');
            } else {
              console.log("Added messenger with organization item:", JSON.stringify(data, null, 2));
              NotificationManager.success('Settings successfully changed','Success');
            }
          });
    }
    changeSettingsStatus(event)
    {
        this.setState({settingsVal:event.target.checked});
        var globalSettingsTable="globalSettings"
        var docClient = new AWS.DynamoDB.DocumentClient();
        var globalSettingsParams = {
                TableName:globalSettingsTable,
                Item:{
                    environment: jsonConfig.environment,
                    checkmarkEnforcement:event.target.checked,
                }
          };
          docClient.put(globalSettingsParams, (err, data) => {
            if (err) {
              console.error("Unable to add item messenger table. Error JSON:", JSON.stringify(err, null, 2));
              NotificationManager.error('Failed to change settings','Error');
            } else {
              console.log("Added messenger with organization item:", JSON.stringify(data, null, 2));
              NotificationManager.success('Settings successfully changed','Success');
            }
          });
    }
//------------SMS SETTINGS--------//
getSmsSettings()
{
        
        var settingsTable = "globalSettings";
        var docClient = new AWS.DynamoDB.DocumentClient();
        var getParams = {
        TableName : settingsTable,
            FilterExpression : "environment = :orgName",
            ExpressionAttributeValues: {
                ":orgName": jsonConfig.environment
            }
        };
        docClient.scan(getParams,(err, data)=> {
        if (err) {
            console.log("Error", err);
        } else {
            if(data.Items.length>0)
            {
                if(data.Items[0].twilioAccountSID!==undefined)
                {
                    this.setState({
                        globalAccountSid:data.Items[0].twilioAccountSID,
                        globalAuthToken:data.Items[0].twilioAuthToken,
                    })
                }
                else{
            
                    this.setState({
                        globalAccountSid:'',
                        globalAuthToken:'',
                    })
                }
                
            }
            else{
            
                this.setState({
                    globalAccountSid:'',
                    globalAuthToken:'',
                })
            }
        }
    })
}

globalSidHandler= event => {
    this.setState({
        globalAccountSid: event.target.value
      });
  }
  globalTokenHandler= event => {
    this.setState({
        globalAuthToken: event.target.value
      });
  }
    
    

   updateSmsSettings()
   {
    if(this.state.globalAccountSid==='' || this.state.globalAuthToken==='')
    {
        NotificationManager.error('Please fill up all the fields','Error');
    }
    else{
        var settingsTable = "globalSettings";
        var docClient = new AWS.DynamoDB.DocumentClient();
        var insertSmsParams = {
        TableName:settingsTable,
        Item:{
            environment:jsonConfig.environment,
            twilioAccountSID: this.state.globalAccountSid,
            twilioAuthToken: this.state.globalAuthToken,
        }
        };
        docClient.put(insertSmsParams, (err, data) => {
        if (err) {
            //console.error("Unable to add item Client chat table. Error JSON:", JSON.stringify(err, null, 2));
            NotificationManager.error('SMS settings updation','Error');
        } else {
            NotificationManager.success('SMS settings successfully updated','Success');
            this.getSmsSettings();
        }
        });
    }
    
   }
getTwilioAccount()
{
    var settingsTable = "twilioSubAccount";
        var docClient = new AWS.DynamoDB.DocumentClient();
        var getParams = {
        TableName : settingsTable,
            FilterExpression : "twilioStatus = :val1",
            ExpressionAttributeValues: {
                ":val1": 'Allocated',
            }
        };
        docClient.scan(getParams,(err, data)=> {
        if (err) {
            console.log("Error", err);
        } else {
            if(data.Items.length>0)
            {
                this.setState({
                    twilioResultList:data.Items,
                    countryJson:data.Items[0].countryCodeJson
                })             
            }
            else{
                this.setState({
                    twilioResultList:[],
                    countryJson:[]
                })
            }
        }
    })
}
   
   coutrycodeHandler=event=>{
        var tempall=event.target.value;
        var tempexplode=tempall.split("-");
        this.setState({
            wholecode:event.target.value,
            dialCode:tempexplode[0],
            countryCode:tempexplode[1],
        })
   }
   subAcNameHandler=event=>{
    this.setState({
        subAcName: event.target.value
      });
    }
    phoneNumberHandler=event=>{
        this.setState({
            phoneNumber: event.target.value
          });
    }
getSmsDetails=()=>
{
        var smsTable = "SMSSettings";
        var docClient = new AWS.DynamoDB.DocumentClient();
        var getParams = {
        TableName : smsTable,
            FilterExpression : "organizationId = :orgId",
            ExpressionAttributeValues: {
                ":orgId": this.state.defaultOrgId
            }
        };
        docClient.scan(getParams,(err, data)=> {
        if (err) {
            console.log("Error", err);
        } else {
            console.log("SMS DATA",JSON.parse(data.Items[0].phoneNumberJson));
            if(data.Items.length>0)
            {
                this.setState({
                    smsSubAccName:data.Items[0].twilioSubAccountName,
                    phoneNumberJson:JSON.parse(data.Items[0].phoneNumberJson)
                })
                
            }
            else{
                this.setState({
                    smsSubAccName:'',
                    phoneNumberJson:[]
                })
                
            }
        }
    })
}
addSmsSettings=()=>
   {
    
    if(this.state.dialCode === 0 || this.state.dialCode === '' || this.state.subAcName==='' || this.state.subAcName===0 || this.state.phoneNumber==='')
    {
        NotificationManager.error('Please fill up all the fields','Error');
    }
    else{
        
        var tempcc=this.state.countryCode
        var tempPh=this.state.dialCode+this.state.phoneNumber;
        
        if(this.state.countryJson===undefined)
        {
            var jsonCountry=[];
        }
        else{
            jsonCountry=JSON.parse(this.state.countryJson);
        }
        
        
        var requestjson={};
        requestjson[tempcc] = tempPh;
        jsonCountry.push(requestjson);
        
        
        var smsTable = "SMSSettings";
        var docClient = new AWS.DynamoDB.DocumentClient();
        var updateParams = {
            TableName:smsTable,
            Key:{
              "organizationId": this.state.defaultOrgId
            },
            "UpdateExpression": "set phoneNumberJson = :val1",
            "ExpressionAttributeValues": {
              ":val1": JSON.stringify(jsonCountry),
            }
          };
          docClient.update(updateParams, (err, data) => {
            if (err) {
              NotificationManager.error('Unable to add phone number','Error');
              console.error("Unable to add item sentiment analysis table. Error JSON:", JSON.stringify(err, null, 2));
              
            } else {
              NotificationManager.success('Phone number succesfully added','Success');
              this.setState({
                dialCode:0,
                subAcName:0,
                phoneNumber:'',
                wholecode:0,
            })
                this.getSmsSettings();
            }
          });
    }
   }
    render() {
        
        return (
            <div id="Admin___Create_User" className="commonWidthHeight">
                <SideMenu appActions={this.state.updateUserStatus} sendProfImgUrl={this.state.profileImgUpdt}/>
                <div className="middlePanelOthers">
                <div id="HeaderOthers">
                    <div id="Interaction_Board">
                        <span>{this.state.headerRoleName}</span>
                    </div>
                </div>
                
                <div id="Group_2_A1_Group_45">

                    <div className="tabset">
                        <input type="radio" name="tabset" id="tab1" aria-controls="marzen" defaultChecked/>
                        <label htmlFor="tab1" className="tabHeading tabDeactive" onClick={(e) => this.generateCarousel(e)}>Users</label>

                        <input type="radio" name="tabset" id="tab2" aria-controls="rauchbier"/>
                        <label htmlFor="tab2" className="tabHeading tabDeactive">Roles</label>
                        {this.state.isSystemOrNot===true?(
                            <input type="radio" name="tabset" id="tab3" aria-controls="org" disabled={this.state.isSystemOrNot===true?false:true}/>
                        ):(
                            ''
                        )}
                        {this.state.isSystemOrNot===true?(
                            <label htmlFor="tab3" className="tabHeading tabDeactive">Organizations</label>
                        ):(
                            ''
                        )}
                        {this.state.isSystemOrNot===true?(
                            <input type="radio" name="tabset" id="tab4" aria-controls="settings"/>
                        ):(
                            ''
                        )}
                        {this.state.isSystemOrNot===true?(
                            <label htmlFor="tab4" className="tabHeading tabDeactive">Settings</label>
                        ):(
                            ''
                        )}
                        {this.state.isSystemOrNot===true?(
                            <input type="radio" name="tabset" id="tab5" aria-controls="sms"/>
                        ):(
                            ''
                        )}
                        {this.state.isSystemOrNot===true?(
                            <label htmlFor="tab5" className="tabHeading tabDeactive">SMS</label>
                        ):(
                            ''
                        )}
                        <div className="tab-panels">
                            <section id="marzen" className="tab-panel">
                                
                                    <div className="row" style={{height:'665px'}}>
                                        <div className="col-md-4" style={{marginTop:'1px',paddingLeft:'0px'}}>
                                        {this.state.isSystemOrNot===true ? (
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <select className="txtType inputForuser w-200 arrowAlignForw200" onChange={(e) =>this.filterUserOrgHandler(e)} value={this.state.filterUserOrg}>
                                                            <option value="all">All Orgnazitaion</option>
                                                                {this.state.orgList.map((value, key) => (
                                                                    <option value={value.id} key={key}>{value.name}</option>
                                                                ))}
                                                        </select>
                                                    </div>
                                                    <div className="col-md-12 mt12">
                                                        <select className="txtType inputForuser w-200 arrowAlignForw200" onChange={(e) =>this.filterUserRoleHandler(e)} value={this.state.filterUserRole}>
                                                            <option value="all">All Roles</option>
                                                                {this.state.rolesList.map((value, key) => (
                                                                    <option value={value.name} key={key}>{value.name}</option>
                                                                ))}
                                                        </select>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            ):(
                                            <div>
                                                <div className="col-md-12">
                                                        <input 
                                                            type="text" 
                                                            className="txtType w-200" 
                                                            value={this.state.defaultOrgName}
                                                            readOnly
                                                        />
                                                    </div>
                                                    <div className="col-md-12 mt12">
                                                            <select className="txtType inputForuser w-200 arrowAlignForw200" onChange={(e) =>this.filterUserRoleHandler(e)} value={this.state.filterUserRole}>
                                                                <option value="all">All Roles</option>
                                                                    {this.state.rolesList.map((value, key) => (
                                                                        <option value={value.name} key={key}>{value.name}</option>
                                                                    ))}
                                                            </select>
                                                    </div>
                                                </div>
                                            
                                            )}
                                            {this.state.isSystemOrNot===true?(
                                            <div>
                                            <div className="col-md-12 mt32 headerforUserTable">
                                                <div className="row">
                                                    <div className="col-md-4">Company</div>
                                                    <div className="col-md-4" style={{paddingLeft:'12px'}}>Name</div>
                                                    <div className="col-md-4" style={{paddingLeft:'10px'}}>Role</div>
                                                </div>
                                            </div>
                                            <div className="col-md-12 lh12 textforUserTable userListScroll" style={{marginTop:'2px'}}>
                                            {this.state.userList.map((value, key) => (
                                                <div 
                                                key={key} 
                                                className={this.state.activeUser === key ?'row paddingAlignforUser activeUser type_a':'row paddingAlignforUser type_a'}
                                                onClick={(e) => this.editUser(key,value.id,value.name,value.email,value.phoneNumber,value.organizationId,value.roleId,value.role,value.profilePictureUrl,value.isActive,value.createdAt)}
                                                >
                                                    <div className="col-md-4">
                                                        {value.organizationId===null?(
                                                            <span></span>
                                                        ):(
                                                        
                                                        value.organization.name.length>8?(
                                                            <span data-tip={value.organization.name}>
                                                                {value.organization.name.substring(0, 8)+".."}
                                                                <ReactTooltip place="bottom" type="dark" effect="solid"/>
                                                            </span>
                                                            
                                                        ):(
                                                            <span>{value.organization.name}</span>
                                                        ))}
                                                    </div>
                                                    <div className="col-md-4">
                                                        {value.name===null?(
                                                            <span></span>
                                                        ):(
                                                        value.name.length>8?(
                                                            <span data-tip={value.name}>
                                                                {value.name.substring(0, 8)+".."}
                                                                <ReactTooltip place="bottom" type="dark" effect="solid"/>
                                                            </span>
                                                        ):(
                                                            <span>{value.name}</span>
                                                        ))}
                                                    </div>
                                                    <div className="col-md-4">
                                                    {value.roleId===null?(
                                                            <span></span>
                                                    ):(
                                                    value.role.name.length>8?(
                                                        
                                                        <span data-tip={value.role.name}>
                                                                {value.role.name.substring(0, 8)+".."}
                                                                <ReactTooltip place="bottom" type="dark" effect="solid"/>
                                                        </span>
                                                    ):(
                                                        <span>{value.role.name}</span>
                                                        ))}
                                                    </div>
                                                </div>
                                            ))}
                                                
                                                </div>
                                            </div>
                                            ):(
                                            <div>
                                            <div className="col-md-12 mt16 lh12 headerforUserTable">
                                                <div className="row">
                                                    <div className="col-md-6">Name </div>
                                                    <div className="col-md-6">Role</div>
                                                </div>
                                            </div>
                                            <div className="col-md-12 mt32 textforUserTable userListScroll">
                                            {this.state.userList.map((value, key) => (
                                                <div 
                                                key={key} 
                                                className={this.state.activeUser === key ?'row paddingAlignforUser activeUser type_a':'row paddingAlignforUser type_a'}
                                                onClick={(e) => this.editUser(key,value.id,value.name,value.email,value.phoneNumber,value.organizationId,value.roleId,value.role,value.organization.name,value.profilePictureUrl,value.isActive,value.createdAt)}
                                                >
                                                    <div className="col-md-6">{value.name}</div>
                                                    <div className="col-md-6">{value.role.name}</div>
                                                </div>
                                            ))}
                                                
                                                </div>
                                            </div>
                                            )}
                                            <div className="col-md-12 mt32 pl0 ml10">
                                                <button className="button_secondary" disabled={this.state.userUpdateDisabled} onClick={(e) => this.resetProfile(e)}>Create new user</button>
                                            </div>
                                        </div>
                                        <div className="col-md-8 border-left">
                                          <div className="col-md-12">
                                              <div className="row">
                                                <div className="col-md-12">
                                                    <p id="SentimentHeaderTxt">{this.state.userHeading}</p>
                                                    <p className="labelText">{this.state.userSubHeading}</p>
                                                </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                    <div className="row">
                                                        <div className="col-md-12 lh12" style={{marginTop:'39px'}}>
                                                            <span className="labelText">Name</span>
                                                            <input 
                                                            type="text"
                                                            className="txtType inputForuser w-270 mt9"
                                                            value={this.state.name}
                                                            onChange={this.nameChange}
                                                            />
                                                        </div>
                                                        <div className="col-md-12 mt9 lh12">
                                                            <span className="labelText">Email</span>
                                                            <input 
                                                            type="text"
                                                            className="txtType inputForuser w-270 mt9"
                                                            value={this.state.userEmail}
                                                            onChange={this.emailChange}
                                                            readOnly={this.state.readonlyEmail}
                                                            />
                                                        </div>
                                                        <div className="col-md-12 mt9 lh12">
                                                            <span className="labelText">Phone</span>
                                                            <input 
                                                            type="text"
                                                            className="txtType inputForuser w-270 mt9"
                                                            value={this.state.userPhone}
                                                            onChange={this.phoneChange}
                                                            />
                                                        </div>
                                                        <div className="col-md-12 mt9 lh12">
                                                            <span className="labelText">Password</span>
                                                            <input 
                                                            type="password"
                                                            className="txtType inputForuser w-270 mt9"
                                                            value={this.state.userPassword}
                                                            onChange={this.passwordChange}
                                                            readOnly={this.state.readonlyPassword}
                                                            />
                                                        </div>
                                                        <div className="col-md-12 mt9 lh12">
                                                            <span className="labelText">Confirm Password</span>
                                                            
                                                                <input 
                                                                type="password"
                                                                className="txtType inputForuser w-270 mt9"
                                                                value={this.state.confirmPassword}
                                                                onChange={this.confirmPasswordChange}
                                                                onFocus={this.putCrossmarks}
                                                                readOnly={this.state.readonlyPassword}
                                                                style={{position:'relative'}}
                                                                />
                                                                {this.state.matchPasswordStatus==='tick'?
                                                                    <span className="mt10" style={{position:'absolute',left:'257px',top:'16px'}}>
                                                                        <i className="fa fa-check-circle checkIconColor" aria-hidden="true"></i>
                                                                    </span>
                                                                :''
                                                                }
                                                                {this.state.matchPasswordStatus==='cross'?
                                                                    <span className="mt10" style={{position:'absolute',left:'257px',top:'16px'}}>
                                                                        <i className="fa fa-times-circle checkCrossColor" aria-hidden="true"></i>
                                                                    </span>

                                                                :''
                                                                }
                                                                {this.state.matchPasswordStatus==='cross'?
                                                                    <div className="mt5 passwordNotMatchTxt">Password does not match</div>
                                                                    
                                                                :''
                                                                }
                                                            
                                                        </div>
                                                        
                                                        <div className="col-md-12 mt9 lh12">
                                                            <span className="labelText">Organization</span>
                                                            {this.state.isSystemOrNot===true?(
                                                            <select className="txtType inputForuser w-270 arrowAlignForPanel3 mt9" onChange={this.userOrgHandlerforUser} value={this.state.selectOrgId}>
                                                                <option value="0">Please Select</option>
                                                            {this.state.orgList.map((value, key) => (
                                                                <option value={value.id} key={key}>{value.name}</option>
                                                            ))}
                                                            </select>
                                                            ):(
                                                                <input 
                                                                type="text" 
                                                                className="txtType inputForuser w-270 mt9" 
                                                                value={this.state.defaultOrgName}
                                                                readOnly
                                                                />
                                                            )}
                                                        </div>
                                                        <div className="col-md-12 mt9 lh12">
                                                            <span className="labelText">Roles</span>
                                                            
                                                            <select className="txtType inputForuser w-270 arrowAlignForPanel3 mt9" onChange={this.userRoleHandler} value={this.state.selectRoleId}>
                                                                <option value="0">Please Select</option>
                                                            {this.state.rolesListforUsers.map((value, key) => (
                                                                <option value={value.id} key={key}>{value.name}</option>
                                                            ))}
                                                            </select>
                                                        </div>
                                                        
                                                        <div className="margin-align col-md-12 mt9 lh12">
                                                            <span className="labelText">Created On : </span>
                                                            <p className="colorChange spanText">{this.state.userCreationDate}</p>
                                                        </div>

                                                      </div>  
                                                    </div>
                                                    <div className="col-md-6">
                                                    <div className="row">
                                                        {this.createCarousel()}
                                                        <div className="col-md-12 text-center">
                                                            {this.state.imgUploadingState===false?(
                                                            <div>
                                                                <p className="labelText ml10 text-center">Choose an Avatar OR</p>
                                                                <div className="upload-btn-wrapper ml10 mt10">
                                                                    <button className="upload_button">Upload Photo</button>
                                                                    <input 
                                                                    type="file" 
                                                                    className="txtFileType"
                                                                    style={{width: '273px'}}
                                                                    onChange={(e) => this.uploadPhoto(e)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            ):(
                                                                <div className="provisioningForUserImg provisionWaitbox mt16">
                                                                    <div className="row">
                                                                    <div className="col-md-3">
                                                                        <img src={img_prLoader} style={{margin:'10px',height:'30px',width:'30px'}} alt="img_prLoader"/>
                                                                    </div>
                                                                    <div className="col-md-9">
                                                                        <p className="labelText colorChange">Please wait ... </p>
                                                                        <p className="labelText colorChange mt10">Image is uploading</p>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="col-md-12 text-right userButtonAlign">
                                                        {this.state.userStat!==''?(
                                                            
                                                            eval(this.state.userStat)===true?(
                                                                <button className="button_delete mt15" style={{paddingRight:'20px'}} onClick={(e) => this.changeUserStat(this.state.editableCognitoId,false,this.state.selectRoleId)}>DISABLE</button>
                                                            ):(
                                                                <button className="button_enable mt15" style={{paddingRight:'20px'}} onClick={(e) => this.changeUserStat(this.state.editableCognitoId,true,this.state.selectRoleId)}>ENABLE</button>
                                                            )
                                                            
                                                        ):(
                                                            ''
                                                        )}
                                                            {this.state.userUpdateDisabled===true?(
                                                            <button className="button pull-right" style={{marginRight:'34px'}} onClick={(e) => this.createProfile(e)}>CREATE</button>
                                                            ):(
                                                            <button className="button pull-right" style={{marginRight:'34px'}} onClick={(e) => this.updateProfile(e)}>UPDATE</button>
                                                            )} 
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                          </div>
                                        </div>
                                    </div>
                                
                            </section>
                            <section id="rauchbier" className="tab-panel">
                                <div className="row">
                                    <div className="col-md-3" style={{marginTop:'20px'}}>
                                    {this.state.isSystemOrNot===true?(
                                        <div className="col-md-12" style={{marginBottom:'15px'}}>
                                            <select className="txtType inputForuser" onChange={(e) =>this.filterRolesByOrg(e)} value={this.state.changeOrgval}>
                                                <option value="0">Select Organaization</option>
                                            {this.state.orgList.map((value, key) => (
                                                <option value={value.id} key={key}>{value.name}</option>
                                            ))}
                                            </select>
                                        </div>
                                        ):(
                                            ''
                                        )}
                                        <div className="col-md-12">
                                            <p id="SentimentHeaderTxt">Roles</p>
                                        </div>
                                        {this.state.noSuperRoleList.map((value, key) => (
                                            <div className="col-md-12" key={key} onClick={(e) => this.getScreenList(key,value.id,value.name,value.organizationId)}>
                                                <p className={this.state.activeList === key ? 'Roletitle activeRole': 'Roletitle'}>{value.name}</p>
                                            </div>
                                        ))}
                                        <div className="col-md-12 ml0 mt12">
                                            <button className="button" onClick={(e) => this.openCreateRole(e)}> Create Role</button>
                                        </div>
                                    </div>
                                    {this.state.alterPanel===false ? (
                                    <div className="col-md-9 border-left" style={{marginTop:'20px'}}>
                                        <div className="col-md-12">
                                            <p id="SentimentHeaderTxt">Edit Roles</p>
                                            <p className="labelText">Edit roles here of your user</p>
                                        </div>
                                        {this.state.permissionList.length>0?(
                                        <div>
                                        <div className="col-md-12" style={{marginTop:'20px'}}>
                                            <span className="labelText">Role</span>
                                            <input 
                                            type="text"
                                            className="txtType inputForuser"
                                            value={this.state.roleVal}
                                            onChange={this.roleHandler}
                                            />
                                        </div>
                                        
                                        <div className="col-md-12" style={{marginTop:'10px'}}>
                                            <button className="button" onClick={(e) => this.updateRole(e)}>Update</button>
                                        </div>
                                        </div>
                                        ):(
                                            ''
                                        )}
                                        {this.state.permissionList.map((value, key) => (
                                            <div className="col-md-12 margin-align_30" key={key}>
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <span className="screentitle">{value.screenName}</span>
                                                    </div>
                                                    <div className="col-md-9">
                                                        <label className="switch">
                                                            <input type="checkbox" key={key} value={value.screenId} checked={eval(value.status)} onChange={(e) => this.changeStatus(e,value.id,value.screenId)}/>
                                                            <span className="slider round"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        ))}
                                    </div>
                                    ):(
                                    <div className="col-md-9 border-left" style={{marginTop:'20px'}}>
                                        <div className="col-md-12">
                                            <p id="SentimentHeaderTxt">Create Roles</p>
                                        </div>
                                        
                                        <div className="col-md-12" style={{marginTop:'20px'}}>
                                            <span className="labelText">Role</span>
                                            <input 
                                            type="text"
                                            className="txtType inputForuser"
                                            value={this.state.roleVal}
                                            onChange={this.roleHandler}
                                            />
                                        </div>
                                        {this.state.isSystemOrNot===true?(
                                        <div className="col-md-12" style={{marginTop:'10px'}}>
                                            
                                        </div>
                                        ):(
                                        <div className="col-md-12" style={{marginTop:'10px'}}>
                                            <span className="labelText">Organization</span>
                                            <input 
                                            type="text" 
                                            className="txtType inputForuser" 
                                            value={this.state.defaultOrgName}
                                            readOnly
                                            />
                                        </div>
                                        )}
                                        <div className="col-md-12" style={{marginTop:'10px'}}>
                                            <button className="button" onClick={(e) => this.createRole(e)}> Create</button>
                                        </div>
                                        
                                    </div>
                                    )}
                                </div>
                                
                                
                            </section>
                            {this.state.isSystemOrNot===true?(
                            <section id="org" className="tab-panel">
                            <div className="row" style={{height:'669px'}}>
                                    <div className="col-md-3" style={{marginTop:'10px',height:'655px'}}>
                                        <div className="col-md-12" style={{marginTop:'20px'}}>
                                            <span className="labelText">Organization</span>
                                            <input 
                                            type="text"
                                            className="txtType inputForuser"
                                            value={this.state.orgVal}
                                            onChange={this.orgHandler}
                                            />
                                        </div>
                                        <div className="col-md-12 mt10">
                                            <span className="labelText">Select Country</span>
                                            <CountryDropdown
                                            value={this.state.countryForOrg}
                                            onChange={(val) => this.selectCountry(val)}
                                            className="txtType inputForuser"
                                            />
                                        </div>
                                        <div className="col-md-12 mt10">
                                            <span className="labelText">Select Region</span>
                                            <RegionDropdown
                                            country={this.state.countryForOrg}
                                            value={this.state.cityForOrg}
                                            onChange={(val) => this.selectRegion(val)}
                                            className="txtType inputForuser"
                                            />
                                        </div>
                                        
                                        <div className="col-md-12 mt10">
                                            <span className="labelText">Address Line 1</span>
                                            <textarea 
                                            type="text"
                                            className="txtType"
                                            value={this.state.addressForOrg}
                                            onChange={this.addressHandler}
                                            rows="2"
                                            cols="1"
                                            />
                                        </div>
                                        <div className="col-md-12 mt10">
                                            <span className="labelText">Postal Code</span>
                                            <input 
                                            type="text"
                                            className="txtType inputForuser"
                                            value={this.state.pinForOrg}
                                            onChange={this.postalHandler}
                                            />
                                        </div>
                                        <div className="col-md-12 mt10">
                                            <span className="labelText">Transactions Limit</span>
                                            <input 
                                            type="text"
                                            className="txtType inputForuser"
                                            value={this.state.transactionsLimit}
                                            onChange={this.transactionsLimitHandler}
                                            />
                                        </div>
                                        {this.state.orgButtonStatus===true?(
                                        <div className="col-md-12" style={{marginTop:'10px'}}>                     
                                                <button className="button" onClick={(e) => this.createOrg(e)}>CREATE</button>
                                        </div>
                                        ):(
                                            <div className="col-md-12" style={{marginTop:'10px'}}> 
                                                <button className="button" onClick={(e) => this.updateOrg(e)}>UPDATE</button>
                                                {this.state.orgStatusEnableDisable===true?(
                                                    <button className="button_delete type_a ml10" onClick={(e) => this.disableOrg(e,false)}>DISABLE</button>
                                                ):(
                                                    <button className="button_enable type_a ml10" onClick={(e) => this.disableOrg(e,true)}>ENABLE</button>
                                                )}
                                            </div>
                                            )}
                                        <div className="col-md-12 pl0" style={{marginTop:'10px'}}>
                                            <span className="labelSubText">Organization Details</span>
                                        </div>
                                        <div className="col-md-12 ImpNoPaddingwithMarginRestOthers">
                                            <span className="labelText">Organization Name : </span>
                                            <span className="labelText colorChange">{this.state.clickedOrgName}</span>
                                        </div>
                                        <div className="col-md-12 ImpNoPaddingwithMarginRestOthers">
                                            <span className="labelText">Number of users : </span>
                                            <span className="labelText colorChange">{this.state.totalUserforthisOrg}</span>
                                        </div>
                                        <div className="col-md-12 ImpNoPaddingwithMarginRestOthers">
                                            <span className="labelText">Number of Roles : </span>
                                            <span className="labelText colorChange">{this.state.totalRolesforthisOrg}</span>
                                        </div>
                                        <div className="col-md-12 ImpNoPaddingwithMarginRestOthers">
                                            <span className="labelText">Total No. of Interaction Boards : </span>
                                            <span className="labelText colorChange">{this.state.totalBoards}</span>
                                        </div>
                                        <div className="col-md-12 ImpNoPaddingwithMarginRestOthers">
                                            <span className="labelText">No. of Active Interaction Boards : </span>
                                            <span className="labelText colorChange">{this.state.totalActiveBoards}</span>
                                        </div>
                                        <div className="col-md-12 ImpNoPaddingwithMarginRestOthers">
                                            <span className="labelText">No of Inactive Interaction Boards : </span>
                                            <span className="labelText colorChange">{this.state.totalInactiveBoards}</span>
                                        </div>
                                    </div>
                                    <div className="col-md-9 border-left userListScroll" style={{marginTop:'10px',height:'655px'}}>
                                        <div className="col-md-12 mt10 mb10">
                                            <select className="txtType inputForuser w-200 arrowAlignForw200" value={this.state.orgFilterValue} onChange={(e) =>this.orgFilterHandler(e)}>
                                                <option value="1">Active</option>
                                                <option value="2">Inactive</option>           
                                            </select>
                                        </div>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th className="txtsmall_sd">Name</th>
                                                    <th className="txtsmall_sd">Country</th>
                                                    <th className="txtsmall_sd">Region</th>
                                                    <th className="txtsmall_sd">Address</th>
                                                    <th className="txtsmall_sd">Postal Code</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.orgList.map((value, key) => (
                                                <tr key={key} className="type_a" onClick={(e) => this.getOrgDetails(e,value.name,value.id,value.isActive,key)}>
                                                    <td className="tdcolor_sd">{value.name}</td>
                                                    <td className="tdcolor_sd">{value.country}</td>
                                                    <td className="tdcolor_sd">{value.city}</td>
                                                    <td className="tdcolor_sd">{value.addressLine1}</td>
                                                    <td className="tdcolor_sd">{value.postalCode}</td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </section>
                            ):(
                                ''
                            )}
                            <section id="settings" className="tab-panel">
                                <div className="row">
                                        <div className="col-md-12">
                                            <div className="col-md-12">
                                                <p id="SentimentHeaderTxt fs22">Global Settings</p>
                                            </div>
                                            <div className="col-md-12 margin-align_30">
                                                <span className="labelText">Only allow check marked components on board : </span>
                                                <label className="switch">
                                                    <input type="checkbox" 
                                                    value={this.state.settingsVal} 
                                                    checked={this.state.settingsVal}
                                                    onChange={(e) => this.changeSettingsStatus(e)}
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            <div className="col-md-12 margin-align_30">
                                                <span className="labelText">Display analyze result : </span>
                                                <label className="switch">
                                                    <input type="checkbox" 
                                                    value={this.state.analyseResultVal} 
                                                    checked={this.state.analyseResultVal}
                                                    onChange={(e) => this.changeAnalyseResultStatus(e)}
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            <div className="col-md-12 margin-align_30">
                                                <p className="labelSubText lh16">Twilio Global Credentials</p>

                                            </div>
                                            <div className="col-md-12" style={{marginTop:'10px'}}>
                                                <span className="labelText">AccountSID</span>
                                                <input 
                                                type="text" 
                                                className="txtType inputForuser w-270 mt9" 
                                                value={this.state.globalAccountSid}
                                                onChange={this.globalSidHandler}
                                                />
                                            </div>
                                            <div className="col-md-12" style={{marginTop:'10px'}}>
                                                <span className="labelText">AuthToken</span>
                                                <input 
                                                type="text" 
                                                className="txtType inputForuser w-270 mt9"
                                                value={this.state.globalAuthToken}
                                                onChange={this.globalTokenHandler}
                                                />
                                            </div>
                                            
                                            <div className="col-md-12" style={{marginTop:'10px'}}>
                                                <button className="button" onClick={(e) => this.updateSmsSettings()}>UPDATE</button>
                                            </div>
                                            
                                        </div>
                                    </div>
                            </section>
                            <section id="sms" className="tab-panel">
                                <div className="row">
                                    <div className="col-md-8">
                                    <table className="table">
                                                <thead>
                                                <tr>
                                                    
                                                    <th className="txtsmall_sd" style={{width:'40%'}}>Account Name</th>
                                                    
                                                    <th className="txtsmall_sd" style={{width:'60%'}}>Phone Number</th>
                                                    
                                                </tr>
                                                </thead>
                                                <tbody>
                                                    
                                                        <tr>
                                                            
                                                            <td className="tdcolor_sd" style={{wordBreak:'break-word'}}>{this.state.smsSubAccName}</td>
                                                            
                                                            <td className="tdcolor_sd" style={{wordBreak:'break-word'}}>
                                                                {this.state.phoneNumberJson.map((phNo, phIndex) => (
                                                                    <span key={phIndex}>{Object.values(phNo)[0]} , </span>
                                                                ))}
                                                            </td>
                                                            
                                                        </tr>
                                                    
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="col-md-4 border-left">
                                            <div className="col-md-12">
                                                <p id="SentimentHeaderTxt fs22">Twilio Sub Account</p>
                                            </div>
                                            
                                            
                                            <div className="col-md-12" style={{marginTop:'10px'}}>
                                                <span className="labelText">Phone Number</span>
                                                
                                                <select className="txtType inputForuser w-270 arrowAlignForPhone displayInline" 
                                                onChange={this.coutrycodeHandler} value={this.state.wholecode}>
                                                    <option value="0">Select Country code</option>
                                                {countryCodes.map((value, key) => (
                                                    <option value={value.dial_code+"-"+value.code} key={key}>{value.name} ({value.dial_code})</option>
                                                ))}
                                                </select>
                                                <input 
                                                type="text" 
                                                className="txtType inputForuser w-270 mt9"
                                                value={this.state.phoneNumber}
                                                onChange={this.phoneNumberHandler}
                                                />
                                                
                                            </div>
                                            <div className="col-md-12" style={{marginTop:'10px'}}>
                                                <span className="labelText">Sub Account Name</span>
                                                <select className="txtType inputForuser w-270 arrowAlignForPhone displayInline" 
                                                onChange={this.subAcNameHandler} value={this.state.subAcName}>
                                                <option value="0">Please Select</option>
                                                {this.state.twilioResultList.map((value, key) => (
                                                    <option value={value.accountSid} key={key}>{value.subAccountName}</option>
                                                ))}
                                                </select>
                                            </div>
                                            
                                            <div className="col-md-12" style={{marginTop:'10px'}}>
                                                <button className="button" onClick={(e) => this.addSmsSettings()}>ADD</button>
                                            </div>
                                        </div>
                                        
                                    </div>
                            </section>
                        </div>
                    </div>
                </div>
                </div>
                <NotificationContainer/>
                {this.createLoader()}
            </div>
        )
    }
}