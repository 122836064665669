import React, { Component } from 'react';
import SideMenu from './sideMenu.js';
import page404 from './images/screen/404.svg';

export default class notFound extends Component {
    
    render() {
        return (
            <div id="not_found" className="commonWidthHeight" style={{backgroundColor:'#F7FAFF'}}>
                <SideMenu/>
                <div className="middlePanelOthers">
                    <div style={{overflow:'hidden'}}>
                        <img src={page404} alt="404bot" style={{width:'1100px',padding: '140px 65px 0px 0px'}}/>
                    </div>
                </div>
            </div>
        )
    }
}