import React, { Component } from 'react';
import SideMenuCollapsble from './sideMenuCollapsble.js';
import userIcon from './images/userIcon-1.png';
import sliderSentiment from './images/screen/ss.png';
import sliderMarker from './images/screen/marker.png';
import vrSentiment from './images/screen/vrSentiment.png';
import img_wa from './images/ec/whatsapp.png';
import img_shocked from './images/em_shocked.png';

import img_fb from './images/ec/messenger.png';
import img_sms from './images/ec/sms.png';
import agentIcon from './images/agentIcon.png';
import send from './images/buttons/send.svg';
import attach from './images/buttons/attach.svg';
import img_Refresh from './images/buttons/refresh_ac.svg';
import img_add from './images/buttons/create_ac.svg';
import img_edit from './images/buttons/edit_ac.svg';

import user_2 from './images/user-2.png';
import user_3 from './images/user-3.png';


export default class AgentChatScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {       
            replyText:'',
            agentStatusColor:'#29CC75',
        };
      }
    instantReply=(e,text)=>
    {
        this.setState({replyText:text});
    }
    replyTextHandler=event=>
    {
        this.setState({replyText:event.target.value});
    }
    agentStatusHandler=event=>
    {
        this.setState({agentStatusColor:event.target.value});
    }
    render() {
        return (
            <div id="agent_chat" className="commonWidthHeight">
                <SideMenuCollapsble/>
                <div className="middlePanelOthers_agentChat">
                    <div id="HeaderOthers">
                        <div id="Interaction_Board" className="w-1245">
                            <span className="pull-left">Agent Chat</span>
                            
                            <div className="ml20 pull-right w-132">
                                <div className="attention_text">
                                    <div className="attentionNumber pull-left">4</div>
                                    <div className="pull-left ml5 mt4" style={{fontFamily:'Averta-Semibold'}}> Need attention</div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div id="agent_chat_main_screen">
                        <div className="row_agent">
                            <div className="col-md-3_agent_chat pl0 pr0">
                            <div className="agent_chat_box">
                                        <div className="mt12">
                                            <div className="mainBorderAgentIcon pull-left">
                                                <div className="agentBorderDiv">
                                                    <img src={agentIcon} alt="agentIcon" className="agentIcon"/>
                                                </div>
                                            </div>
                                            
                                            <div className="nameAddressBox pull-left ml5 mt10 w-187">
                                                <p className="name_text_agent">
                                                    <span style={{fontFamily:'Averta-Semibold'}}>Michael Wayne</span>
                                                    <span className="agent_status_dot ml12" style={{backgroundColor:this.state.agentStatusColor}}></span>
                                                </p>
                                                <p className="type_agent">Contact Centre Agent</p>
                                                <p className="address_text_agent">
                                                        Unicorn Tech Solutions, Seattle
                                                </p>

                                                <div className="address_text_agent mt22">
                                                    <span style={{color:'#24527E'}}>Status </span>
                                                    <select className="txtType w-101 arrowAlignForw101 displayInline" style={{backgroundColor: '#f2f7ff',fontFamily:'Averta-Semibold'}} onChange={this.agentStatusHandler}>
                                                        <option value="#29CC75" className="defaultOpt">Available</option>
                                                        <option value="#FFC107" className="defaultOpt">Away</option>
                                                        <option value="#F44336" className="defaultOpt">Busy</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="clear"></div>
                                            
                                        </div>
                                    </div>
                                <div className="mt20 ml13">
                                    <input 
                                    type="text"
                                    className="txtType w-267 searchConversion"
                                    placeholder="Search Conversation"
                                    style={{backgroundColor:'#fff'}}
                                    />
                                </div>
                                <div className="user_chat_list mt20">
                                    <div className="user_chat_box" style={{backgroundColor:'#fff',borderLeft:'4px solid #008CFF',paddingLeft:'8px'}}>
                                        <div className="mt5" style={{overflow:'hidden',position:'relative'}}>
                                            <div className="socialIcon_agent_chat pull-left">
                                                <img src={img_fb} className="channelIcon_agent_chat" alt="img_fb_1"/>
                                            </div>
                                            <img src={user_3} alt="userIcon" className="chatIcon pull-left ml12"/>
                                            <div className="nameAddressBox pull-left ml9">
                                                <p className="userName_chatBox_agent_chat">Antonio Austin</p>
                                                <p className="usertxt_chatBox_agent_chat">
                                                    How I Lost The Secret Of Dazzling Success For 20 Years...
                                                </p>
                                            </div>
                                            <div className="unread_number_ac">3</div>
                                            <div className="timebox pull-left redMarkNotification_ac">
                                                <span>38mins</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="user_chat_box">
                                        <div className="mt5" style={{overflow:'hidden',position:'relative'}}>
                                            <div className="socialIcon_agent_chat pull-left">
                                                <img src={img_wa} className="channelIcon_agent_chat" alt="img_wa"/>
                                            </div>
                                            
                                            <img src={userIcon} alt="userIcon" className="chatIcon pull-left ml12"/>
                                            
                                            <div className="nameAddressBox pull-left ml9">
                                                <p className="userName_chatBox_agent_chat">Alexander Adams</p>
                                                
                                                <p className="usertxt_chatBox_agent_chat">
                                                    How I Lost The Secret Of Dazzling Success For 20 Years...
                                                </p>
                                            </div>
                                            <div className="timebox pull-left">
                                                <span className="italic">02 Sec ago</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="user_chat_box">
                                        <div className="mt5" style={{overflow:'hidden',position:'relative'}}>
                                        <div className="socialIcon_agent_chat pull-left">
                                                <img src={img_sms} className="channelIcon_agent_chat" alt="img_sms_1"/>
                                            </div>
                                            <img src={user_2} alt="userIcon" className="chatIcon pull-left ml12"/>
                                            <div className="nameAddressBox pull-left ml9">
                                                <p className="userName_chatBox_agent_chat">Sam Garza</p>
                                                
                                                <p className="usertxt_chatBox_agent_chat">
                                                    How I Lost The Secret Of Dazzling Success For 20 Years...
                                                </p>
                                            </div>
                                            <div className="timebox pull-left">
                                                <span className="italic">02 Sec ago</span>    
                                            </div>
                                        </div>
                                    </div>
                                    <div className="user_chat_box">
                                        <div className="mt5" style={{overflow:'hidden',position:'relative'}}>
                                            <div className="socialIcon_agent_chat pull-left">
                                                <img src={img_wa} className="channelIcon_agent_chat" alt="img_wa"/>
                                            </div>
                                            <img src={user_2} alt="userIcon" className="chatIcon pull-left ml12"/>
                                            <div className="nameAddressBox pull-left ml9">
                                                <p className="userName_chatBox_agent_chat">Lloyd Hawkins</p>
                                                
                                                <p className="usertxt_chatBox_agent_chat">
                                                    How I Lost The Secret Of Dazzling Success For 20 Years...
                                                </p>
                                            </div>
                                            <div className="timebox pull-left">
                                                <span className="italic">02 Sec ago</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="user_chat_box">
                                        <div className="mt5" style={{overflow:'hidden',position:'relative'}}>
                                            <div className="socialIcon_agent_chat pull-left">
                                                <img src={img_sms} className="channelIcon_agent_chat" alt="img_wa"/>
                                            </div>
                                            <img src={userIcon} alt="userIcon" className="chatIcon pull-left ml12"/>
                                            <div className="nameAddressBox pull-left ml9">
                                                <p className="userName_chatBox_agent_chat">Marguerite Malone</p>
                                                
                                                <p className="usertxt_chatBox_agent_chat">
                                                    How I Lost The Secret Of Dazzling Success For 20 Years...
                                                </p>
                                            </div>
                                            <div className="timebox pull-left">
                                                <span className="italic">02 Sec ago</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="user_chat_box">
                                        <div className="mt5" style={{overflow:'hidden',position:'relative'}}>
                                            <div className="socialIcon_agent_chat pull-left">
                                                <img src={img_fb} className="channelIcon_agent_chat" alt="img_wa"/>
                                            </div>
                                            <img src={userIcon} alt="userIcon" className="chatIcon pull-left ml12"/>
                                            <div className="nameAddressBox pull-left ml9">
                                                <p className="userName_chatBox_agent_chat">Darrell Simmons</p>
                                                
                                                <p className="usertxt_chatBox_agent_chat">
                                                    How I Lost The Secret Of Dazzling Success For 20 Years...
                                                </p>
                                            </div>
                                            <div className="timebox pull-left">
                                                <span className="italic">02 Sec ago</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="user_chat_box">
                                        <div className="mt5" style={{overflow:'hidden',position:'relative'}}>
                                            <div className="socialIcon_agent_chat pull-left">
                                                <img src={img_sms} className="channelIcon_agent_chat" alt="img_wa"/>
                                            </div>
                                            <img src={userIcon} alt="userIcon" className="chatIcon pull-left ml12"/>
                                            <div className="nameAddressBox pull-left ml9">
                                                <p className="userName_chatBox_agent_chat">Caleb Quinn</p>
                                                
                                                <p className="usertxt_chatBox_agent_chat">
                                                    How I Lost The Secret Of Dazzling Success For 20 Years...
                                                </p>
                                            </div>
                                            <div className="timebox pull-left">
                                                <span className="italic">02 Sec ago</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="user_chat_box">
                                        <div className="mt5" style={{overflow:'hidden',position:'relative'}}>
                                            <div className="socialIcon_agent_chat pull-left">
                                                <img src={img_fb} className="channelIcon_agent_chat" alt="img_wa"/>
                                            </div>
                                            <img src={userIcon} alt="userIcon" className="chatIcon pull-left ml12"/>
                                            <div className="nameAddressBox pull-left ml9">
                                                <p className="userName_chatBox_agent_chat">Roy Lindsey</p>
                                                
                                                <p className="usertxt_chatBox_agent_chat">
                                                    How I Lost The Secret Of Dazzling Success For 20 Years...
                                                </p>
                                            </div>
                                            <div className="timebox pull-left">
                                                <span className="italic">02 Sec ago</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="user_chat_box">
                                        <div className="mt5" style={{overflow:'hidden',position:'relative'}}>
                                            <div className="socialIcon_agent_chat pull-left">
                                                <img src={img_sms} className="channelIcon_agent_chat" alt="img_wa"/>
                                            </div>
                                            <img src={userIcon} alt="userIcon" className="chatIcon pull-left ml12"/>
                                            <div className="nameAddressBox pull-left ml9">
                                                <p className="userName_chatBox_agent_chat">Noah Brewer</p>
                                                
                                                <p className="usertxt_chatBox_agent_chat">
                                                    How I Lost The Secret Of Dazzling Success For 20 Years...
                                                </p>
                                            </div>
                                            <div className="timebox pull-left">
                                                <span className="italic">02 Sec ago</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-md-5_agent_chat pl10 pr0 userOriented_chat_window mt20 ml20">
                                <div className="col-md-12 pl10 pb13 pr0" style={{overflow:'hidden'}}>
                                    <img src={user_3} className="userChatIconforChatBox" alt="happy"/>
                                    <div className="divHeaderTxt_ac pull-left w-300">
                                        <div className="SentimentHeaderTxt_ac">Antonio Austin 
                                            <span className="ml10" style={{color:'#008CFF'}}>
                                                <span className="agent_status_dot"></span>
                                            </span>
                                            <img src={img_wa} className="transferIcon_agent_chat ml12" alt="img_wa"/>
                                        </div>
                                        
                                        <p className="customerEmail_ac">antonioaustin09@gmail.com</p>
                                    </div>
                                    <div className="divHeaderTxt_ac pull-right mr20">
                                        <p id="SentimentSubHeaderTxt" className="mt20" style={{color:'#008CFF',textDecoration:'underline'}}>Transfer</p>
                                    </div>
                                    <div className="clear"></div>
                                    <div className="PreviousChatDetails_box pull-left">
                                        <div>
                                            <span className="semibold pull-left">Last Chat Summary</span>
                                            <span className="ml10 fs10 pull-right semibold">12:36PM</span>
                                            <span className="ml10 fs10 pull-right semibold">12 Mar 19</span>
                                        </div>
                                        <div className="prevoius_details_text pull-left mt10">When someone mentions barbecue images comes to the mind like cooking at the backyard grill</div>
                                        <div className="pull-right mt10">
                                            <span>
                                                <img src={img_wa} className="channelIcon_agent_chat" alt="img_wa"/>
                                            </span>
                                            <span className="ml5">
                                                <img src={img_shocked} className="channelIcon_agent_chat" alt="img_wa"/>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="PreviousChatDetails_box pull-right mr20">
                                        <div className="pull-left">
                                            <p className="box_agent_browserdetails">Mozilla FireFox</p>
                                            <p className="box_agent_browserdetails mt9">Sign In Page</p>
                                            <p className="box_agent_browserdetails mt9">Melbourne, AU</p>
                                        </div>
                                        <div className="pull-right ml16">
                                            <p className="box_agent_browserdetails">Whatsapp</p>
                                            <p className="box_agent_browserdetails mt9">BJS: Proactive Chat</p>
                                            <p className="box_agent_browserdetails mt9">00 : 12 : 32 : 00</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 mt15 mb10" style={{overflow:'hidden'}}>
                                    <div className="dayforagentchat">Today</div>
                                    <div className="chat-message-bot-chat-box">
                                    Everybody that has ever been to a meeting, 
                                    can recall the all familiar “passing” of the business cards.
                                    </div>
                                </div>
                                <div className="col-md-12  mt10" style={{overflow:'hidden'}}>
                                    <div className="chat-message-bot-chat-box">Parties and family gatherings are popular this time of year.</div>
                                    <div className="agoTimeAgentChat text-left pull-left mt5">5 mins ago</div>
                                </div>
                                <div className="col-md-12" style={{overflow:'hidden'}}>
                                    <div className="chat-message-user-chat-box margin-align">Yes sir, 
                                    I completely understand and I am deeply
                                    sorry for the inconvenience.
                                    </div>
                                    <div className="agoTimeAgentChat pull-right text-right mt5">2 seconds ago</div>
                                </div>
                                
                                
                                <div className="col-md-12 margin-align_30" style={{position:'absolute',bottom:'40px'}}>
                                    <textarea 
                                    className="txtTypeChat_chat_agent"
                                    value={this.state.replyText}
                                    onChange={this.replyTextHandler}
                                    >

                                    </textarea>
                                    <img src={img_add} alt="attach" className="agent_chat_attach"/>
                                    <img src={send} alt="send" className="agent_chat_send"/>
                                </div>
                            </div>


                            <div className="col-md-4_agent_chat ml20">
                                <div className="col-md-12 sentiment_chat_box mt20 bgWhite">
                                    <div className="sentiment_header_chat_box pull-left">
                                        Sentiment Analysis
                                    </div>
                                    <div className="refresh_agent_chat_sentiment pull-right">
                                        <img src={img_Refresh} data-tip="Refresh" alt="refresh" className="type_a"/>
                                    </div>
                                    <div className="clear"></div>
                                    <div className="mt12">
                                        <img src={sliderSentiment} alt="sentiment"/>
                                    </div>
                                    <div className="markerSentiment">
                                        <img src={sliderMarker} alt="sliderMarker"/>
                                    </div>
                                    <p className="markerText ml5">Negetive</p>  
                                    
                                    
                                </div>
                                <div className="col-md-12 sentiment_chat_box mt20 bgWhite">
                                    <div className="sentiment_header_chat_box pull-left">
                                        Visual Recoginition
                                    </div>
                                    <div className="refresh_agent_chat_sentiment pull-right">
                                        <img src={img_Refresh} data-tip="Refresh" alt="refresh" className="type_a"/>
                                    </div>
                                    <img src={vrSentiment} alt="vrSentiment" className="mt12"/>
                                    <p className="attention_text img_process_color mt12">
                                        <span className="fs14 img_process_color">Foods</span> 
                                        <span className="pull-right mt6 fadedColor fs10 averta-italic">3 Images Processed</span>
                                    </p>
                                </div>
                                <div className="col-md-12 sentiment_chat_box mt20 bgWhite">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th className="sentiment_header_chat_box" style={{width:'80%'}}>Instant Replies</th>
                                                <th className="attention_text img_process_color" style={{width:'10%'}}>
                                                    <img src={img_add} alt="img_add"/>
                                                </th>
                                                <th className="attention_text img_process_color" style={{width:'10%'}}>
                                                    <img src={img_edit} alt="img_edit" className="ml12"/>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="usertxt_chatBox_agent_chat_ir borderBottomTd">Your feedback help us improve our service and support...</td>
                                                <td className="borderBottomTd">&nbsp;</td>
                                                <td className="borderBottomTd text-right">
                                                    <img src={attach} alt="edit" className="type_a" onClick={(e) => this.instantReply(e,'Your feedback help us improve our service and support')}/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="usertxt_chatBox_agent_chat_ir borderBottomTd">Bryce Canyon A Stunning Us Travel Destination</td>
                                                <td className="borderBottomTd">&nbsp;</td>
                                                <td className="borderBottomTd text-right">
                                                    <img src={attach} alt="edit" className="type_a" onClick={(e) => this.instantReply(e,'Bryce Canyon A Stunning Us Travel Destination')}/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="usertxt_chatBox_agent_chat_ir borderBottomTd">Global Resorts Network Grn Putting Timeshares To Shame</td>
                                                <td className="borderBottomTd">&nbsp;</td>
                                                <td className="borderBottomTd text-right">
                                                    <img src={attach} alt="edit" className="type_a" onClick={(e) => this.instantReply(e,'Global Resorts Network Grn Putting Timeshares To Shame')}/>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-md-12 sentiment_chat_box mt20 bgWhite">
                                    <div className="sentiment_header_chat_box">
                                        Feedback Survey Request
                                    </div>
                                    
                                    <p className="usertxt_chatBox_agent_chat fs12 mt10">
                                        <label className="switch_ac" style={{marginBottom:'0px'}}>
                                            <input type="checkbox"  defaultChecked={false}/>
                                            <span className="slider_ac round"></span>
                                        </label>&nbsp;&nbsp;
                                        For Agent
                                    </p>
                                    <p className="usertxt_chatBox_agent_chat fs12 mt6">
                                        <label className="switch_ac" style={{marginBottom:'0px'}}>
                                            <input type="checkbox"  defaultChecked={true}/>
                                            <span className="slider_ac round"></span>
                                        </label>&nbsp;&nbsp;
                                        For Customer
                                    </p>
                                    <button className="button_delete delete_ac mt15">End Chat</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}