import React, { Component } from 'react';
import SideMenu from './sideMenu.js';
import Dropzone from 'react-dropzone'

import img_wa from './images/ec/whatsapp.png';
import img_fb from './images/ec/messenger.png';
import img_inf from './images/buttons/inf.svg';

import img_elKik2 from './images/ec/kik.png';
import img_elLine from './images/ec/line.png';
import img_elInsta from './images/ec/instagram.png';
import img_elfb from './images/ec/F_icon.png';
import img_elsc from './images/ec/snapchat.png';
import img_elwc from './images/ec/wechat.png';
import img_elli from './images/ec/linkedin.png';
import img_elsms from './images/ec/sms.png';
import img_elreddit from './images/ec/reddit.png';
import img_elpin from './images/ec/pinterest.png';
import img_eltwitt from './images/ec/Twitter_Bird.png';
import img_eltele from './images/ec/telegram.png';
import img_elyt from './images/ec/youtube.png';
import img_elVb from './images/ec/viber.png';
import img_elClose from './images/arrow_prev.png';
import img_flAu from './images/Flag_of_Australia.png';
import img_drag from './images/buttons/drag.svg';

import {NotificationContainer, NotificationManager} from 'react-notifications';
import Modal from 'react-modal';
import { default as Amplify, Auth, Storage } from "aws-amplify";
import axios from 'axios';
var wc = require('which-country');
var AWS = require("aws-sdk");
const jsonConfig = require("./Config.json");

export default class Content3 extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            countryFlag: img_flAu,
            countryCode:'AUS',
            modalUploadIsOpen: false,
            modalPersonaliseIsOpen: false,
            margin_left:'9px !important',
            areadefaultVal:'We’re pleased to inform that your registered email address,is now activated with a lot of new feature.',
            csvSmsHeader:[],
            csvSmsStatus:false,
            csvTitle:'',
            campaignCSVfile:'',
            csvType:'',
            csvHeaderCoulmn:'',
            getCsvUrl:'',
            sendCampaignData:'',
            estReach:0,
            dragDropStatus:false,
        };
        this.showPosition = this.showPosition.bind(this);
        this.getCsvFile = this.getCsvFile.bind(this);
        this.acceptedFiles = this.acceptedFiles.bind(this);
        this.onClick_elSaveCsv = this.onClick_elSaveCsv.bind(this);
      }
    componentDidMount()
    {
        //console.log(this.props.location.state);
        if(this.props.location.state!==undefined)
        {
            
            this.setState({sendCampaignData:this.props.location.state.sendData})
            var getParseCampaignData=JSON.parse(this.props.location.state.sendData);
            var expUrl=getParseCampaignData.fileUrl;
            console.log(expUrl);
            var getFileName=expUrl.split("/");
            this.setState({csvTitle:decodeURI(getFileName[4])});
            this.setState({csvSmsHeader:this.props.location.state.csvSmsHeader})
        }
        this.getLocation();
        Modal.setAppElement('body');
        AWS.config.update({
            credentials: new AWS.CognitoIdentityCredentials({
              IdentityPoolId: jsonConfig.awsConfigCredentials.IdentityPoolId,
              RoleArn: jsonConfig.awsConfigCredentials.RoleArn,
            }),
            region: jsonConfig.awsConfigRegion,
          });
          this.getUserDetails();
    }
    async getUserDetails()
    {
        var user = await Auth.currentAuthenticatedUser();
        var getUserById = await axios.get(jsonConfig.rdsApi+"users/"+user.attributes.sub,{
            headers: {
              'X-API-KEY': jsonConfig.ApiKey,
              'Content-Type': 'application/json'
            }});
        this.setState({
          defaultOrgName:getUserById.data.data[0].organization.name,
          defaultOrgId:getUserById.data.data[0].organizationId,
          defaultRoleName:getUserById.data.data[0].role.name,
          getCognitoId:user.username,
          defaultCognitoId:user.username
        })
    }
    onClick_elUpload= (ev) =>{
        this.setState({modalUploadIsOpen: true});
    }
    onClick_elPersonalise= (ev) =>{
        this.setState({modalPersonaliseIsOpen: true});
    }
    closeUploadModal=()=> {
        this.setState({modalUploadIsOpen: false});
      }
    closePersonaliseModal =()=>{
        this.setState({modalPersonaliseIsOpen: false});
    }
    onClick_elContentPrev= (ev) =>{
		//this.props.history.push("/content2");
    }
    onClick_elContentNext= (ev) =>{
		this.props.history.push({pathname:"/content2",state: { csvHeader: this.state.csvSmsHeader,sendData:this.state.sendCampaignData }});
    }
    onClick_elRemoveCsv=()=>
    {
        this.setState({csvTitle:''})
    }
      getLocation() {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(this.showPosition);
        } else {
          console.log("BROWSER NOT SUPPORTED");
        }
      }
      
      showPosition(position) {
        var getCountryCode=wc([position.coords.longitude,position.coords.latitude ]);
        var tempCountrycode=getCountryCode.substring(0,getCountryCode.length-1)
        var getFlagUrl="https://www.countryflags.io/"+tempCountrycode+"/flat/64.png"
        
            this.setState({
                countryFlag: getFlagUrl,
                countryCode:tempCountrycode
            })
      }
      acceptedFiles=(e)=>
      {
          console.log("CSV",csvFilePath.name);
        var csvFilePath=e[0];
        if(csvFilePath.type==='application/vnd.ms-excel')
        {
            var reader = new FileReader();
            reader.onload = event => {
                var dataDetails=reader.result.trim();
                var lines=dataDetails.split("\n");
                var headers=lines[0].split(",");
                
                this.setState({
                    campaignCSVfile:csvFilePath,
                    csvTitle:csvFilePath.name,
                    csvType:csvFilePath.type,
                    csvSmsHeader:headers,
                    csvSmsStatus:true,
                    estReach:lines.length-1,
                    dragDropStatus:true,
                })
            };
              reader.readAsBinaryString(csvFilePath);
        }
        else{
            this.setState({
                csvSmsHeader:[],
                csvSmsStatus:false,
                csvTitle:''
            })
            NotificationManager.error('Please upload CSV file','Error');
        }
      }
      getCsvFile(e)
      {
        var csvFilePath=e.target.files[0];
        console.log(csvFilePath);
        if(csvFilePath.type==='application/vnd.ms-excel')
        {
            var reader = new FileReader();
            reader.onload = event => {
                var dataDetails=reader.result.trim();
                var lines=dataDetails.split("\n");
                var headers=lines[0].split(",");
                
                this.setState({
                    campaignCSVfile:csvFilePath,
                    csvTitle:csvFilePath.name,
                    csvType:csvFilePath.type,
                    csvSmsHeader:headers,
                    csvSmsStatus:true,
                    estReach:lines.length-1
                })
            };
              reader.readAsBinaryString(csvFilePath);
        }
        else{
            this.setState({
                csvSmsHeader:[],
                csvSmsStatus:false,
                csvTitle:''
            })
            NotificationManager.error('Please upload CSV file','Error');
        }
      }
      selectCsvHeader=event=>
      {
          this.setState({csvHeaderCoulmn:event.target.value})
      }
      async onClick_elSaveCsv()
      {
        if(this.state.csvHeaderCoulmn==='' || this.state.csvHeaderCoulmn===null || this.state.csvHeaderCoulmn===undefined)
        {
            //this.setState({modalUploadIsOpen: false});
            NotificationManager.error('Please select csv header for phone number','Error');
        }
        else{

        
        this.setState({modalUploadIsOpen: false});
        AWS.config.update({
            region: jsonConfig.awsConfigRegion, // Put your aws region here
            accessKeyId: jsonConfig.accessKeyId,
            secretAccessKey: jsonConfig.secretAccessKey
          })
          Amplify.configure({
            Storage: {
                AWSS3: {
                    bucket: jsonConfig.campaignFileBucket, //REQUIRED -  Amazon S3 bucket
                }
            }
          });
          try {
            let uploadResult = await Storage.put(this.state.csvTitle, this.state.campaignCSVfile, {
              contentType: this.state.csvType
            })
            /**/let getCsvS3=await Storage.get(uploadResult.key);
            var csvSplit=getCsvS3.split("?");
            
            /**/this.setState({
              getCsvUrl:csvSplit[0],
            });
            NotificationManager.success('CSV successfully uploaded','Success');
          } catch (err) {
            console.log(err)
          }
          var sendData={
                "organizationId": this.state.defaultOrgId,
                "scheduledDateAndTime": "",
                "connectors": [
                    {
                        "name": "SMS",
                        "contactsColumn": this.state.csvHeaderCoulmn
                    }
                ],
                "content": "",
                "fileUrl": this.state.getCsvUrl,
                "status": "",
                "campaignName":"",
            }
            this.setState({sendCampaignData:JSON.stringify(sendData)})
        }
      }
    render() {
        return (
            <div id="Smart_Feed___Content3" className="commonWidthHeight">
                <SideMenu/>
                <div className="middlePanelOthers">
                <div id="HeaderOthers">
                    <div id="Interaction_Board">
                        <span>Digital Broadcasting</span>
                    </div>
                    <div className="pull-right flagDiv" style={{marginTop:'-15px'}}>
                        <span><img src={this.state.countryFlag} alt="au" className="mr7 flagSize"/></span>
                        <span>{this.state.countryCode}</span>
                    </div>
                </div>
                
                    <div id="Digital_broadcasting">
                        <div className="row_content_1">
                            <div className="col-md-8-content_2">
                                <div className="col-md-3_content_2">
                                    <div className="db_content_header_text ml6">Channels</div>
                                    <div className="db_channel_container_content_2">
                                        <div className="db_content2_subheader_text ml6">Direct Message</div>
                                        <div className="db_row_channel_holder">

                                            <div className="col-md-3_channelbox activeIcon_channels_db progressFull_channel_icon">
                                                <div className="channel_icon_holder text-center">
                                                    <img src={img_elsms} alt="img_elsms" className="channel_icon_size"/>
                                                </div>
                                                <div className="channel_text_holder mt6">
                                                    <div className="channel_text">SMS / MMS</div>
                                                    <div className="channel_sub_text">Direct</div>
                                                </div>
                                            </div>
                                            <div className="col-md-3_channelbox ml20">
                                                <div className="channel_icon_holder text-center">
                                                    <img src={img_wa} alt="img_wa" className="channel_icon_size"/>
                                                </div>
                                                <div className="channel_text_holder mt6">
                                                    <div className="channel_text">Whatsapp</div>
                                                    <div className="channel_sub_text">Direct</div>
                                                </div>
                                            </div>
                                            <div className="col-md-3_channelbox ml20">
                                                <div className="channel_icon_holder text-center">
                                                    <img src={img_wa} alt="img_wa" className="channel_icon_size"/>
                                                </div>
                                                <div className="channel_text_holder mt6">
                                                    <div className="channel_text">Whatsapp</div>
                                                    <div className="channel_sub_text">Stories</div>
                                                </div>
                                            </div>
                                            <div className="col-md-3_channelbox ml20">
                                                <div className="channel_icon_holder text-center">
                                                    <img src={img_elKik2} alt="img_elKik2" className="channel_icon_size"/>
                                                </div>
                                                <div className="channel_text_holder mt6">
                                                    <div className="channel_text">Kik</div>
                                                    <div className="channel_sub_text">Direct</div>
                                                </div>
                                            </div>
                                            
                                            <div className="col-md-3_channelbox ">
                                                <div className="channel_icon_holder text-center">
                                                    <img src={img_elInsta} alt="img_elInsta" className="channel_icon_size"/>
                                                </div>
                                                <div className="channel_text_holder mt6">
                                                    <div className="channel_text">Instagram</div>
                                                    <div className="channel_sub_text">Direct</div>
                                                </div>
                                            </div>
                                            <div className="col-md-3_channelbox ml20">
                                                <div className="channel_icon_holder text-center">
                                                    <img src={img_elInsta} alt="img_elInsta" className="channel_icon_size"/>
                                                </div>
                                                <div className="channel_text_holder mt6">
                                                    <div className="channel_text">Instagram</div>
                                                    <div className="channel_sub_text">Feeds</div>
                                                </div>
                                            </div>
                                            <div className="col-md-3_channelbox ml20">
                                                <div className="channel_icon_holder text-center">
                                                    <img src={img_elInsta} alt="img_elInsta" className="channel_icon_size"/>
                                                </div>
                                                <div className="channel_text_holder mt6">
                                                    <div className="channel_text">Instagram</div>
                                                    <div className="channel_sub_text">Stories</div>
                                                </div>
                                            </div>
                                            <div className="col-md-3_channelbox ml20">
                                                <div className="channel_icon_holder text-center">
                                                    <img src={img_fb} alt="img_elfb" className="channel_icon_size"/>
                                                </div>
                                                <div className="channel_text_holder mt6">
                                                    <div className="channel_text">Messenger</div>
                                                    <div className="channel_sub_text">Direct</div>
                                                </div>
                                            </div>



                                            <div className="col-md-3_channelbox">
                                                <div className="channel_icon_holder text-center">
                                                    <img src={img_elfb} alt="img_elfb" className="channel_icon_size"/>
                                                </div>
                                                <div className="channel_text_holder mt6">
                                                    <div className="channel_text">Facebook</div>
                                                    <div className="channel_sub_text">feeds</div>
                                                </div>
                                            </div>
                                            <div className="col-md-3_channelbox ml20">
                                                <div className="channel_icon_holder text-center">
                                                    <img src={img_elfb} alt="img_elfb" className="channel_icon_size"/>
                                                </div>
                                                <div className="channel_text_holder mt6">
                                                    <div className="channel_text">Facebook</div>
                                                    <div className="channel_sub_text">Stories</div>
                                                </div>
                                            </div>
                                            <div className="col-md-3_channelbox ml20">
                                                <div className="channel_icon_holder text-center">
                                                    <img src={img_elsc} alt="img_elsc" className="channel_icon_size"/>
                                                </div>
                                                <div className="channel_text_holder mt6">
                                                    <div className="channel_text">Snapchat</div>
                                                    <div className="channel_sub_text">Direct</div>
                                                </div>
                                            </div>
                                            <div className="col-md-3_channelbox ml20">
                                                <div className="channel_icon_holder text-center">
                                                    <img src={img_elsc} alt="img_elsc" className="channel_icon_size"/>
                                                </div>
                                                <div className="channel_text_holder mt6">
                                                    <div className="channel_text">Snapchat</div>
                                                    <div className="channel_sub_text">Stories</div>
                                                </div>
                                            </div>




                                            <div className="col-md-3_channelbox">
                                                <div className="channel_icon_holder text-center">
                                                    <img src={img_elwc} alt="img_elwc" className="channel_icon_size"/>
                                                </div>
                                                <div className="channel_text_holder mt6">
                                                    <div className="channel_text">Wechat</div>
                                                    <div className="channel_sub_text">Direct</div>
                                                </div>
                                            </div>
                                            <div className="col-md-3_channelbox ml20">
                                                <div className="channel_icon_holder text-center">
                                                    <img src={img_elli} alt="img_elli" className="channel_icon_size"/>
                                                </div>
                                                <div className="channel_text_holder mt6">
                                                    <div className="channel_text">Linkedin</div>
                                                    <div className="channel_sub_text">Direct</div>
                                                </div>
                                            </div>
                                            <div className="col-md-3_channelbox ml20">
                                                <div className="channel_icon_holder text-center">
                                                    <img src={img_elli} alt="img_elli" className="channel_icon_size"/>
                                                </div>
                                                <div className="channel_text_holder mt6">
                                                    <div className="channel_text">Linkedin</div>
                                                    <div className="channel_sub_text">Feeds</div>
                                                </div>
                                            </div>
                                            <div className="col-md-3_channelbox ml20">
                                                <div className="channel_icon_holder text-center">
                                                    <img src={img_elLine} alt="img_elLine" className="channel_icon_size"/>
                                                </div>
                                                <div className="channel_text_holder mt6">
                                                    <div className="channel_text">Line</div>
                                                    <div className="channel_sub_text">Direct</div>
                                                </div>
                                            </div>



                                            <div className="col-md-3_channelbox">
                                                <div className="channel_icon_holder text-center">
                                                    <img src={img_elreddit} alt="img_elreddit" className="channel_icon_size"/>
                                                </div>
                                                <div className="channel_text_holder mt6">
                                                    <div className="channel_text">Reddit</div>
                                                    <div className="channel_sub_text">Feeds</div>
                                                </div>
                                            </div>
                                            <div className="col-md-3_channelbox ml20">
                                                <div className="channel_icon_holder text-center">
                                                    <img src={img_elpin} alt="img_elpin" className="channel_icon_size"/>
                                                </div>
                                                <div className="channel_text_holder mt6">
                                                    <div className="channel_text">Pinterest</div>
                                                    <div className="channel_sub_text">Direct</div>
                                                </div>
                                            </div>
                                            <div className="col-md-3_channelbox ml20">
                                                <div className="channel_icon_holder text-center">
                                                    <img src={img_elpin} alt="img_elpin" className="channel_icon_size"/>
                                                </div>
                                                <div className="channel_text_holder mt6">
                                                    <div className="channel_text">Pinterest</div>
                                                    <div className="channel_sub_text">Direct</div>
                                                </div>
                                            </div>
                                            <div className="col-md-3_channelbox ml20">
                                                <div className="channel_icon_holder text-center">
                                                    <img src={img_eltwitt} alt="img_eltwitt" className="channel_icon_size"/>
                                                </div>
                                                <div className="channel_text_holder mt6">
                                                    <div className="channel_text">Twitter</div>
                                                    <div className="channel_sub_text">Direct</div>
                                                </div>
                                            </div>



                                            <div className="col-md-3_channelbox">
                                                <div className="channel_icon_holder text-center">
                                                    <img src={img_eltwitt} alt="img_eltwitt" className="channel_icon_size"/>
                                                </div>
                                                <div className="channel_text_holder mt6">
                                                    <div className="channel_text">Twitter</div>
                                                    <div className="channel_sub_text">Feeds</div>
                                                </div>
                                            </div>
                                            <div className="col-md-3_channelbox ml20">
                                                <div className="channel_icon_holder text-center">
                                                    <img src={img_eltele} alt="img_eltele" className="channel_icon_size"/>
                                                </div>
                                                <div className="channel_text_holder mt6">
                                                    <div className="channel_text">Telegram</div>
                                                    <div className="channel_sub_text">Direct</div>
                                                </div>
                                            </div>
                                            <div className="col-md-3_channelbox ml20">
                                                <div className="channel_icon_holder text-center">
                                                    <img src={img_elyt} alt="img_elyt" className="channel_icon_size"/>
                                                </div>
                                                <div className="channel_text_holder mt6">
                                                    <div className="channel_text">Youtube</div>
                                                    <div className="channel_sub_text">Feeds</div>
                                                </div>
                                            </div>
                                            <div className="col-md-3_channelbox ml20">
                                                <div className="channel_icon_holder text-center">
                                                    <img src={img_elyt} alt="img_elyt" className="channel_icon_size"/>
                                                </div>
                                                <div className="channel_text_holder mt6">
                                                    <div className="channel_text">Youtube</div>
                                                    <div className="channel_sub_text">Stories</div>
                                                </div>
                                            </div>


                                            <div className="col-md-3_channelbox">
                                                <div className="channel_icon_holder text-center">
                                                    <img src={img_elVb} alt="img_elVb" className="channel_icon_size"/>
                                                </div>
                                                <div className="channel_text_holder mt6">
                                                    <div className="channel_text">Viber</div>
                                                    <div className="channel_sub_text">Stories</div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5_content_2">
                                    <div className="db_content_header_text">Audience</div>
                                    <div className="db_channel_container_content_2">
                                    
                                        <div className="db_fileUploadHolder_content2">
                                            <div className="fileuploader_content2">
                                                <div className="fileupload_text_content2">File Uploaded :</div>
                                                <div className="fileupload_link_content2 type_a" onClick={this.onClick_elUpload}>Upload File</div>
                                            </div>
                                            {this.state.csvTitle!==''?(
                                            <div className="fileuploader_content2 mt7">
                                                <div className="filename_text_content2">{this.state.csvTitle}</div>
                                                <div className="fileremove_text_content2" onClick={this.onClick_elRemoveCsv}>Remove</div>
                                            </div>
                                            ):(
                                                ''
                                            )}
                                        </div>
                                        
                                        <div className="dividerfromuploader_content2"></div>
                                        <div className="db_content2_subheader_text mt26">Direct Message</div>
                                        <div className="db_content2_messageContainer mt9">
                                            <div className="db_content2_message_1">
                                                <div className="db_content2_imgHolder">
                                                    <img src={img_elsms} className="db_content_2_text_icon" alt="img_elsms"/>
                                                    <span className="db_content_2_text_icon_name">SMS / MMS</span>
                                                </div>
                                                <div className="db_content2_textHolder">
                                                    {this.state.csvTitle!==''?(
                                                        <div>
                                                            <div className="db_content_2_select_text type_a" style={{marginLeft:'0px'}} onClick={this.onClick_elPersonalise}>Personalize</div>
                                                            <div className="db_content_2_est_text mt10">Est. Reach {this.state.estReach}</div>
                                                        </div>
                                                    ):(
                                                        ''
                                                    )}
                                                    
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <div className="db_content2_subheader_text mt26">Feeds & Stories</div>
                                        <div className="db_content_2_information">
                                            <img src={img_inf} alt="img_inf"/>
                                            <div className="db_content_2_inf_text">The content gets posted on all your feeds</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            

                            <div className="col-md-4-content">
                                <div className="db_all_step">
                                    <div className="db_serial_step">
                                        <div className="db_step_divider">
                                            <div className="db_step_box">1</div>
                                            <div className="db_active_step">
                                                <div className="db_step_header">Select Channels<br/>& Audience</div>
                                                <div className="db_step_subtext">Choose the streams & <br/>Audience for your content</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="db_step_line"></div>
                                    <div className="db_serial_step" style={{marginTop:'44px'}}>
                                        <div className="db_step_divider">
                                            <div className="db_step_box_disable">2</div>
                                            <div className="db_step_subtext_disable">Create<br/> Content</div>
                                        </div>
                                    </div>
                                    <div className="db_step_line_2"></div>
                                    <div className="db_serial_step" style={{marginTop:'51px'}}>
                                        <div className="db_step_divider">
                                            <div className="db_step_box_disable">3</div>
                                            <div className="db_step_subtext_disable">Preview <br/>Content</div>
                                        </div>
                                    </div>
                                    <div className="db_step_line_3"></div>
                                    <div className="db_serial_step" style={{marginTop:'51px'}}>
                                        <div className="db_step_divider">
                                            <div className="db_step_box_disable">4</div>
                                            <div className="db_step_subtext_disable">Schedule <br/>Broadcast</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt190">
                                    
                                    <button className="button ml35 db_next_button" onClick={this.onClick_elContentNext}>NEXT</button>
                                </div>
                            </div>
                        </div>   
                    </div>

                </div>
                


                <Modal
                isOpen={this.state.modalUploadIsOpen}
                onRequestClose={this.closeUploadModal}
                style={{
                    overlay: {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 140, 255, 0.45)'
                    },
                    content : {
                    top                   : '50%',
                    left                  : '50%',
                    right                 : 'auto',
                    bottom                : 'auto',
                    marginRight           : '-50%',
                    transform             : 'translate(-50%, -50%)',
                    borderRadius          : '18px',
                    backgroundColor       : '#f2f7ff',
                    overflow              : 'hidden',
                    width                 : '588px',
                    //height                :'414px',
                    padding               :'27px'
                    }
                }}
                >
                    <div className="row_content_modal">
                        <div className="modal_header">
                            <div className="modal_close_holder">
                                <img src={img_elClose} alt="close" className="img_close type_a" onClick={this.closeUploadModal}/>
                            </div>
                            <div className="modal_header_text_holder ml20">
                                <div className="content_modal_header">Audience</div>
                                <div className="content_modal_sub_header">Upload File</div>
                                {this.state.csvTitle!==''?(
                                <div className="content_modal_header">{this.state.csvTitle}</div>
                                ):(
                                    ''
                                )}
                            </div>
                        </div>
                        <div className="content_upload_tab_holder ml27 mt25">
                            <div className="tabset_upload_content">
                                <input type="radio" name="tabset" id="tab1" aria-controls="computer" defaultChecked/>
                                <label htmlFor="tab1" className="tabHeading tabDeactive_upload_content" style={{marginLeft:'-31px'}}>Computer</label>

                                <input type="radio" name="tabset" id="tab2" aria-controls="cloud"/>
                                <label htmlFor="tab2" className="tabHeading tabDeactive_upload_content">Cloud Drive</label>

                                <input type="radio" name="tabset" id="tab3" aria-controls="crm"/>
                                <label htmlFor="tab3" className="tabHeading tabDeactive_upload_content">CRM</label>

                                <div className="tab-panels-upload_content">
                                    <section id="computer" className="tab-panel-upload-content">
                                        
                                            <Dropzone onDrop={(e) => this.acceptedFiles(e)}>
                                            {({getRootProps, getInputProps}) => (
                                                <section>
                                                <div {...getRootProps()}>
                                                    <div className="drgaImgHolder">
                                                        <img src={img_drag} alt="drag"/>
                                                    </div>
                                                    
                                                </div>
                                                </section>
                                            )}
                                            </Dropzone>
                                        <div className="dragTextHolder mt10">
                                            Drag and Drop your Files here
                                        </div>
                                        <div className="orTextHolder mt10">
                                            OR
                                        </div>
                                        <div className="browseButtonHolder mt10">
                                            
                                            <div className="upload-btn-wrapper ml10 mt10">
                                                <button className="upload_button_browse">BROWSE</button>
                                                <input 
                                                type="file" 
                                                className="txtFileType type_a"
                                                style={{width: '273px'}}
                                                onChange={(e) => this.getCsvFile(e)}
                                                />
                                            </div>
                                        </div>
                                        {this.state.csvSmsStatus===true?(
                                        <div>
                                            <div className="dragTextHolder mt10">
                                            select the column for phone number
                                            </div>
                                            <div className="csvHeaderSelectHolder ml16 mt10">
                                                <select className="txtType selectFordb w-120 arrowAlignForw120" onChange={this.selectCsvHeader}>
                                                    <option value="0">Select Column</option>
                                                    {this.state.csvSmsHeader.map((value, key) => (
                                                        <option value={value} key={key}>{value}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="csvHeaderSelectHolder ml16 mt10">
                                                <button className="button db_next_button" onClick={this.onClick_elSaveCsv}>UPLOAD</button>
                                            </div>
                                        </div>
                                        ):(
                                            ''
                                        )}
                                    </section>
                                    <section id="cloud" className="tab-panel-upload-content">
                                        <div>
                                            
                                        </div>
                                    </section>
                                    <section id="crm" className="tab-panel-upload-content">
                                        <div>
                                            
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>


                <Modal
                isOpen={this.state.modalPersonaliseIsOpen}
                onRequestClose={this.closePersonaliseModal}
                style={{
                    overlay: {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 140, 255, 0.45)'
                    },
                    content : {
                    top                   : '50%',
                    left                  : '50%',
                    right                 : 'auto',
                    bottom                : 'auto',
                    marginRight           : '-50%',
                    transform             : 'translate(-50%, -50%)',
                    borderRadius          : '18px',
                    backgroundColor       : '#f2f7ff',
                    overflow              : 'hidden',
                    width                 : '764px',
                    height                :'464px',
                    padding               :'27px'
                    }
                }}
                >
                    <div className="row_content_modal">
                        <div className="modal_header">
                            <div className="modal_close_holder">
                                <img src={img_elClose} alt="close" className="img_close type_a" onClick={this.closePersonaliseModal}/>
                            </div>
                            <div className="modal_header_text_holder ml20">
                                <div className="content_modal_header">SMS / MMS</div>
                                <div className="content_modal_sub_header">Personalize</div>
                            </div>
                        </div>
                        <div className="personalize_container">
                            <div className="list_container_content">
                                <div className="list_heading">List Preview :</div>
                                <div className="list_name ml12">{this.state.csvTitle}</div>
                            </div>
                            <div className="property_container mt9">
                                <div className="table_container">
                                    <div className="table_container_layer"></div>
                                    <table className="table table-bordered list-table">
                                        <thead>
                                            <tr>
                                            {this.state.csvSmsHeader.map((value, key) => (
                                                <th key={key}>{value}</th>
                                            ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Buckaroo</td>
                                                <td>Banzai</td>
                                                <td>buckaroo@onclipboard.com</td>
                                                <td>Wonka Industries</td>
                                                
                                                
                                            </tr>

                                            <tr>
                                                <td>Buckaroo</td>
                                                <td>Banzai</td>
                                                <td>buckaroo@onclipboard.com</td>
                                                <td>Wonka Industries</td>
                                                
                                                
                                            </tr>

                                            <tr>
                                                <td>Buckaroo</td>
                                                <td>Banzai</td>
                                                <td>buckaroo@onclipboard.com</td>
                                                <td>Wonka Industries</td>
                                                
                                                
                                            </tr>

                                            <tr>
                                                <td>Buckaroo</td>
                                                <td>Banzai</td>
                                                <td>buckaroo@onclipboard.com</td>
                                                <td>Wonka Industries</td>
                                                
                                                
                                            </tr>

                                            <tr>

                                                <td>Buckaroo</td>
                                                <td>Banzai</td>
                                                <td>buckaroo@onclipboard.com</td>
                                                <td>Wonka Industries</td>
                                                
                                                
                                            </tr>

                                            <tr>
                                                <td>Buckaroo</td>
                                                <td>Banzai</td>
                                                <td>buckaroo@onclipboard.com</td>
                                                <td>Wonka Industries</td>
                                                
                                                
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="msg_container">
                                    <div className="secondary_button_holder">
                                    {this.state.csvSmsHeader.map((value, key) => (
                                        <button className="button btn_content3" key={key}>{value}</button>
                                    ))}
                                        
                                    </div>
                                    <div className="msg_body_holder mt27">
                                        <div className="labelText">Message Body</div>
                                        <textarea 
                                                className="txtType inputForuser w-228 db_area mt7"
                                                defaultValue={this.state.areadefaultVal}
                                        ></textarea>
                                        <button className="button db_next_button mt29">SAVE</button>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </Modal>
                <NotificationContainer/>
                
            </div>
        )
    }
}