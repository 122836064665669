import React, { Component } from 'react';
import img_loader from './images/BL-PreLoader.gif';

import {Auth} from "aws-amplify";
import axios from 'axios';
var AWS = require("aws-sdk");
const jsonConfig = require("./Config.json");

export default class LandingScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultCognitoId:'',
            overlay:'none',
            changeOpacity:0
        }}
    componentDidMount()
    {
        this.setState({
            overlay:'block',
            changeOpacity:1
          })
          AWS.config.update({
            credentials: new AWS.CognitoIdentityCredentials({
              IdentityPoolId: jsonConfig.awsConfigCredentials.IdentityPoolId,
              RoleArn: jsonConfig.awsConfigCredentials.RoleArn,
            }),
            region: jsonConfig.awsConfigRegion,
          });
          this.getuserDetails();
    }
    async getuserDetails()
    {
        var user = await Auth.currentAuthenticatedUser();
        console.log(user);
        this.setState({defaultCognitoId:user.username});
        this.getTourSettings();
    }
    getTourSettings()
    {
      var profileSettingsTable = "profileSettings";
      var docClient = new AWS.DynamoDB.DocumentClient();
        var params = {
          TableName : profileSettingsTable,
          FilterExpression : "cognitoId = :cognitoid",
          ExpressionAttributeValues: {
              ":cognitoid": this.state.defaultCognitoId
          }
      };
      docClient.scan(params, (err, data)=> {
        if (err) {
            console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
            this.setState({guidedDemoVal:false})
            window.location.reload();
        } else {
          if(data.Items.length>0)
          {
            if(data.Items[0].tourSettings===true)
            {
                //window.location.href="/interaction";
                this.getPageAccess();
            }
            else{
                window.location.href="/take-a-tour";
            }
          }
          else{
                window.location.href="/take-a-tour";
          }
        }
      });
    }
    getPageAccess()
	{
        axios.get(jsonConfig.rdsApi+'users/'+this.state.defaultCognitoId,{
                headers: {
                'X-API-KEY': jsonConfig.ApiKey,
                'Content-Type': 'application/json'
                }}).then((response) => {
                if(response.data.data[0].organizationId===null || response.data.data[0].organizationId===undefined)
                {
                    this.props.history.push("/access-denied");
                }
                else{
                    if(response.data.data[0].role.name==='System' || response.data.data[0].role.name==='Admin')
                    {
                        this.props.history.push("/smart-dashboard");
                    }
                    else{
                        axios.get(jsonConfig.rdsApi+'screenPermissionByRole/'+response.data.data[0].roleId,{
                            headers: {
                            'X-API-KEY': jsonConfig.ApiKey,
                            'Content-Type': 'application/json'
                            }}).then((result) => {
                                if(result.data.data[3].status==='true')
                                {
                                    this.props.history.push("/smart-dashboard");
                                }
                                else{
                                    this.props.history.push("/access-denied");
                                }
                            },(error) => {
                                this.props.history.push("/access-denied");
                            });
                    }
                    
                }
            },(error) => {
                this.props.history.push("/access-denied");
            });
    }
    createLoader()
    {
        return(
            <div className="overlayLoader" style={{display:this.state.overlay,opacity:this.state.changeOpacity}}>
                <img src={img_loader} className="loaderClass" alt="loader"/>
            </div>
        )
    }
    
    render() {
        return (
            <div id="not_found" className="commonWidthHeight">
                {this.createLoader()}
            </div>
        )
    }
}