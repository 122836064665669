import React, { Component } from 'react';
import SideMenu from './sideMenu.js';
import Logo from './images/blLogo1.svg';
import img_elClose2 from './images/close.svg';
import img_loader from './images/BL-PreLoader.gif';

import img_elPinterest from './images/svgicons/instagram-2d.svg';
import img_elTwitter from './images/svgicons/twitter-2d.svg';
import img_elF_icon from './images/svgicons/fb-2d.svg';
import img_elYoutube from './images/svgicons/youtube-2d.svg';

import img_smiley0 from './images/em_smiley.png';
import img_smiley1 from './images/em_angry.png';
import img_smiley2 from './images/em_happy.png';
import img_smiley3 from './images/em_shocked.png';


import sd5 from './images/sd/social_sd.png';
import sd6 from './images/sd/graph_sd.png';
import notch_up from './images/buttons/notch_up.png';
import notch_down from './images/buttons/notch_down.png';


import img_info from './images/info.png';
import Modal from 'react-modal';



import img_elBlChat from './images/svgicons/blchat.svg';
import img_elSa from './images/svgicons/sabot.svg';
import img_elCsvt from './images/svgicons/esbot.svg';
import img_elFaq from './images/svgicons/faqbot.svg';

import {Auth} from "aws-amplify";
import axios from 'axios';
var AWS = require("aws-sdk");
const jsonConfig = require("./Config.json");
var wc = require('which-country');
export default class SmartDashboard extends Component {
    constructor(props) {
        super(props);
        this.state={
            openModal2:false,
            defaultOrgName:'',
            defaultOrgId:'',
            defaultUserName:'',
            defaultRoleName:'',
            defaultCognitoId:'',
            userRegistered:0,
            numberOfActiveInteractions:0,
            numberOfAllInteractions:0,
            numberOfInactiveInteractions:0,
            websiteName:'',
            boardList:[],
            numberOfNotuseInteractions:0,
            generateMonitoring:[],
            TempemojiPositive:0,
            tempEmojiNegetive:0,
            TempemojiNeutral:0,
            TempemojiMixed:0,
            TotalSentimentCount:0,
            overlay:'none',
            changeOpacity:0,
            botsActiveStatus:0,
            botsProcessStatus:0,
            setHour:''

        }
    }
    componentDidMount()
    {
        Modal.setAppElement('body');
        AWS.config.update({
            credentials: new AWS.CognitoIdentityCredentials({
                IdentityPoolId: jsonConfig.awsConfigCredentials.IdentityPoolId,
                RoleArn: jsonConfig.awsConfigCredentials.RoleArn,
            }),
            region: jsonConfig.awsConfigRegion,
            });
        this.getUserDetails();
    }
    
    createLoader()
    {
        return(
            <div className="overlayLoader" style={{display:this.state.overlay}}>
                <img src={img_loader} className="loaderClass" alt="loader"/>
            </div>
        )
    }
    sortByProperty(property){  
        return function(a,b){  
          var x=new Date(a[property]);
          var y=new Date(b[property]);
           if(x > y)  
              return -1;  
           else if(x < y)  
              return 1;  
       
           return 0;  
        }  
      }
async getUserDetails()
{
    
        var user = await Auth.currentAuthenticatedUser();
        var getUserById = await axios.get(jsonConfig.rdsApi+"users/"+user.attributes.sub,{
            headers: {
            'X-API-KEY': jsonConfig.ApiKey,
            'Content-Type': 'application/json'
            }});
        this.setState({
            defaultOrgName:getUserById.data.data[0].organization.name,
            defaultOrgId:getUserById.data.data[0].organizationId,
            defaultUserName:user.attributes.name,
            defaultRoleName:getUserById.data.data[0].role.name,
            defaultCognitoId:user.username
        })
            this.getAllusers();
            this.getActiveInteractions();
            this.getLocation();
            this.getAllInteractions();
            this.getNotuseInteractions();
            this.getInactiveInteractions();
            this.getBoardData();
            this.getSetup()
            this.setState({
                overlay:'none',
                changeOpacity:0
            })
        } catch (error) {
    
}
getSetup()
{
    var setupTable = "setup";
    var docClient = new AWS.DynamoDB.DocumentClient();
      var setUPparams = {
        TableName : setupTable,
        FilterExpression : "organizationId = :val1",
        ExpressionAttributeValues: {
            ":val1": this.state.defaultOrgId
        }
    };
    docClient.scan(setUPparams, (err, data)=> {
        if (err) {
            console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
        } else {
          if(data.Items.length>0)
          {
          //-----------------FOR AI----------------//
          var checkAiSetup=JSON.parse(data.Items[0].aiServicesJson);
          var activeFilterAi=checkAiSetup.filter(r=>r.setup==='Complete');
          var inactiveFilterAi=checkAiSetup.filter(r=>r.setup==='inComplete');
          this.setState({
            botsActiveStatus:activeFilterAi.length,
            botsProcessStatus:inactiveFilterAi.length,
          })
        }
          else{
            this.setState({
              botsActiveStatus:0,
              botsProcessStatus:0,
            })
          }
        }
    });   
}
async getAllusers()
{
    var resUsers = await axios.get(jsonConfig.rdsApi+"filterUsers?organizationId="+this.state.defaultOrgId+'&roleName=all',{
        headers: {
          'X-API-KEY': jsonConfig.ApiKey,
          'Content-Type': 'application/json'
        }});
        this.setState({
            userRegistered:resUsers.data.data.length,
        })
}
getActiveInteractions()
{
    var interactionBoardTable = "interactions";
    var docClient = new AWS.DynamoDB.DocumentClient();
      var params = {
        TableName : interactionBoardTable,
        FilterExpression : "organizationId = :val1 AND boardStatus = :status",
        ExpressionAttributeValues: {
            ":val1":this.state.defaultOrgId,
            ":status": 'Active',
        }
    };
    
    docClient.scan(params, (err, data)=> {
        if (err) {
            console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
        } else {
        if(data.Items.length>0)
          {
            data.Items.sort(this.sortByProperty("updatedAt"));
            this.setState({
                numberOfActiveInteractions:data.Items.length,
                boardList:data.Items,
            })
        }
        else{
            this.setState({
                numberOfActiveInteractions:0,
                boardList:[],
            })
        }
        }
    });
}
getAllInteractions()
{
    var interactionBoardTable = "interactions";
    var docClient = new AWS.DynamoDB.DocumentClient();
      var params = {
        TableName : interactionBoardTable,
        FilterExpression : "organizationId = :val1",
        ExpressionAttributeValues: {
            ":val1":this.state.defaultOrgId,
        }
    };
    
    docClient.scan(params, (err, data)=> {
        if (err) {
            console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
        } else {
            this.setState({numberOfAllInteractions:data.Items.length})
        }
    });
}
getInactiveInteractions()
{
    var interactionBoardTable = "interactions";
    var docClient = new AWS.DynamoDB.DocumentClient();
      var params = {
        TableName : interactionBoardTable,
        FilterExpression : "organizationId = :val1 AND boardStatus = :status",
        ExpressionAttributeValues: {
            ":val1":this.state.defaultOrgId,
            ":status": 'Inactive',
        }
    };
    
    docClient.scan(params, (err, data)=> {
        if (err) {
            console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
        } else {
            this.setState({numberOfInactiveInteractions:data.Items.length})
        }
    });
}
getNotuseInteractions()
{
    var options={day: '2-digit',year: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit'}
    var d = new Date();
    d.setDate(d.getDate() - 7);
    
    var interactionBoardTable = "interactions";
    var docClient = new AWS.DynamoDB.DocumentClient();
      var params = {
        TableName : interactionBoardTable,
        FilterExpression : "organizationId = :val1 AND updatedAt < :updt",
        ExpressionAttributeValues: {
            ":val1":this.state.defaultOrgId,
            ":updt": d.toLocaleTimeString([],options),
        }
    };
    
    docClient.scan(params, (err, data)=> {
        if (err) {
            console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
        } else {
            this.setState({
                numberOfNotuseInteractions:data.Items.length,
            })
        
        }
    });
}
async getBoardData()
  {
    var interactionBoardTable = "interactions";
    var docClient = new AWS.DynamoDB.DocumentClient();
      var params = {
        TableName : interactionBoardTable,
        FilterExpression : "organizationId = :val1",
        ExpressionAttributeValues: {
            ":val1": this.state.defaultOrgId
        }
    };
    docClient.scan(params, (err, data)=> {
        if (err) {
            console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
            
        } else {
          if(data.Items.length>0)
          {

              data.Items.sort(this.sortByProperty("updatedAt"));
              this.setState({boardList:data.Items});
              var tempLive=this.state.generateMonitoring;
              
              for(var i=0;i<data.Items.length;i++)
              {
                tempLive.push({
                    "boardName":data.Items[i].interactionName,
                    "boardId":data.Items[i].interactionId,
                    "updateDate":data.Items[i].updatedAt,
                    "interactions":JSON.parse(atob(data.Items[i].json)),
                    "analysisSentiment":"",
                    "analysisPercentage":0,
                    "analysisImage":'',
                });
                this.openInteraction(data.Items[i].interactionId,i);
                        
              }
              
              this.setState({generateMonitoring:tempLive})
              
              /**/
          }
          else{
            this.setState({
              boardList:[],
              activeInteractions:[],
              boardName:''
            })
          }
         
        }
        
    });
  }
  async openInteraction(boardId,index)
    { 
        var tempAnalysis=this.state.generateMonitoring;
        let sendBoardId={
            "interactionId": boardId,
            "duration": this.state.setHour,
        }
        var getAnalysis = await axios.post(jsonConfig.analysisApi,sendBoardId,{
            headers: {
            'X-API-KEY': jsonConfig.ApiKey,
            }
        });
        
        
        if(getAnalysis.data.statusCode===200)
            {
                
                var emojiPositive=this.state.TempemojiPositive;
                var emojiNegetive=this.state.TempemojiNegetive;
                var emojiNeutral=this.state.TempemojiNeutral;
                var emojiMixed=this.state.TempemojiMixed;
                var isExists=this.state.TotalSentimentCount;
                var tempPval=0;
                var tempSval="";
                if(getAnalysis.data.body[0]===undefined)
                {
                    this.setState({
                        TempemojiPositive:emojiPositive+0,
                        tempEmojiNegetive:emojiNegetive+0,
                        TempemojiNeutral:emojiNeutral+0,
                        TempemojiMixed:emojiMixed+0,
                        TotalSentimentCount:isExists+1
                    })
                }
                else{
                    this.setState({
                        TempemojiPositive:emojiPositive+getAnalysis.data.body[0].percentage,
                        tempEmojiNegetive:emojiNegetive+getAnalysis.data.body[1].percentage,
                        TempemojiNeutral:emojiNeutral+getAnalysis.data.body[2].percentage,
                        TempemojiMixed:emojiMixed+getAnalysis.data.body[3].percentage,
                        TotalSentimentCount:isExists+1
                    })
                }
                /*if(getAnalysis.data.body!=='TransactionLog not found')
                {
                    this.setState({
                        TempemojiPositive:emojiPositive+getAnalysis.data.body[0].percentage,
                        tempEmojiNegetive:emojiNegetive+getAnalysis.data.body[1].percentage,
                        TempemojiNeutral:emojiNeutral+getAnalysis.data.body[2].percentage,
                        TempemojiMixed:emojiMixed+getAnalysis.data.body[3].percentage,
                        TotalSentimentCount:isExists+1
                    })
                }*/
                
                for(var k=0;k<getAnalysis.data.body.length;k++)
                {
                    
                    if(getAnalysis.data.body[k].percentage>tempPval)
                    {
                        tempPval=getAnalysis.data.body[k].percentage;
                        tempSval=getAnalysis.data.body[k].sentiment;
                    }
                }
                if(tempSval==='POSITIVE')
                {
                    tempAnalysis[index]["analysisImage"]=img_smiley0;
                }
                else if(tempSval==='NEGATIVE')
                {
                    tempAnalysis[index]["analysisImage"]=img_smiley1;
                }
                else if(tempSval==='NEUTRAL')
                {
                    tempAnalysis[index]["analysisImage"]=img_smiley2;
                }
                else{
                    tempAnalysis[index]["analysisImage"]=img_smiley3;
                }
                tempAnalysis[index]["analysisSentiment"]=tempSval;
                tempAnalysis[index]["analysisPercentage"]=tempPval;
            }
            else{
                tempAnalysis[index]["analysisSentiment"]='';
                tempAnalysis[index]["analysisPercentage"]=0;
                tempAnalysis[index]["analysisImage"]="";
            }
              
        this.setState({generateMonitoring:tempAnalysis})
        
    }
getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.showPosition);
    } else {
      console.log("BROWSER NOT SUPPORTED");
    }
  }
  
  showPosition=(position)=> {
    var getCountryCode=wc([position.coords.longitude,position.coords.latitude ]);
    if(getCountryCode==='AUS')
    {
        this.setState({websiteName:'https://blueleap.com.au'})
    }
    else{
        this.setState({websiteName:'https://blueleap.com'})
    }
  }
    onClick_openVersion=()=>{
        this.setState({openModal2: true});
    }
    closeModal2=()=>{
        this.setState({openModal2: false});
    }
    onClick_elDetailTour=()=>
    {
        window.open(this.state.websiteName, "_blank");
    }
    render() {
        return (
            <div id="Smart_Feed___RobotCleaner" className="commonWidthHeight">
                <SideMenu/>
                <div className="middlePanelOthers">
                    <div id="HeaderOthers">
                        <div id="Interaction_Board" className="w-500">
                            <span className="pull-left">Smart Dashboard</span>
                            
                        </div>
                        <div className="pull-right">
                            <img src={img_info} alt="info" className="type_a" style={{height:'20px',width:'20px'}} onClick={this.onClick_openVersion}/>
                        </div>
                    </div>
                    <div id="Group_2_A1_Group_47" style={{position:'relative',marginTop:'20px',width:'1052px'}}>
                        <div className="smartdashboardAlign">
                            <div className="row_sd">
                                <div className="col-md-3-sd">
                                    <div className="sd_row_heading">Users</div>
                                    <div className="intaerctions_sd">
                                        <div style={{width:'100%'}}>
                                            <span className="txtSubheader_sd">{this.state.userRegistered}</span>
                                            <span className="txtsmall_sd ml10">Active users</span>
                                        </div>
                                        <div className="pull-left w-99">
                                            <div className="textblock_sd mt20">
                                                <img src={img_elPinterest} className="img80_sd" alt="img_elPinterest"/>
                                                <span className="text-left imgtext_sd ml10">534</span>
                                            </div>
                                            <div className="textblock_sd mt16">
                                                <img src={img_elTwitter} className="img80_sd" alt="img_elPinterest"/>
                                                <span className="text-left imgtext_sd ml10">740</span>
                                            </div>
                                        </div>
                                        <div className="pull-right w-99">
                                            <div className="textblock_sd mt20">
                                                <img src={img_elF_icon} className="img80_sd" alt="img_elPinterest"/>
                                                <span className="text-left imgtext_sd ml10">456</span>
                                            </div>
                                            <div className="textblock_sd mt16">
                                                <img src={img_elYoutube} className="img80_sd" alt="img_elPinterest"/>
                                                <span className="text-left imgtext_sd ml10">380</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3-sd ml20">
                                    <div className="sd_row_heading">Current Sentiment</div>
                                    <div className="intaerctions_sd">
                                        <div style={{overflow:'hidden'}}>
                                        <div className="pull-left w-99 mt20">
                                            <div className="textblock_sd">
                                                <img src={img_smiley0} className="imgemoji_sd" alt="img_elPinterest"/>
                                                <span className="text-left imgtext_sd ml20">{Math.round(this.state.TempemojiPositive/this.state.TotalSentimentCount)}%</span>
                                            </div>
                                            <div className="textblock_sd" style={{display:'flex',flexWrap:'wrap'}}>
                                                <img src={img_smiley1} className="imgemoji_sd mt16" alt="img_elPinterest"/>
                                                {isNaN(Math.round(this.state.TempemojiNegetive/this.state.TotalSentimentCount))===true?(
                                                <div className="text-left imgtext_sd ml20 mT24">0%</div>
                                                    ):(
                                                <div className="text-left imgtext_sd ml20 mT24">
                                                    {Math.round(this.state.TempemojiNegetive/this.state.TotalSentimentCount)}%
                                                </div>
                                                )}
                                                
                                            </div>
                                        </div>
                                        <div className="pull-right w-99 mt20">
                                            <div className="textblock_sd">
                                                <img src={img_smiley2} className="imgemoji_sd " alt="img_elPinterest"/>
                                                <span className="text-left imgtext_sd ml20">{Math.round(this.state.TempemojiNeutral/this.state.TotalSentimentCount)}%</span>
                                            </div>
                                            <div className="textblock_sd" style={{display:'flex',flexWrap:'wrap'}}>
                                                <img src={img_smiley3} className="imgemoji_sd mt18" alt="img_elPinterest"/>
                                                <div className="text-left imgtext_sd ml20 mT24">{Math.round(this.state.TempemojiMixed/this.state.TotalSentimentCount)}%</div>
                                            </div>
                                        </div>
                                        </div>
                                        <p className="txtsmall_sd mt20" style={{fontSize:'10px'}}>Average Ratings All Channels for Today</p>
                                    </div>
                                </div>
                                <div className="col-md-3-sd ml20">
                                    <div className="sd_row_heading">Interaction Status</div>
                                    <div className="intaerctions_sd">
                                        
                                        <div className="pull-left w-99">
                                            <div className="textblock_sd">
                                                <p className="text-left txtSubheader_sd">{this.state.numberOfAllInteractions}</p>
                                                <p className="txtsmall_sd text-left">Total</p>
                                            </div>
                                            <div className="textblock_sd">
                                                <p className="text-left txtSubheader_sd">{this.state.numberOfActiveInteractions}</p>
                                                <p className="txtsmall_sd text-left">Active</p>
                                            </div>
                                        </div>
                                        <div className="pull-right w-99">
                                            <div className="textblock_sd">
                                                <p className="text-left txtSubheader_sd">{this.state.numberOfInactiveInteractions}</p>
                                                <p className="txtsmall_sd text-left">Inactive</p>
                                            </div>
                                            <div className="textblock_sd">
                                                <p className="text-left txtSubheader_sd">{this.state.numberOfNotuseInteractions}</p>
                                                <p className="txtsmall_sd text-left">Not used</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3-sd ml20">
                                    <div className="sd_row_heading">Bot Status</div>
                                    <div className="intaerctions_sd">
                                        <div style={{width:'100%'}}>
                                            <span className="txtSubheader_sd">{this.state.botsActiveStatus}</span>
                                            <span className="txtsmall_sd">Bots being used</span>
                                        </div>
                                        <div style={{width:'100%'}} className="mt20">
                                            <span className="txtSubheader_sd">{this.state.botsProcessStatus}</span>
                                            <span className="txtsmall_sd">Processed</span>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            <div className="row_sd pt0">
                                <div className="col-md-6-sd">
                                    <div className="sd_row_heading">Recent Interactions</div>
                                    <table className="table customTable mb0">
                                        <thead>
                                        <tr>
                                            <th className="txtsmall_sd" style={{width:'20%'}}>Date</th>
                                            <th className="txtsmall_sd" style={{width:'40%'}}>&nbsp;</th>
                                            <th className="txtsmall_sd" style={{width:'30%'}}>Last Statement</th>
                                            <th className="txtsmall_sd" style={{width:'10%'}}>Sentiment Analysis</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.generateMonitoring.slice(0, 5).map((value, key) => (
                                        <tr key={key}>
                                            <td className="tdcolor_sd">{new Date(value.updateDate).toLocaleTimeString([],{day: '2-digit',year: 'numeric', month: 'short'}).substring(0, 12)}</td>
                                            <td className="tdcolor_sd">
                                                {value.interactions.map((imgPath, actKey) => (
                                                    <img src={imgPath.icon} alt={actKey} key={actKey} className="pull-left ml10" style={{width:'30px'}}/>
                                                ))}
                                            </td>
                                            <td className="tdcolor_sd">{value.boardName}</td>
                                            <td className="tdcolor_sd">
                                                {value.analysisSentiment!==''?(
                                                    <div style={{display:'flex'}}>
                                                        <img src={value.analysisImage} alt="img_smiley0" className="img_td"/>  
                                                        <span className="ml5">&nbsp;{Math.round(value.analysisPercentage)}%</span>
                                                    </div>
                                                ):(
                                                    <span>N/A</span>
                                                )}
                                            </td>
                                        </tr>
                                        ))}
                                        
                                        </tbody>
                                    </table>
                                    <div className="interaction_performace_holder">
                                        <div className="sd_row_heading mt20">Interaction Performance</div>
                                        <div className="noInteractionText_ribbon_live w-132" style={{marginTop:'25px'}}>
                                            <span className="comingsoon_text">COMING SOON</span>
                                        </div>
                                    </div>
                                    <div className="row IpBox ml0">
                                        <img src={sd6} alt="sd6" className="graph_img_sd"/>
                                        <div className="roundboxfroNotch_sd_top">
                                            <img src={notch_up} alt="notch_up" className="notch_sd"/>
                                        </div>
                                        <div className="roundboxfroNotch_sd_bottom">
                                            <img src={notch_down} alt="notch_down" className="notch_sd"/>
                                        </div>
                                        
                                    </div>
                                    <div className="interaction_performace_holder">
                                        <div className="sd_row_heading mt20">Content Scheduled</div>
                                        <div className="noInteractionText_ribbon_live w-132" style={{marginTop:'25px'}}>
                                            <span className="comingsoon_text">COMING SOON</span>
                                        </div>
                                    </div>
                                    <table className="table customTable">
                                    <thead>
                                        <tr>
                                            <th className="txtsmall_sd">Content</th>
                                            <th className="txtsmall_sd">Est. Reach</th>
                                            <th className="txtsmall_sd">Date</th>
                                            <th className="txtsmall_sd">Time</th>
                                            <th className="txtsmall_sd">&nbsp;</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="tdcolor_sd">Friends & Family Offer</td>
                                                <td className="tdcolor_sd">59.1M</td>
                                                <td className="tdcolor_sd">19/10</td>
                                                <td className="tdcolor_sd">8.00AM</td>
                                                <td className="tdcolor_sd">
                                                    <button className="small_button_details">Details</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="tdcolor_sd">Friends & Family Offer</td>
                                                <td className="tdcolor_sd">59.1M</td>
                                                <td className="tdcolor_sd">19/10</td>
                                                <td className="tdcolor_sd">8.00AM</td>
                                                <td className="tdcolor_sd">
                                                    <button className="small_button_details">Details</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="tdcolor_sd">Friends & Family Offer</td>
                                                <td className="tdcolor_sd">59.1M</td>
                                                <td className="tdcolor_sd">19/10</td>
                                                <td className="tdcolor_sd">8.00AM</td>
                                                <td className="tdcolor_sd">
                                                    <button className="small_button_details">Details</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-md-5-sd ml20">
                                    <div className="sd_row_heading">Bots View</div>
                                    <div className="row botsBox ml0">
                                        <div className="col-md-4-sd bots3box">
                                            <img src={img_elBlChat} className="botView_img" alt="img_elBlChat"/>
                                            <div className="imgText_bot ml10 mt45">Client Chat</div>
                                        </div>
                                        <div className="col-md-4-sd ml10">
                                            <div className="text-left txtSubheader_sd">88</div>
                                            <div className="imgText_bot">images scanned in last 1min(s)</div>
                                        </div>
                                        <div className="col-md-4-sd ml10">
                                            <div className="text-left txtSubheader_sd">97.9%</div>
                                            <div className="imgText_bot">Accuracy</div>
                                        </div>
                                        <div className="col-md-12-sd ml16">
                                            <img src={sd5} alt="sd5" className="img_bl1_sd2"/>
                                        </div>
                                    </div>
                                    
                                    <div className="row botsBox mt20 ml0">
                                        <div className="col-md-4-sd bots3box">
                                            <img src={img_elSa} className="botView_img" alt="img_elSa"/>
                                            <div className="imgText_bot ml10 mt45">Sentiment Bot</div>
                                        </div>
                                        <div className="col-md-4-sd ml10">
                                            <div className="text-left txtSubheader_sd">88</div>
                                            <div className="imgText_bot">images scanned in last 1min(s)</div>
                                        </div>
                                        <div className="col-md-4-sd ml10">
                                            <div className="text-left txtSubheader_sd">97.9%</div>
                                            <div className="imgText_bot">Accuracy</div>
                                        </div>
                                        <div className="col-md-12-sd">
                                            <img src={sd5} alt="sd5" className="img_bl1_sd2"/>
                                        </div>
                                    </div>
                                    <div className="row botsBox mt20 ml0">
                                        <div className="col-md-4-sd bots3box">
                                            <img src={img_elFaq} className="botView_img" alt="img_elFaq"/>
                                            <div className="imgText_bot ml10 mt45">Query Bot</div>
                                        </div>
                                        <div className="col-md-4-sd ml10">
                                            <div className="text-left txtSubheader_sd">88</div>
                                            <div className="imgText_bot">images scanned in last 1min(s)</div>
                                        </div>
                                        <div className="col-md-4-sd ml10">
                                            <div className="text-left txtSubheader_sd">97.9%</div>
                                            <div className="imgText_bot">Accuracy</div>
                                        </div>
                                        <div className="col-md-12-sd">
                                            <img src={sd5} alt="sd5" className="img_bl1_sd2"/>
                                        </div>
                                    </div>
                                    <div className="row botsBox mt20 ml0">
                                        <div className="col-md-4-sd bots3box">
                                            <img src={img_elCsvt} className="botView_img" alt="img_elCsvt"/>
                                            <div className="imgText_bot ml10 mt45">Dialogue Search</div>
                                        </div>
                                        <div className="col-md-4-sd ml10">
                                            <div className="text-left txtSubheader_sd">88</div>
                                            <div className="imgText_bot">images scanned in last 1min(s)</div>
                                        </div>
                                        <div className="col-md-4-sd ml10">
                                            <div className="text-left txtSubheader_sd">97.9%</div>
                                            <div className="imgText_bot">Accuracy</div>
                                        </div>
                                        <div className="col-md-12-sd">
                                            <img src={sd5} alt="sd5" className="img_bl1_sd2"/>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <Modal
                isOpen={this.state.openModal2}
                onRequestClose={this.closeModal2}
                style={{
                    overlay: {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 140, 255, 0.45)'
                    },
                    content : {
                    top                   : '50%',
                    left                  : '50%',
                    right                 : 'auto',
                    bottom                : 'auto',
                    marginRight           : '-50%',
                    transform             : 'translate(-50%, -50%)',
                    borderRadius          : '18px',
                    backgroundColor       : '#f2f7ff',
                    overflow              : 'hidden',
                    width                 : '500px'
                    }
                }}
                >
                
                <div style={{display:'flex',flexWrap:'wrap',justifyContent: 'space-between'}}>
                    <div className="mt15">
                        <div id="VersionHeader">Blueleap Dialogue</div>
                        <p id="VersionNumber">Version 3.2</p>
                    </div>
                    <div className="type_a" onClick={this.closeModal2}>
                        <img src={img_elClose2} alt="close" className="img_close2"/>
                    </div>
                </div>
                <div className="mT24">
                    <span className="labelText">Organisation Name : </span>
                    <span className="versionResult">{this.state.defaultOrgName}</span>
                </div>  
                <div className="mt12">
                    <span className="labelText">Website : </span>
                    <span className="versionResult type_a hyperlink" onClick={this.onClick_elDetailTour}>{this.state.websiteName}</span>
                </div>
                <div className="mt12">
                    <span className="labelText">Users Registered : </span>
                    <span className="versionResult">{this.state.userRegistered}</span>
                </div>
                <div className="mt12">
                    <span className="labelText">Active Interactions : </span>
                    <span className="versionResult">{this.state.numberOfActiveInteractions}</span>
                </div>
                
                <div className="col-md-12">
                    <img className="pull-right" src={Logo} alt="blueleap" style={{height:'32px'}}/>   
                </div>
                    
                
                </Modal>
                {this.createLoader()}
            </div>
        )
    }
}