import React , { Component } from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom'
import {Switch } from 'react-router';

import Interaction from './Interaction.js';
import CreateUser from './CreateUser.js';
import Sentiment from './Sentiment.js';
import Fashion from './Fashion.js';
import Trending from './Trending.js';
import CustomTracker from './CustomTracker.js';
import LiveMonitoring from './LiveMonitoring.js';
import LiveMonitoring2 from './LiveMonitoring2.js';
import AnalyzeResults from './AnalyzeResults.js';
import Content from './Content.js';
import Content2 from './Content2.js';
import Content3 from './Content3.js';
import Content4 from './Content4.js';
import Content5 from './Content5.js';
import Content6 from './Content6.js';
import Profile from './Profile.js';
import CustomTrackerLoading from './CustomTrackerLoading.js';
import PreBuiltBot from './PreBuiltBot.js';
import DeniedScreen from './DeniedScreen.js';
import GuidedDemo from './GuidedDemo.js';
import LandingScreen from './LandingScreen.js';
import notFound from './404.js';
import SmartDashboard from './SmartDashboard.js';
import AgentChatScreen from './AgentChatScreen.js';

export default class AppRoute extends Component {
  
    render() {
        return (           
            <Router>               
              <Switch>
                  <Route exact path={"/"} component={LandingScreen} />
                  <Route path="/interaction" component={Interaction}/>
                  <Route path="/results" component={AnalyzeResults}/>
                  <Route path="/sentiment" component={Sentiment}/>
                  <Route path="/fashion" component={Fashion}/>
                  <Route path="/trending" component={Trending}/>
                  <Route path="/customtracker" component={CustomTracker}/>
                  <Route path="/customloading" component={CustomTrackerLoading}/>
                  <Route path="/livemonitoring" component={LiveMonitoring}/>
                  <Route path="/livemonitoring2" component={LiveMonitoring2}/>
                  <Route path="/content" component={Content}/>
                  <Route path="/content2" component={Content2}/>
                  <Route path="/content3" component={Content3}/>
                  <Route path="/content4" component={Content4}/>
                  <Route path="/content5" component={Content5}/>
                  <Route path="/content6" component={Content6}/>
                  <Route path="/settings" component={CreateUser}/>
                  <Route path="/profile" component={Profile}/>
                  <Route path="/access-denied" component={DeniedScreen}/>
                  <Route path="/take-a-tour" component={GuidedDemo}/>
                  <Route path="/landing" component={LandingScreen}/>
                  <Route path="/pre-built-boards" component={PreBuiltBot}/>
                  <Route path="/smart-dashboard" component={SmartDashboard}/>
                  <Route path="/agent-chat" component={AgentChatScreen}/>
                  <Route component={notFound}/>
              </Switch>                
            </Router>
           
        );
      }
}