import React, { Component } from 'react';
import SideMenu from './sideMenu.js';
import Calendar from 'react-calendar';
import TimePicker from 'react-time-picker';

import img_calender from './images/buttons/calendar.svg';
import page_done from './images/buttons/page_done.svg';
import img_clock from './images/buttons/clock.svg';

import img_wa from './images/ec/whatsapp.png';
import img_sms from './images/ec/sms.png';
import img_send_contacts from './images/buttons/send_contacts.svg';

import "./css/Calendar.css";
import "./css/TimePicker.css";
import prev from './images/buttons/prev_carousel.svg';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { default as Amplify, Auth, Storage } from "aws-amplify";
import axios from 'axios';

import img_flAu from './images/Flag_of_Australia.png';
var wc = require('which-country');
var AWS = require("aws-sdk");
const jsonConfig = require("./Config.json");



export default class Content5 extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            countryFlag: img_flAu,
            countryCode:'AUS',
            campaignTitle:'',
            calenderDisplay:'none',
            timerDisplay:'none',
            date: new Date(),
            time: '10:00',
            options:{day: '2-digit',year: 'numeric', month: 'long'},
            timeOptions:{ hour: '2-digit', minute: '2-digit' },
            sendCampaignData:'',
            fileName:'',
        };
        this.showPosition = this.showPosition.bind(this);
        this.onClick_elContentNext = this.onClick_elContentNext.bind(this);
        this.onClick_elContentImmidiate = this.onClick_elContentImmidiate.bind(this);
        
      }
    componentDidMount()
    {
        var getfileName=(JSON.parse(this.props.location.state.sendData).fileUrl).split("/");
        this.getLocation();
        this.setState({
            campaignTitle:this.props.location.state.campaigntitle,
            sendCampaignData:this.props.location.state.sendData,
            fileName:getfileName[4]
        });
    }
    
    onClick_elContentPrev= (ev) =>{
        //this.props.history.push("/content4");
        this.props.history.push({pathname:"/content4",state: { campaigntitle: this.state.campaignTitle}});
    }
      getLocation() {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(this.showPosition);
        } else {
          console.log("BROWSER NOT SUPPORTED");
        }
      }
      campaignTitleHandler=event=>
      {
          this.setState({campaignTitle:event.target.value});
      }
      showPosition(position) {
        var getCountryCode=wc([position.coords.longitude,position.coords.latitude ]);
        
        var tempCountrycode=getCountryCode.substring(0,getCountryCode.length-1)
        var getFlagUrl="https://www.countryflags.io/"+tempCountrycode+"/flat/64.png"
        
            this.setState({
                countryFlag: getFlagUrl,
                countryCode:tempCountrycode
            })
      }
      openCalendar=()=>
      {
          if(this.state.calenderDisplay==='none')
          {
            this.setState({
                calenderDisplay:'block',
                timerDisplay:'none',
            })
          }
          else{
            this.setState({
                calenderDisplay:'none',
                timerDisplay:'none',
            })
          }
          
      }
      openTimer=()=>
      {
          if(this.state.timerDisplay==='none')
          {
            this.setState({
                timerDisplay:'block',
                calenderDisplay:'none'
            })
          }
          else{
            this.setState({
                timerDisplay:'none',
                calenderDisplay:'none'
            })
          }
          
      }
      calendarChange=date=>{
        this.setState({date:date})
    }
      timeChange = time => {
        this.setState({ time });
      }
      async onClick_elContentNext(){
        var getData=JSON.parse(this.state.sendCampaignData)
       
        var mergeTime=this.state.time+":00"
        var datetostring=this.state.date.toString();
        var splitstring=datetostring.split(" ");
        
        var stringJoin=splitstring[0]+" "+splitstring[1]+" "+splitstring[2]+" "+splitstring[3]+" "+mergeTime+" "+splitstring[5]+" "+splitstring[6]+" "+splitstring[7]+" "+splitstring[8];
        
        var setDateTime=new Date(stringJoin);
        

        getData["scheduledDateAndTime"]=setDateTime;
        getData["status"]="scheduled";
        getData["campaignName"]=this.state.campaignTitle;
        
        var createSchedule=await axios.post(jsonConfig.scheduleApi, getData, {headers: {
            'X-API-KEY': jsonConfig.ApiKey,
            'Content-Type': 'application/json'
          }});
        console.log(createSchedule);
        if(createSchedule.data.statusCode==200)
        {
            NotificationManager.success('Campaign successfully created','Success');
            window.setTimeout(() => {
                this.props.history.push("/content3");     
              }, 2000);
        }
        else{
            NotificationManager.error('Campaign creation failed','Error');
        }
        
    }
    async onClick_elContentImmidiate()
    {
        var getData=JSON.parse(this.state.sendCampaignData)
        getData["scheduledDateAndTime"]=this.state.date;
        getData["status"]="immediate";
        getData["campaignName"]=this.state.campaignTitle;
        
        
        var createSchedule=await axios.post(jsonConfig.scheduleApi, getData, {headers: {
            'X-API-KEY': jsonConfig.ApiKey,
            'Content-Type': 'application/json'
          }});
        
        if(createSchedule.data.statusCode==200)
        {
            NotificationManager.success('Schedule successfully created','Success');
            window.setTimeout(() => {
                this.props.history.push("/content3");     
              }, 2000);
        }
        else{
            NotificationManager.error('Schedule creation failed','Error');
        }
    }
    render() {
        return (
            <div id="Smart_Feed___Content3" className="commonWidthHeight">
                <SideMenu/>
                <div className="middlePanelOthers">
                <div id="HeaderOthers">
                    <div id="Interaction_Board">
                        <span>Digital Broadcasting</span>
                    </div>
                    <div className="pull-right flagDiv" style={{marginTop:'-15px'}}>
                        <span><img src={this.state.countryFlag} alt="au" className="mr7 flagSize"/></span>
                        <span>{this.state.countryCode}</span>
                    </div>
                </div>
                
                    <div id="Digital_broadcasting">
                        <div className="row_content_1">
                            <div className="col-md-8-content_c5">
                                <div style={{width:'100%'}}>
                                    <div className="db_c5_header_holder">
                                        <div className="db_content_header_text">Schedule Braodcast</div>
                                        <div className="db_content_header_text" style={{marginLeft:'195px'}}>Overview</div>
                                    </div>

                                    <div className="db_content_details">
                                        <div className="db_content_frm_details">
                                            <div className="db_ct">
                                                <div className="labelText db_label_text">Launch Time</div>
                                                <div className="mt12">
                                                    <button className="db_add_button type_a" onClick={this.openCalendar}>
                                                        <div style={{width:'80px'}}>
                                                            <img src={img_calender} className="db_button_add" alt="img_calender"/>
                                                            <span className="ml11">Calender</span>
                                                        </div>
                                                    </button>
                                                    <span className="text_content_5 ml25">{new Date(this.state.date).toLocaleDateString([],this.state.options)}</span>
                                                </div>
                                                <div className="mT24" style={{display:this.state.calenderDisplay}}>
                                                    <Calendar
                                                    value={this.state.date}
                                                    onChange={this.calendarChange}
                                                    />
                                                </div>
                                                <div className="mT24">
                                                    <button className="db_add_button type_a" onClick={this.openTimer}>
                                                        <div style={{width:'80px'}}>
                                                            <img src={img_clock} className="db_button_add" alt="img_calender"/>
                                                            <span className="ml11">Set Time</span>
                                                        </div>
                                                    </button>
                                                    <span className="text_content_5 ml25">{this.state.time}</span>
                                                </div>
                                                <div className="mT24" style={{display:this.state.timerDisplay}}>
                                                    <TimePicker
                                                    value={this.state.time}
                                                    onChange={this.timeChange}
                                                    className="txt_type"
                                                    />
                                                </div>
                                            </div>
                                            
                                            <div className="mt33">
                                                <div className="labelText db_label_text">Campaign Title</div>
                                                <input 
                                                    className="txtType inputForuser w-330 h-36"
                                                    type="text"
                                                    onChange={this.campaignTitleHandler}
                                                    value={this.state.campaignTitle}
                                                />
                                                    
                                                
                                            </div>
                                            
                                            
                                        </div>
                                        <div className="db_content_img_details_c5">
                                            
                                            
                                            
                                            <div className="up_content_4_label_holder">
                                                <div className="left_img_holder_content_4">
                                                    <img src={img_sms} className="db_content_2_text_icon" alt="img_wa"/>
                                                    <span className="db_content_2_text_icon_name">SMS</span>
                                                </div>
                                                <div className="send_contact">
                                                    <img src={img_send_contacts} alt="img_send_contacts"/>
                                                    <span className="send_contact_text_c4">Sending to all contacts</span>
                                                    <div className="xls_name_c5 ml24">{this.state.fileName}</div>
                                                </div>
                                            </div>
                                            <div className="dividerfromuploader_content2_c5"></div>
                                            <div className="endList_c5 mt27">End of the List</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4-content">
                                <div className="db_all_step">
                                    <div className="db_serial_step">
                                        <div className="db_step_divider">
                                        <div className="db_step_done_box"><img src={page_done} alt="page_done"/></div>
                                        <div className="db_step_subtext_disable">Select Channels<br/>& Audience</div>
                                        </div>
                                    </div>
                                    <div className="db_step_line_ct_2"></div>
                                    <div className="db_serial_step" style={{marginTop:'48px'}}>
                                        <div className="db_step_divider">
                                        <div className="db_step_done_box"><img src={page_done} alt="page_done"/></div>
                                        <div className="db_step_subtext_disable">Create<br/> Content</div>
                                        </div>
                                    </div>
                                    <div className="db_step_line_1_ct_4"></div>
                                    <div className="db_serial_step_ct_2" style={{marginTop:'50px'}}>
                                        <div className="db_step_divider">
                                            <div className="db_step_done_box"><img src={page_done} alt="page_done"/></div>
                                            <div className="db_step_subtext_disable">Preview<br/>Content</div>
                                        </div>
                                    </div>
                                    <div className="db_step_line_2_ct_5"></div>
                                    <div className="db_serial_step" style={{marginTop:'49px'}}>
                                        <div className="db_step_divider">
                                            <div className="db_step_box">4</div>
                                            <div className="db_active_step">
                                                <div className="db_step_header_ct_2">Schedule <br/>Broadcast</div>
                                                <div className="db_step_subtext_ct_2">Send it at a later point<br/>of time?</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="post_now_button_holder">
                                    
                                </div>
                                <div className="mt190">
                                    <div className="text-right mr22"><button style={{paddingTop:'8px'}} className="button db_postnow_button" onClick={this.onClick_elContentImmidiate}>POST NOW</button></div>
                                    <div className="next_prev_button_holder mt18">
                                        <img src={prev} className="type_a"  alt="prev" onClick={this.onClick_elContentPrev}/>
                                        <button className="button ml35 db_next_button" style={{paddingTop:'8px'}} onClick={this.onClick_elContentNext}>SCHEDULE</button>
                                    </div>
                                </div>
                            </div>
                        </div>   
                    </div>

                </div>
                <NotificationContainer/>
            </div>
        )
    }
}