import React, { Component } from 'react';
import SideMenu from './sideMenu.js';

import fb from './images/ec/F_icon.png';
import wa from './images/ec/whatsapp.png';
import sms from './images/ec/sms.png';
import twitter from './images/ec/Twitter_Bird.png';
import insta from './images/ec/instagram.png';

import angry from './images/em_angry.png';
import happy from './images/em_happy.png';
import shocked from './images/em_shocked.png';
import img_elClose from './images/arrow_prev.png';
import img_elLoading from './images/loading.png';
import au from './images/au.png';
import sort from './images/sort1.png';

import Modal from 'react-modal';
export default class CustomTrackerLoading extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
          modalIsOpen: false,
        };
        this.onClick_elAddNew = this.onClick_elAddNew.bind(this);
        this.closeModal = this.closeModal.bind(this);
      }
      componentDidMount() 
      {
        Modal.setAppElement('body');
      };
      closeModal() {
        this.setState({modalIsOpen: false});
      }
    
      
      onClick_elAddNew()
      {
        this.setState({modalIsOpen: true});
      }
    
    
    render() {
        return (
            <div id="Smart_Feed___CustomtrackerLoading" className="commonWidthHeight">
                <SideMenu/>
                <div className="middlePanelOthers">
                <div id="Header">
                    <div id="Interaction_Board">
                        <span>Custom Tracker</span>
                        <span id="today">TODAY <i className="fa fa-caret-right caret-right"></i></span>
                    </div>
                    <div className="pull-right">
                        <img src={sort} alt="sort"/>
                        <img src={au} alt="au"/>
                    </div>
                </div>
                
                <div id="Group_2_A1_Group_56">
                    
                        <div className="col-md-12" style={{paddingTop:'20px'}}>
                            <div className="row">
                                <div className="col-md-2">&nbsp;</div>
                                <div className="col-md-2">
                                    <img src={fb} className="img_top_img" alt="15"/>
                                    <div className="lblHeaderTxt">Facebook #23</div>
                                </div>
                                <div className="col-md-2">
                                    <img src={insta} className="img_top_img" alt="14"/>
                                    <div className="lblHeaderTxt">Instagram #53</div>
                                </div>
                                <div className="col-md-2">
                                    <img src={wa} className="img_top_img" alt="13"/>
                                    <div className="lblHeaderTxt">Whatsapp #12</div>
                                </div>
                                <div className="col-md-2">
                                    <img src={twitter} className="img_top_img" alt="12"/>
                                    <div className="lblHeaderTxt">Twitter #11</div>
                                </div>
                                <div className="col-md-2">
                                    <img src={sms} className="img_top_img" alt="11"/>
                                    <div className="lblHeaderTxt">SMS #21</div>
                                </div>
                            </div>
                    </div>
                    <div className="col-md-12 margin-align_30">
                            <div className="row">
                                <div className="col-md-2">
                                    <p className="lblHeaderTxt_left">Returns</p>
                                    <p className="labelText_left">A descriptor that elaborates on Returns</p>
                                </div>
                                <div className="col-md-2">
                                    <div className="switchSquare_2">
                                        <img src={angry} className="img_emoji" alt="10"/>
                                        <p className="labelTextEmoji"><span className="percent_emoji">58%</span>&nbsp;&nbsp; 280 posts</p>
                                    </div>
                                </div>
                                
                                <div className="col-md-2">
                                    <div className="switchSquare_2">
                                        <img src={angry} className="img_emoji" alt="9"/>
                                        <p className="labelTextEmoji"><span className="percent_emoji">91%</span>&nbsp;&nbsp; 123 posts</p> 
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="switchSquare_2">
                                        <img src={angry} className="img_emoji" alt="8"/>
                                        <p className="labelTextEmoji"><span className="percent_emoji">78%</span>&nbsp;&nbsp; 165 posts</p> 
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="switchSquare_2">
                                        <img src={happy} className="img_emoji" alt="7"/>
                                        <p className="labelTextEmoji"><span className="percent_emoji">76%</span>&nbsp;&nbsp; 345 posts</p>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="switchSquare_2">
                                        <img src={happy} className="img_emoji" alt="6"/>
                                        <p className="labelTextEmoji"><span className="percent_emoji">52%</span>&nbsp;&nbsp; 250 posts</p> 
                                    </div>
                                </div>
                            </div>
                    </div>
                    <div className="col-md-12 margin-align_30">
                            <div className="row">
                                <div className="col-md-2">
                                    <p className="lblHeaderTxt_left">Exchanges</p>
                                    <p className="labelText_left">A descriptor that elaborates on Exchanges</p>
                                </div>
                                <div className="col-md-2">
                                    <div className="switchSquare_2">
                                        <img src={shocked} className="img_emoji" alt="5"/>
                                        <p className="labelTextEmoji"><span className="percent_emoji">32%</span>&nbsp;&nbsp; 180 posts</p>
                                    </div>
                                </div>
                                
                                <div className="col-md-2">
                                    <div className="switchSquare_2">
                                        <img src={happy} className="img_emoji" alt="4"/>
                                        <p className="labelTextEmoji"><span className="percent_emoji">58%</span>&nbsp;&nbsp; 423 posts</p> 
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="switchSquare_2">
                                        <img src={shocked} className="img_emoji" alt="3"/>
                                        <p className="labelTextEmoji"><span className="percent_emoji">55%</span>&nbsp;&nbsp; 234 posts</p> 
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="switchSquare_2">
                                        <img src={shocked} className="img_emoji" alt="2"/>
                                        <p className="labelTextEmoji"><span className="percent_emoji">67%</span>&nbsp;&nbsp; 234 posts</p>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="switchSquare_2">
                                        <img src={angry} className="img_emoji" alt="1"/>
                                        <p className="labelTextEmoji"><span className="percent_emoji">87%</span>&nbsp;&nbsp; 634 posts</p> 
                                    </div>
                                </div>
                            </div>
                    </div>
                    <div className="col-md-12 margin-align_30">
                            <div className="row">
                                <div className="col-md-2">
                                    <p className="lblHeaderTxt_left">New Key</p>
                                    <p className="labelText_left">A descriptor that elaborates</p>
                                </div>
                                <div className="col-md-2">
                                    <div className="switchSquare_2">
                                        <img src={img_elLoading} alt="15"/>
                                    </div>
                                </div>
                                
                                <div className="col-md-2">
                                    <div className="switchSquare_2">
                                        <img src={img_elLoading} alt="14"/>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="switchSquare_2">
                                        <img src={img_elLoading} alt="13"/>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="switchSquare_2">
                                        <img src={img_elLoading} alt="12"/>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="switchSquare_2">
                                        <img src={img_elLoading} alt="11"/>
                                    </div>
                                </div>
                            </div>
                    </div>
                    <div className="col-md-12 margin-align_30 text-center">
                        <button className="button" onClick={this.onClick_elAddNew}><i className="fa fa-plus-circle" aria-hidden="true"></i>  Add New</button>
                    </div>
                </div>
                </div>
                <Modal
                isOpen={this.state.modalIsOpen}
                onRequestClose={this.closeModal}
                style={{
                    overlay: {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 140, 255, 0.45)'
                    },
                    content : {
                    top                   : '50%',
                    left                  : '50%',
                    right                 : 'auto',
                    bottom                : 'auto',
                    marginRight           : '-50%',
                    transform             : 'translate(-50%, -50%)',
                    borderRadius          : '18px',
                    backgroundColor       : '#f2f7ff',
                    overflow              : 'hidden'
                    }
                }}
                >
                <div className="type_a" onClick={this.closeModal}>
                    <img src={img_elClose} alt="close" className="img_close"/>
                </div>
                <div className="col-md-12">
                    <div className="divHeaderTxt">
                        <p id="SentimentSubHeaderTxt">Custom Tracker</p>
                        <div id="SentimentHeaderTxt">Add New Tracker</div>
                    </div>
                </div>  
                <div className="col-md-12 margin-align_30">
                    <input type="text" className="txtType" placeholder="Keyword"/>
                </div>
                <div className="col-md-12 margin-align">
                    <input type="text" className="txtType" placeholder="Description"/>
                </div>
                <div className="col-md-12 margin-align">
                    <button className="button" onClick={this.onClick_elLoading}>Continue</button>
                </div>
                    
                
                </Modal>
            </div>
            
        )
    }
}