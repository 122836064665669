import React, { Component } from 'react';
import SideMenu from './sideMenu.js';

import sort from './images/sort1.png';
import img_Refresh from './images/buttons/refresh.svg';
import img_collapseall from './images/buttons/collapseall.svg';
import img_expandall from './images/buttons/expandall.svg';
import img_flAu from './images/Flag_of_Australia.png';
import img_loader from './images/BL-PreLoader.gif';
import {Auth} from "aws-amplify";
import axios from 'axios';
var AWS = require("aws-sdk");
const jsonConfig = require("./Config.json");

var wc = require('which-country');

export default class LiveMonitoring extends Component {
    constructor(props) {
        super(props);
        this.state = {
        overlay:'none',
        changeOpacity:0,
        countryFlag: img_flAu,
        countryCode:'AUS',

        defaultOrgName:'',
        defaultOrgId:'',
        defaultUserName:'',
        defaultRoleName:'',
        defaultCognitoId:'',
        boardId:'',
        boardList:[],
        activeInteractions:[],
        sentimentData:[],
        generateMonitoring:[],
        setHour:'',
        img_smiley0 : './images/em_smiley.png',
        img_smiley1 : './images/em_angry.png',
        img_smiley2 : './images/em_happy.png',
        img_smiley3 : './images/em_shocked.png',
        boardFIlterHeader:'Active',
        todayDate: new Date(),
        todayOptions:{day: '2-digit',year: 'numeric', month: 'long'}

        };
        this.showPosition = this.showPosition.bind(this);
        this.expandAll = this.expandAll.bind(this);
        this.collapseAll = this.collapseAll.bind(this);
        
      }
componentDidMount()
{
    this.getLocation();

    AWS.config.update({
        credentials: new AWS.CognitoIdentityCredentials({
            IdentityPoolId: jsonConfig.awsConfigCredentials.IdentityPoolId,
            RoleArn: jsonConfig.awsConfigCredentials.RoleArn,
        }),
        region: jsonConfig.awsConfigRegion,
        });
    this.getUserDetails();
}
createLoader()
    {
        return(
        <div className="overlayLoader" style={{display:this.state.overlay,opacity:this.state.changeOpacity}}>
          <img src={img_loader} className="loaderClass" alt="loader"/>
        </div>
        )
    }
async getUserDetails()
{
        try {
            var user = await Auth.currentAuthenticatedUser();
            var getUserById = await axios.get(jsonConfig.rdsApi+"users/"+user.attributes.sub,{
              headers: {
                'X-API-KEY': jsonConfig.ApiKey,
                'Content-Type': 'application/json'
              }});
            this.setState({
              defaultOrgName:getUserById.data.data[0].organization.name,
              defaultOrgId:getUserById.data.data[0].organizationId,
              defaultUserName:user.attributes.name,
              defaultRoleName:getUserById.data.data[0].role.name,
              defaultCognitoId:user.username
            })
            this.getBoardData(getUserById.data.data[0].organizationId);
          } catch (error) {
          }
}
sortByProperty(property)
{  
    return function(a,b){  
        var x=new Date(a[property]);
        var y=new Date(b[property]);
        if(x > y)  
            return -1;  
        else if(x < y)  
            return 1;  
    
        return 0;  
    }  
}
async getBoardData(orgIdParam)
  {
    this.setState({
        overlay:'block',
        changeOpacity:1
      })
    var interactionBoardTable = "interactions";
    var docClient = new AWS.DynamoDB.DocumentClient();
      var params = {
        TableName : interactionBoardTable,
        FilterExpression : "organizationId = :val1 AND boardStatus = :status",
        ExpressionAttributeValues: {
            ":val1": orgIdParam,
            ":status":'Active'
        }
    };
    docClient.scan(params, (err, data)=> {
        if (err) {
            console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
            
        } else {
          if(data.Items.length>0)
          {
              data.Items.sort(this.sortByProperty("updatedAt"));
              this.setState({boardList:data.Items});
              var tempLive=this.state.generateMonitoring;
              
              for(var i=0;i<data.Items.length;i++)
              {
                tempLive.push({
                    "boardName":data.Items[i].interactionName,
                    "boardId":data.Items[i].interactionId,
                    "interactions":JSON.parse(atob(data.Items[i].json)),
                    "analysis":[],
                    "performanceIndicators":[]
                });
                this.openInteraction(data.Items[i].interactionId,i);
                        
              }
              
              this.setState({generateMonitoring:tempLive})
              /**/
          }
          else{
            this.setState({
              boardList:[],
              activeInteractions:[],
              boardName:''
            })
          }
        }
        this.setState({
            overlay:'none',
            changeOpacity:0
          })
    });
  }
  async openInteraction(boardId,index)
    {
        var tempAnalysis=this.state.generateMonitoring;
        
        let sendBoardId={
            "interactionId": boardId,
            "duration": this.state.setHour,
        }
        var getAnalysis = await axios.post(jsonConfig.analysisApi,sendBoardId,{
            headers: {
            'X-API-KEY': jsonConfig.ApiKey,
            }
        });

        /*var getAnalysis = await axios.post(jsonConfig.liveMonitoringAnalysis,sendBoardId,{
            headers: {
            'X-API-KEY': jsonConfig.ApiKey,
            }
        });*/
        
           
        /**/if(getAnalysis.data.statusCode===200)
            {
                tempAnalysis[index]["analysis"]=getAnalysis.data.body.sentiment;
                tempAnalysis[index]["performanceIndicators"]=getAnalysis.data.body.performanceIndicators;
            }
            else{
                tempAnalysis[index]["analysis"]=[];
                tempAnalysis[index]["performanceIndicators"]=[];
            }
            
            this.setState({generateMonitoring:tempAnalysis})
    }
    getLocation() {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(this.showPosition);
        } else {
          console.log("BROWSER NOT SUPPORTED");
        }
      }
      
      showPosition(position) {
        var getCountryCode=wc([position.coords.longitude,position.coords.latitude ]);
        //var getCountryCode=wc([151,-33]);
        var tempCountrycode=getCountryCode.substring(0,getCountryCode.length-1)
        var getFlagUrl="https://www.countryflags.io/"+tempCountrycode+"/flat/64.png"
        
            this.setState({
                countryFlag: getFlagUrl,
                countryCode:tempCountrycode
            })
      }
    expandAll()
    {
        for(var i=0;i<this.state.boardList.length;i++)
        {
            document.getElementById("chk_"+i).checked=true;
        }
    }
    collapseAll()
    {
        for(var j=0;j<this.state.boardList.length;j++)
        {
            document.getElementById("chk_"+j).checked=false;
        }
    }
    onClick_elDrillDown= (ev) =>{
        this.props.history.push("/livemonitoring2");
    }
    onClick_elRefresh=()=>{
        this.getBoardData(this.state.defaultOrgId);
    }
    sentHourHandler=event=>
    {
        this.setState({setHour:event.target.value});
        console.log("GET HOUR",this.state.setHour);
        this.getBoardData(this.state.defaultOrgId);
    }
    filterMonitoring=(event)=>{
        
          var interactionBoardTable = "interactions";
          var params='';
          if(event.target.value==1)
          {
              this.setState({boardFIlterHeader:'Active'});
            params = {
                TableName : interactionBoardTable,
                FilterExpression : "organizationId = :val1 AND boardStatus = :status",
                ExpressionAttributeValues: {
                    ":val1": this.state.defaultOrgId,
                    ":status": 'Active'
                }
            };
          }
          else if(event.target.value==2)
          {
            this.setState({boardFIlterHeader:'Inactive'});
            params = {
                TableName : interactionBoardTable,
                FilterExpression : "organizationId = :val1 AND boardStatus = :status",
                ExpressionAttributeValues: {
                    ":val1": this.state.defaultOrgId,
                    ":status": 'Inactive'
                }
            };
          }
          else{
            this.setState({boardFIlterHeader:'All'});
            params = {
                TableName : interactionBoardTable,
                FilterExpression : "organizationName = :orgName",
                ExpressionAttributeValues: {
                    ":orgName": this.state.defaultOrgId
                }
            };
          }
          this.getFilteredBoardData(params)
    }
async getFilteredBoardData(wholeParam)
  {
    this.setState({
        overlay:'block',
        changeOpacity:1
      })
        var docClient = new AWS.DynamoDB.DocumentClient();
        
        docClient.scan(wholeParam, (err, data)=> {
        if (err) {
            console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
            
        } else {
            
          if(data.Items.length>0)
          {
              data.Items.sort(this.sortByProperty("updatedAt"));
              this.setState({boardList:data.Items});
              var tempLive=[];
              for(var i=0;i<data.Items.length;i++)
              {
                tempLive.push({
                    "boardName":data.Items[i].interactionName,
                    "boardId":data.Items[i].interactionId,
                    "interactions":JSON.parse(atob(data.Items[i].json)),
                    "analysis":[],
                    "performanceIndicators":[]
                });         
              }
              this.setState({generateMonitoring:tempLive})
              for(var k=0;k<data.Items.length;k++)
              {
                this.openInteraction(data.Items[k].interactionId,k);                       
              }
          }
          else{
           
            this.setState({
              boardList:[],
              activeInteractions:[],
              boardName:'',
              generateMonitoring:[]
            })
          }
        }
        this.setState({
            overlay:'none',
            changeOpacity:0
          })
    });
  }
  AnalysisPerClick=event=>
  {
      console.log(event.target.checked);
      if(event.target.checked===true)
      {

      }
  }
    render() {
        
        return (
            <div id="Live_monitoring" className="commonWidthHeight">
                <SideMenu/>
                <div className="middlePanelOthers">
                <div id="HeaderOthers">
                    <div id="Interaction_Board">
                        <span className="pull-left">Live Monitoring</span>
                        <span className="ml20"><img src={img_Refresh} data-tip="Refresh" alt="refresh" className="type_a" onClick={this.onClick_elRefresh}/></span>
                    </div>
                    <div className="pull-right">
                        <select className="txtType inputForuserLive w-139 arrowAlignForw139 displayInline" onChange={this.sentHourHandler}>
                            <option value="" className="defaultOpt">All Time</option>
                            <option value="1" className="defaultOpt">1 Hour</option>
                            <option value="2" className="defaultOpt">2 Hours</option>
                            <option value="4" className="defaultOpt">4 Hours</option>
                            <option value="8" className="defaultOpt">8 Hours</option>
                            <option value="24" className="defaultOpt">1 Day</option>
                            <option value="48" className="defaultOpt">2 Days</option>
                            <option value="168" className="defaultOpt">7 Days</option>
                            <option value="720" className="defaultOpt">30 Days</option>
                            <option value="8760" className="defaultOpt">1 Year</option>
                        </select>
                        <img src={img_collapseall} alt="collapse" className="type_a ml20" onClick={(e) => this.collapseAll(e)}/>
                        <img src={img_expandall} alt="expand" className="type_a" style={{marginLeft:'13px'}} onClick={(e) => this.expandAll(e)}/>

                        <select className="txtType inputForuserLive w-139 arrowAlignForw139 displayInline ml20" onChange={this.filterMonitoring}>
                            <option value="1" className="defaultOpt">Active</option>
                            <option value="2" className="defaultOpt">Inactive</option>
                        </select>
                        <img src={sort} alt="sort" className="ml13"/>
                        <div className="flagDiv">
                            <span><img src={this.state.countryFlag} alt="au" className="mr7 flagSize"/></span>
                            <span>{this.state.countryCode}</span>
                        </div>
                        
                    </div>
                </div>
                
                    <div id="Group_2_A1_Group_47" style={{padding:'24px 40px 0px 40px',marginTop:'29px'}}>
                        <div style={{overflow:'hidden'}}>
                            <div className="pull-left" style={{marginTop:'6px'}}>
                                <span className="labelSubText lh16">{this.state.boardFIlterHeader} Interaction Boards</span>
                                
                            </div>
                            
                        </div>
                        <div className="autoScrollforLive">
                            <div className="tabsForLive">
                                {this.state.generateMonitoring.map((value, key) => (
                                <div className="tabforLive mb10" key={key}>
                                <input className="checkbox-opacity" type="checkbox" id={"chk_"+key} onChange={this.AnalysisPerClick}/>
                                <label className="tab-label-live" htmlFor={"chk_"+key} style={{marginBottom:'0px'}}>{value.boardName}</label>
                                    <div className="tab-content-live">
                                        <div className="tabboxForLive">
                                            
                                            <div className="intBoxForLive">
                                                <ul className="ulforLiveMonitoring">
                                                    {value.interactions.map((actVal, actIndex) => (
                                                        value.interactions.length===1?(
                                                            <li key={actIndex}>
                                                            <div className="mb10"><img src={actVal.icon} style={{width:'60px'}} alt="wa"/></div>
                                                            <div>
                                                                <div className="arrowforLive" style={{overflow:'hidden'}}>
                                                                    <div className="lineforLiveTransparent"></div>
                                                                    <div className="circleforLive"></div>
                                                                    <div className="lineforLiveTransparent"></div>
                                                                </div>
                                                                <div className="textForLive mt8">{actVal.displayName}</div>
                                                            </div>
                                                        </li>
                                                        ):(
                                                        value.interactions.length===actIndex+1?(
                                                        <li key={actIndex}>
                                                            <div className="mb10"><img src={actVal.icon} style={{width:'60px'}} alt="wa"/></div>
                                                            <div>
                                                                <div className="arrowforLive" style={{overflow:'hidden'}}>
                                                                    <div className="lineforLive"></div>
                                                                    <div className="circleforLive"></div>
                                                                    <div className="lineforLiveTransparent"></div>
                                                                </div>
                                                                <div className="textForLive mt8">{actVal.displayName}</div>
                                                            </div>
                                                        </li>
                                                        ):(
                                                            actIndex===0?(
                                                            <li key={actIndex}>
                                                                <div className="mb10"><img src={actVal.icon} style={{width:'60px'}} alt="wa"/></div>
                                                                <div>
                                                                    <div className="arrowforLive" style={{overflow:'hidden'}}>
                                                                        <div className="lineforLiveTransparent"></div>
                                                                        <div className="circleforLive"></div>
                                                                        <div className="lineforLive"></div>
                                                                        <div className="pointforLive"></div>
                                                                    </div>
                                                                    <div className="textForLive mt8">{actVal.displayName}</div>
                                                                </div>
                                                            </li>
                                                            ):(
                                                            <li key={actIndex}>
                                                                <div className="mb10"><img src={actVal.icon} style={{width:'60px'}} alt="wa"/></div>
                                                                <div>
                                                                    <div className="arrowforLive" style={{overflow:'hidden'}}>
                                                                        <div className="lineforLive"></div>
                                                                        <div className="circleforLive"></div>
                                                                        <div className="lineforLive"></div>
                                                                        <div className="pointforLive"></div>
                                                                    </div>
                                                                    <div className="textForLive mt8">{actVal.displayName}</div>
                                                                </div>
                                                            </li>
                                                        ))
                                                    )))}
                                                </ul>
                                            </div>
                                            
                                        <div className="kpiBoxForLive">
                                                
                                            <div className="mainrowForParam pull-left">
                                                <div className="analysisHeader">Sentiment Analysis</div>
                                                <div className="analysisResultbox">
                                                    {value.analysis.length>0?(
                                                        value.analysis.map((sentVal, sentIndex) => (
                                                            <div className="analysisForLive" key={sentIndex}>
                                                            
                                                                <span>
                                                                    {sentVal.sentiment==='POSITIVE'?(
                                                                        <img className="sentimentImg" src={require('./images/em_smiley.png')} alt="ang1"/>
                                                                    ):(
                                                                        sentVal.sentiment==='NEGATIVE'?(
                                                                            <img className="sentimentImg" src={require('./images/em_angry.png')} alt="ang2"/>
                                                                        ):(
                                                                            sentVal.sentiment==='NEUTRAL'?(
                                                                                <img className="sentimentImg" src={require('./images/em_happy.png')} alt="ang3"/>
                                                                            ):(
                                                                                <img className="sentimentImg" src={require('./images/em_shocked.png')} alt="ang4"/>
                                                                            )
                                                                        )
                                                                    )}
                                                                    
                                                                </span>
                                                                <span className={`colorSentiment${sentIndex} `+"livePtagForLive"}>{Math.round(sentVal.percentage)}<span className="fs24">%</span></span>
                                                                <p className={`colorSentiment${sentIndex} `+"fs12 sentimentText"}>{sentVal.sentiment}</p>
                                                            </div>
                                                            
                                                        ))
                                                        ):(
                                                            <p className="livePtagForLive">N/A</p>
                                                        )}
                                                </div>
                                            </div>
                                            
                                            <div className="mainrowForParam pull-right">
                                                <div className="analysisHeader">Performance Indicators</div>
                                                <div className="analysisResultbox" >
                                                {value.performanceIndicators.length>0?(
                                                    value.performanceIndicators.map((perfomaenceVal, perfomaenceIndex) => (
                                                        
                                                            <div className="analysisForLive" key={perfomaenceIndex}>
                                                                <p className="livePtagForLive colorResolved">{perfomaenceVal.total}</p>
                                                                <p className="fs12 w-101 colorResolved">{perfomaenceVal.name}</p>
                                                            </div>
                                                        
                                                ))
                                                        
                                                ):(
                                                    <p className="livePtagForLive">N/A</p>
                                                )}
                                                </div>
                                            </div>
                                            

                                        </div> 
                                            
                                    </div>
                                </div>
                            </div>
                        ))}
                            
                            </div>
                        </div>
                    </div>
                </div>
                {this.createLoader()}
            </div>
        )
    }
}