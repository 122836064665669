import React, { Component } from 'react';
import SideMenu from './sideMenu.js';

import sort from './images/sort1.png';
import img_mySearch from './images/screen/search.svg';
import exit from './images/screen/exit.svg';
import graph from './images/screen/graph.svg';
import sm from './images/screen/sm.png';

export default class CustomTracker extends Component {

    
    
    render() {
        return (
            <div id="Smart_Feed___Customtracker" className="commonWidthHeight">
                <SideMenu/>
                <div className="middlePanelOthers">
                <div id="HeaderOthers">
                    <div id="Interaction_Board" className="w-500">
                        <span className="pull-left">Social Monitor </span>
                        <div className="margin-align noInteractionText_ribbon_live ml20 pull-left w-132">
                            <span className="comingsoon_text">COMING SOON</span>
                        </div>
                    </div>
                    <div className="pull-right">
                        <img src={img_mySearch} alt="search"/>
                        <img src={graph} alt="graph" className="ml13"/>
                        <img src={exit} alt="exit" className="ml13"/>
                        <img src={sort} alt="sort" className="ml13"/>
                        <button className="button ml13" style={{width:'130px'}}>ADD PROFILE</button>
                    </div>
                </div>
                
                <div id="Group_2_A1_Group_49" style={{marginTop:'32px',borderRadius: '6px'}}>
                    <div>
                        <img src={sm} alt="sm" style={{width:'1040px'}}/>
                    </div>
                </div>
            </div>
                
            </div>
            
        )
    }
}