import React, { Component,useEffect } from 'react';
import SideMenu from './sideMenu.js';

import upldImg from './images/uploadimage.png';
import video from './images/video.png';
import img_add from './images/buttons/add_field.png';
import page_done from './images/buttons/page_done.svg';

import db_img_1 from './images/no-image.jpg';
import db_vdo from './images/no-video.png';
import prev from './images/buttons/prev_carousel.svg';
import { default as Amplify, Storage} from "aws-amplify";
import img_flAu from './images/Flag_of_Australia.png';
var wc = require('which-country');
const jsonConfig = require("./Config.json");

export default class Content2 extends Component {
    constructor(props) {
        super(props);
       
        this.state = {
            countryFlag: img_flAu,
            countryCode:'AUS',
            csvSmsHeader:[],
            csvTags:'',
            csvTagsForContent:'',
            campaignTitle:'',
            getCampaignData:'',
            firstImg:db_img_1,
            secondImg:db_img_1,
            firstImageStat:false,
            videoFile:'',
            videoFileStatus:false,
        };
        this.showPosition = this.showPosition.bind(this);
        
      }
    componentDidMount()
    {
        if(this.props.location.state.csvHeader===undefined)
        {
            this.setState({csvSmsHeader:[]})
        }
        else{
            this.setState({csvSmsHeader:this.props.location.state.csvHeader})
        }
        this.setState({
            getCampaignData:this.props.location.state.sendData,
            campaignTitle:this.props.location.state.campaigntitle,
            csvTagsForContent:JSON.parse(this.props.location.state.sendData)['content'],
        })
        this.getLocation();
        console.log(this.props.location.state);
    }
    onClick_elContentPrev= (ev) =>{
        //this.props.history.push("/content3");
        this.props.history.push({pathname:"/content3",state: { csvSmsHeader: this.state.csvSmsHeader,sendData:this.state.getCampaignData}});
    }
    onClick_elContentNext= (ev) =>{
		this.props.history.push({pathname:"/content4",state: { campaigntitle: this.state.campaignTitle,sendData:this.state.getCampaignData,csvSmsHeader:this.state.csvSmsHeader,FirstImage:this.state.firstImg,SecondImage:this.state.secondImg,VideoFile:this.state.videoFile}});
    }
      getLocation() {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(this.showPosition);
        } else {
          console.log("BROWSER NOT SUPPORTED");
        }
      }
      
      showPosition(position) {
        var getCountryCode=wc([position.coords.longitude,position.coords.latitude ]);
        //var getCountryCode=wc([151,-33]);
        console.log("COUNTRY CODE",getCountryCode);
        var tempCountrycode=getCountryCode.substring(0,getCountryCode.length-1)
        var getFlagUrl="https://www.countryflags.io/"+tempCountrycode+"/flat/64.png"
        
            this.setState({
                countryFlag: getFlagUrl,
                countryCode:tempCountrycode
            })
      }
      inputTagschange=event=>{
          var tempTags="["+event.target.value.trim()+"]";
          this.setState({csvTags:tempTags})
      }
      insertTagsToContent=()=>
      {
          var getTags=this.state.csvTags;
          if(this.state.csvTagsForContent==='')
          {
            var getParseData=JSON.parse(this.state.getCampaignData);
            getParseData["content"]=getTags;
            this.setState({
                csvTagsForContent:getTags,
                getCampaignData:JSON.stringify(getParseData)
            });
          }
          else{
              var prevTags=this.state.csvTagsForContent
              var mergeTags=prevTags+getTags;
              var getParseData=JSON.parse(this.state.getCampaignData);
              getParseData["content"]=mergeTags;
              this.setState({
                  csvTagsForContent:mergeTags,
                  getCampaignData:JSON.stringify(getParseData)
                });
          }
          
        
      }
      contentHandler=event=>{
        this.setState({csvTagsForContent:event.target.value})
        var getParseData=JSON.parse(this.state.getCampaignData)
        getParseData["content"]=event.target.value;
        this.setState({getCampaignData:JSON.stringify(getParseData)})
      }
      titleHandler=event=>
      {
        this.setState({campaignTitle:event.target.value})
      }

      async uploadfirstImage(event) {
        Amplify.configure({
          Storage: {
              AWSS3: {
                  bucket: jsonConfig.campaignFile, //REQUIRED -  Amazon S3 bucket
              }
          }
        });
        
        const file = event.target.files[0];
        
        let uploadResult = await Storage.put(file.name, file, {contentType: file.type});
        let getImgS3=await Storage.get(uploadResult.key);
        var picSplit=getImgS3.split("?");
        
        this.setState({
          firstImg:picSplit[0],
          firstImageStat:true
        });
      }
      async uploadSecondImage(event) {
        Amplify.configure({
          Storage: {
              AWSS3: {
                  bucket: jsonConfig.campaignFile, //REQUIRED -  Amazon S3 bucket
              }
          }
        });
        
        const file = event.target.files[0];
        
        let uploadResult = await Storage.put(file.name, file, {contentType: file.type});
        let getImgS3=await Storage.get(uploadResult.key);
        var picSplit=getImgS3.split("?");
        
        this.setState({
            secondImg:picSplit[0],
            firstImageStat:false
        });
      }
      async uploadVideo(event) {
        Amplify.configure({
          Storage: {
              AWSS3: {
                  bucket: jsonConfig.campaignFile, //REQUIRED -  Amazon S3 bucket
              }
          }
        });
        
        const file = event.target.files[0];
        
        let uploadResult = await Storage.put(file.name, file, {contentType: file.type});
        let getImgS3=await Storage.get(uploadResult.key);
        var picSplit=getImgS3.split("?");
        
        this.setState({
            videoFile:picSplit[0],
            videoFileStatus:true,
        });
      }
      
    render() {
        return (
            <div id="Smart_Feed___Content3" className="commonWidthHeight">
                <SideMenu/>
                <div className="middlePanelOthers">
                <div id="HeaderOthers">
                    <div id="Interaction_Board">
                        <span>Digital Broadcasting</span>
                    </div>
                    <div className="pull-right flagDiv" style={{marginTop:'-15px'}}>
                        <span><img src={this.state.countryFlag} alt="au" className="mr7 flagSize"/></span>
                        <span>{this.state.countryCode}</span>
                    </div>
                </div>
                
                    <div id="Digital_broadcasting">
                        <div className="row_content_1">
                            <div className="col-md-8-content">
                                <div style={{width:'100%'}}>
                                    <div className="db_content_header_text">Create Content</div>
                                    <div className="db_content_details">
                                        <div className="db_content_frm_details">
                                            <div className="db_ct">
                                                <div className="labelText db_label_text">Campaign Title</div>
                                                <input 
                                                type="text"
                                                className="txtType inputForuser w-355 h-36"
                                                onChange={this.titleHandler}
                                                value={this.state.campaignTitle}
                                                />
                                            </div>
                                            <div className="mt23">
                                                <div className="labelText db_label_text">Input Tags</div>
                                                
                                                <select className="txtType inputForuser w-355 h-36 arrowAlignForw355" onChange={this.inputTagschange}>
                                                    <option value="0">Select Column</option>
                                                    {this.state.csvSmsHeader.map((value, key) => (
                                                        <option value={value} key={key}>[{value}]</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="mt25">
                                                <button className="db_add_button type_a" onClick={this.insertTagsToContent}>
                                                    <div style={{width:'80px'}}>
                                                        <img src={img_add} className="db_button_add" alt="upldImg"/>
                                                        <span className="ml11">Insert</span>
                                                    </div>
                                                </button>
                                            </div>
                                            <div className="mt23">
                                                <div className="labelText db_label_text">Content</div>
                                                <textarea 
                                                className="txtType inputForuser w-355 db_area"
                                                value={this.state.csvTagsForContent}
                                                onChange={this.contentHandler}
                                                
                                                ></textarea>
                                            </div>
                                            
                                            <div className="db_button_holder mt22">
                                                <div className="upload-btn-wrapper-broadcasting">
                                                    <button className="db_img_button">
                                                        <img src={upldImg} className="db_button_audio" alt="upldImg"/>
                                                        <span className="ml11">Add Image</span>
                                                    </button>
                                                    <input type="file"
                                                        onChange={(e) => this.state.firstImageStat===false?this.uploadfirstImage(e):this.uploadSecondImage(e)}
                                                        className="txtFileType mt10"
                                                        style={{width:'273px'}}
                                                    />
                                                </div>
                                                

                                                <div className="upload-btn-wrapper-broadcasting ml10">
                                                    <button className="db_img_button">
                                                        <img src={video} className="db_button_audio" alt="video"/>
                                                        <span className="ml11">Add Video</span>
                                                    </button>
                                                    <input type="file"
                                                        onChange={(e) => this.uploadVideo(e)}
                                                        className="txtFileType mt10"
                                                        style={{width:'273px'}}
                                                    />
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <div className="db_content_img_details">
                                            <div className="db_content_img">
                                                <div className="labelText db_label_text alignBoth_db">
                                                    <div>Images</div>
                                                    <div className="noInteractionText_ribbon_live w-132">
                                                        <span className="comingsoon_text">COMING SOON</span>
                                                    </div>
                                                </div>
                                                <div className="db_img_src">
                                                    <img src={this.state.secondImg} alt="db_img_2" className="db_img"/>
                                                    <img src={this.state.firstImg} alt="db_img_1" className="ml10 db_img"/>
                                                </div>
                                            </div>
                                            <div className="db_content_img w-138 mt31">
                                                <div className="labelText db_label_text alignBoth_db">
                                                    <div>Videos</div>
                                                    <div className="noInteractionText_ribbon_live w-132">
                                                        <span className="comingsoon_text">COMING SOON</span>
                                                    </div>
                                                </div>
                                                
                                                <div className="db_img_src" style={{width:'263px'}}>
                                                {this.state.videoFileStatus===true?(
                                                    <video className="video_loader_db" controls>
                                                        <source src={this.state.videoFile} type="video/mp4"/>
                                                    </video>
                                                ):(
                                                    <img src={db_vdo} alt="db_img_2" className="db_img_vdo"/>
                                                )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            

                            <div className="col-md-4-content">
                                <div className="db_all_step">
                                    <div className="db_serial_step">
                                        <div className="db_step_divider">
                                        <div className="db_step_done_box"><img src={page_done} alt="page_done"/></div>
                                        <div className="db_step_subtext_disable">Select Channels<br/>& Audience</div>
                                        </div>
                                    </div>
                                    <div className="db_step_line_ct_2"></div>
                                    <div className="db_serial_step_ct_2" style={{marginTop:'50px'}}>
                                        <div className="db_step_divider">
                                            <div className="db_step_box">2</div>
                                            <div className="db_active_step">
                                                <div className="db_step_header_ct_2">Create<br/> Content</div>
                                                <div className="db_step_subtext_ct_2">Feed in all your & <br/>Constant here</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="db_step_line_2_ct_2"></div>
                                    <div className="db_serial_step" style={{marginTop:'45px'}}>
                                        <div className="db_step_divider">
                                            <div className="db_step_box_disable">3</div>
                                            <div className="db_step_subtext_disable">Preview <br/>Content</div>
                                        </div>
                                    </div>
                                    <div className="db_step_line_3"></div>
                                    <div className="db_serial_step" style={{marginTop:'50px'}}>
                                        <div className="db_step_divider">
                                            <div className="db_step_box_disable">4</div>
                                            <div className="db_step_subtext_disable">Schedule <br/>Broadcast</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt190">
                                    <img src={prev} className="type_a"  alt="prev" onClick={this.onClick_elContentPrev}/>
                                    <button className="button ml35 db_next_button" onClick={this.onClick_elContentNext}>NEXT</button>
                                </div>
                            </div>
                        </div>   
                    </div>

                </div>
                
            </div>
        )
    }
}