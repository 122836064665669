import React, { Component } from 'react';
import AppRoute from './appRoute';
import LandingScreen from './LandingScreen.js';
import './App.css';
import './css/style.css';
import './css/bootstrap.min.css';
import 'react-notifications/lib/notifications.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"


import { ConfirmSignIn, ConfirmSignUp, ForgotPassword, RequireNewPassword, SignIn, SignUp, VerifyContact, withAuthenticator  } from 'aws-amplify-react';

class App extends Component {
	
	componentDidMount()
	{
		window.addEventListener('resize', this.windowDidResize);
		window.addEventListener('load', this.windowDidResize);
		
	}
	windowDidResize = () => {
		let h=window.innerHeight;
		var obj = document.getElementById("App");
		
		if(obj!=undefined || obj!=null)
		{
			var c=obj.offsetHeight;
			var calc=(h-c)/2;
			document.getElementById("App").style.marginTop =Math.max(0, calc) + 'px';
		}
		else{
			 c=0;
		}
		
	  }
	
	render() {
		return (		
		<div id="App" className="App">	
			<AppRoute>
				<LandingScreen/>
			</AppRoute>
		</div>
		);
	  }
}
export default withAuthenticator(App, false, [
  
  <SignIn/>,
  <ConfirmSignIn/>,
  <VerifyContact/>,
  <SignUp/>,
  <ConfirmSignUp/>,
  <ForgotPassword/>,
  <RequireNewPassword />
  
]);
  