import React, { Component } from 'react';
import SideMenu from './sideMenu.js';
import frusted from './images/Ico-HappyCopy2.png';
import meh from './images/meh.png'
import happy from './images/happy.png';
import fashion1 from './images/fashion1.png'
import fb from './images/ec/F_icon.png';
import wa from './images/ec/whatsapp.png';
import sms from './images/ec/sms.png';
import twitter from './images/ec/Twitter_Bird.png';
import insta from './images/ec/instagram.png';
import messenger from './images/ec/messenger.png';
import fashion2 from './images/fashion2.png';
import fashion3 from './images/fashion3.png';
import prev from './images/arrow_prev.png';
import au from './images/au.png';
import sort from './images/sort1.png';

export default class Fashion extends Component {
    onClick_elSentiment= (ev) =>{
        this.props.history.push("/sentiment");
      }
    render() {
        return (
            <div id="Smart_Feed___fashion" className="commonWidthHeight">
                <SideMenu/>
                <div className="middlePanelOthers">
                <div id="HeaderOthers">
                    <div id="Interaction_Board">
                        <span>Sentiment in Australia </span>
                        <span id="today">TODAY <i className="fa fa-caret-right caret-right"></i></span>
                    </div>
                    <div className="pull-right">
                        <img src={sort} alt="sort"/>
                        <img src={au} alt="au"/>
                    </div>
                </div>
                
                <div id="Group_2_A1_Group_48">
                    <div className="col-md-12">
                        <div className="row" style={{marginTop: '20px'}}>
                            <div className="col-md-4">
                                <img src={prev} className="imgHeaderIcon_sentiment type_a" onClick={this.onClick_elSentiment} alt="prev"/>
                                <img src={happy} className="imgHeaderIcon_sentiment" alt="happy"/>
                                <div className="divHeaderTxt">
                                    <div id="SentimentHeaderTxt">Happy Posts</div>
                                    <p id="SentimentSubHeaderTxt">People who love your social image</p>
                                </div>
                                <div className="imagePost">
                                    <img src={fashion1} className="ImgPostIcon" alt="fashion1"/>
                                    <p className="ImgPostText">Love this blue zipper hoodie from @fashioncompany…</p>
                                    <img src={fb} className="imgHeaderIcon_sentiment_social" style={{marginLeft:'20px'}} alt="fb"/>
                                    <div className="divHeaderTxt">
                                        <div id="Sender">Evelyn Ryan</div>
                                        <p id="Sender">2:48 PM</p>
                                    </div>
                                </div>
                                <div className="smallPost">
                                    <p className="ImgSmallPostText">I got the black quick dry Tshirt from your store & I must say it feels so good. Feels like feathers</p>
                                    <img src={wa} className="imgHeaderIcon_sentiment_social" alt="wa" style={{marginLeft:'20px'}}/>
                                    <div className="divHeaderTxt">
                                        <div id="Sender">Jonathan Kim</div>
                                        <p id="Sender">7:03 AM</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <img src={meh} className="imgHeaderIcon_sentiment" alt="meh"/>
                                <div className="divHeaderTxt">
                                    <div id="SentimentHeaderTxt">Meh Posts</div>
                                    <p id="SentimentSubHeaderTxt">People who neither love nor hate</p>
                                </div>
                                <div className="smallPost" style={{marginTop:'70px'}}>
                                    <p className="ImgSmallPostText">The right material weight, but somewhat small and not very good color variety.</p>
                                    <img src={sms} className="imgHeaderIcon_sentiment_social" style={{marginLeft:'20px'}} alt="sms"/>
                                    <div className="divHeaderTxt">
                                        <div id="Sender">Jonathan Kim</div>
                                        <p id="Sender">7:03 AM</p>
                                    </div>
                                </div>
                                <div className="imagePost" style={{marginTop:'20px'}}>
                                    <img src={fashion2} className="ImgPostIcon" alt="fashion2"/>
                                    <p className="ImgPostText">Good Tshirt quality, but it is way longer than what I had expected …</p>
                                    <img src={twitter} className="imgHeaderIcon_sentiment_social" alt="twitter" style={{marginLeft:'20px'}}/>
                                    <div className="divHeaderTxt">
                                        <div id="Sender">Andrew Rose</div>
                                        <p id="Sender">2:48 PM</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <img src={frusted} className="imgHeaderIcon_sentiment" alt="frusted"/>
                                <div className="divHeaderTxt">
                                    <div id="SentimentHeaderTxt">Frustrated Posts</div>
                                    <p id="SentimentSubHeaderTxt">People who need help to feel better</p>
                                </div>
                                
                                <div className="smallPost" style={{marginTop:'70px'}}>
                                    <p className="ImgSmallPostText">Do yourself a favor and ignore anyone who tells you to be yourself. Bad idea in your case.</p>
                                    <img src={messenger} className="imgHeaderIcon_sentiment_social" alt="messenger" style={{marginLeft:'20px'}}/>
                                    <div className="divHeaderTxt">
                                        <div id="Sender">Albert Stanley</div>
                                        <p id="Sender">7:03 AM</p>
                                    </div>
                                </div>
                                <div className="imagePost" style={{marginTop:'20px'}}>
                                    <img src={fashion3} className="ImgPostIcon" alt="fashion3"/>
                                    <p className="ImgPostText">Got this Tshirt in all torn condition. So disappointing. @fashioncompany…</p>
                                    <img src={insta} className="imgHeaderIcon_sentiment_social" style={{marginLeft:'20px'}} alt="insta"/>
                                    <div className="divHeaderTxt">
                                        <div id="Sender">Andrew Rose</div>
                                        <p id="Sender">2:48 PM</p>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        )
    }
}