import React, { Component } from 'react';
import SideMenu from './sideMenu.js';
import sort from './images/sort1.png';
import img_myFeed from './images/screen/myfeed.png';
import img_mySearch from './images/screen/search.svg';
import img_myPaging from './images/screen/paging.svg';
export default class Sentiment extends Component {
    
    onClick_elFashion= (ev) =>{
        this.props.history.push("/fashion");
      }
    render() {
        return (
            <div id="Smart_Feed___RobotCleaner" className="commonWidthHeight">
                <SideMenu/>
                <div className="middlePanelOthers">
                    <div id="HeaderOthers">
                        <div id="Interaction_Board" className="w-500">
                            <span className="pull-left">My Feed</span>
                            <div className="margin-align noInteractionText_ribbon_live ml20 pull-left w-132">
                                <span className="comingsoon_text">COMING SOON</span>
                            </div>
                        </div>
                        <div className="pull-right">
                        
                            <img src={img_mySearch} alt="search"/>
                            <img src={sort} alt="sort" className="ml13"/>
                            <select className="txtType inputForuserLive w-139 arrowAlignForw139 displayInline ml13">
                                <option value="0">All Channels</option>
                                <option value="1">Param 1</option>
                                <option value="2">Param 2</option>
                            </select>
                            <img src={img_myPaging} alt="au" className="ml13"/>
                            
                            
                        </div>
                    </div>
                    <div id="Group_2_A1_Group_47" style={{position:'relative',marginTop:'20px'}}>
                        <img src={img_myFeed} alt="myfeed" style={{position:'absolute',top:'26px',right:'0px'}}/>
                    </div>
                    
                </div>
            </div>
        )
    }
}