import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
//import { createBrowserHistory } from 'history';
//import { browserHistory } from 'react-router';
//-----------IMPORT IMAGES-------------//
import Logo from './images/logo.png';
import sd from './images/MenuIcons/sd.svg';
import mi from './images/MenuIcons/mi.svg';
import analyse from './images/MenuIcons/ar.svg';
import sf from './images/MenuIcons/sf.svg';
import lm from './images/MenuIcons/lm.svg';
import cm from './images/MenuIcons/cm.svg';
import ac from './images/MenuIcons/ac.svg';
import cog from './images/MenuIcons/cog.svg';
import humberger from './images/MenuIcons/humberger.svg';
//import cart from './images/MenuIcons/cart.svg';
import cart from './images/MenuIcons/prebuiltBoards.svg';

import call from './images/MenuIcons/callHelp.svg';
import userIcon from './images/userIcon.png';
//import line1 from './images/line.png';
import Modal from 'react-modal';
import img_elClose from './images/arrow_prev.png';
import img_elClose2 from './images/close.svg';

import {Auth} from "aws-amplify";
import axios from 'axios';

var AWS = require("aws-sdk");
var wc = require('which-country');
const jsonConfig = require("./Config.json");

class SideMenuCollapsble extends Component {
	
    constructor(props) {
        super(props);
        this.state={
            openModal2:false,
            numberOfActiveInteractions:0,
            userRegistered:0,
            checkboxID:1234567,
            activeChecked:false,
            
            defaultBackgroundSubMenu:'transparent',
            iClassSmartfeedMainmenu:'fa fa-angle-right faIconRotate',
            rolePermission:true,
            modalIsOpen: false,

            blStorePermission:true,
            smartFeedPermission:true,
            lmPermission:true,
            interactionPermission:true,
            resultPermission:true,
            contentPermission:true,
            defaultOrgName:'',
            profilePictureUrl:'',

            defaultOrgId:'',
            defaultUserName:'',
            defaultUserEmail:'',
            defaultRoleName:'',
            defaultCognitoId:'',
            defaultPhoneNumber:'',

            feedSubmenu:'none',
            humbergerclass:'tab type_a',
            hamburgerImg:humberger
            
        }
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.logOut = this.logOut.bind(this);
        
    }
    componentDidMount()
    {
        AWS.config.update({
            credentials: new AWS.CognitoIdentityCredentials({
              IdentityPoolId: jsonConfig.awsConfigCredentials.IdentityPoolId,
              RoleArn: jsonConfig.awsConfigCredentials.RoleArn,
            }),
            region: jsonConfig.awsConfigRegion,
          });
        this.userData();
        
        Modal.setAppElement('body');
        var chkId=Math.floor((Math.random()*1000000000000000000)+1);
        this.setState({
            checkboxID:chkId,
        })
        
        
    }

    async userData() {
        var user = await Auth.currentAuthenticatedUser();
        console.log("USER",user);
        let getUserById=null;
        try {
            getUserById = await axios.get(jsonConfig.rdsApi+'users/'+user.attributes.sub,{
                headers: {
                  'X-API-KEY': jsonConfig.ApiKey,
                  'Content-Type': 'application/json'
                }});
            this.setState({
                defaultOrgName:getUserById.data.data[0].organization.name,
                profilePictureUrl:getUserById.data.data[0].profilePictureUrl,
                defaultOrgId:getUserById.data.data[0].organizationId,
                defaultUserName:user.attributes.name,
                defaultUserEmail:user.attributes.email,
                defaultPhoneNumber:user.attributes.phone_number,
                defaultRoleName:getUserById.data.data[0].role.name,
                defaultCognitoId:user.username
              })
            if(getUserById!==null)
            {
                if(getUserById.data.data[0].role.name==='System' || getUserById.data.data[0].role.name==='Admin')
                {
                    this.setState({rolePermission:true})
                }
                else{
                    var resScreen = await axios.get(jsonConfig.rdsApi+'screenPermissionByRole/'+getUserById.data.data[0].roleId,{
                        headers: {
                          'X-API-KEY': jsonConfig.ApiKey,
                          'Content-Type': 'application/json'
                        }});
                    if(resScreen.status===200)
                    {
                        if(resScreen.data.data[0].status==='false')
                        {
                            this.setState({blStorePermission:false})
                        }
                        if(resScreen.data.data[1].status==='false')
                        {
                            this.setState({smartFeedPermission:false})
                        }
                        if(resScreen.data.data[2].status==='false')
                        {
                            this.setState({lmPermission:false})
                        }
                        if(resScreen.data.data[3].status==='false')
                        {
                            this.setState({interactionPermission:false});
                        }
                        if(resScreen.data.data[4].status==='false')
                        {
                            this.setState({resultPermission:false})
                        }
                        if(resScreen.data.data[5].status==='false')
                        {
                            this.setState({contentPermission:false})
                        }
                        
                    }
                        this.setState({rolePermission:false})
                    }
                

                
            }
            else{
                this.setState({
                    rolePermission:false,
                    blStorePermission:false,
                    smartFeedPermission:false,
                    lmPermission:false,
                    interactionPermission:false,
                    resultPermission:false,
                    contentPermission:false,
                })
            }
            
        } catch (error) {
            console.error();
            console.error("Error response:",error);
            this.setState({
                rolePermission:false,
                blStorePermission:false,
                smartFeedPermission:false,
                lmPermission:false,
                interactionPermission:false,
                resultPermission:false,
                contentPermission:false,
            })
        }
        this.getActiveInteractions();
        this.getAllusers();
        this.getLocation();
      }
async getAllusers()
{
    var resUsers = await axios.get(jsonConfig.rdsApi+"filterUsers?organizationId="+this.state.defaultOrgId+'&roleName=all',{
        headers: {
          'X-API-KEY': jsonConfig.ApiKey,
          'Content-Type': 'application/json'
        }});
        this.setState({
            userRegistered:resUsers.data.data.length,
        })
}
getActiveInteractions()
{
    var interactionBoardTable = "interactions";
    var docClient = new AWS.DynamoDB.DocumentClient();
      var params = {
        TableName : interactionBoardTable,
        FilterExpression : "organizationName = :orgName AND boardStatus = :status",
        ExpressionAttributeValues: {
            ":orgName":this.state.defaultOrgName,
            ":status": 'Active',
        }
    };
    
    docClient.scan(params, (err, data)=> {
        if (err) {
            console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
        } else {
        if(data.Items.length>0)
          {
            this.setState({
                numberOfActiveInteractions:data.Items.length,
                boardList:data.Items,
            })
        }
        else{
            this.setState({
                numberOfActiveInteractions:0,
                boardList:[],
            })
        }
        }
    });
}
getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.showPosition);
    } else {
      console.log("BROWSER NOT SUPPORTED");
    }
  }
  
  showPosition=(position)=> {
    var getCountryCode=wc([position.coords.longitude,position.coords.latitude ]);
    if(getCountryCode==='AUS')
    {
        this.setState({websiteName:'https://blueleap.com.au'})
    }
    else{
        this.setState({websiteName:'https://blueleap.com'})
    }
  }
    logOut(){
        localStorage.removeItem('orgnaizationName');
        localStorage.removeItem('roleName');
        localStorage.removeItem('organaizationId');
        localStorage.removeItem('roleId');
        localStorage.removeItem('firstLoad');
        localStorage.clear();
        Auth.signOut()
        .then(data => console.log("CHECK",data))
        .catch(err => console.log("ERROR",err));
        //this.props.history.push("/");
        window.location.href="/"
        
    }
    openModal() {
        this.setState({modalIsOpen: true});
      }
      closeModal() {
        this.setState({modalIsOpen: false});
      }
     
    onClick_elCreateUser= (ev) =>{
		this.props.history.push("/settings");
      }
    onClick_elInteraction= (ev) =>{
		this.props.history.push("/interaction");
    }
    onClick_elSentiment= (ev) =>{
		this.props.history.push("/sentiment");
    }
    onClick_elLiveMonitoring= (ev) =>{
		this.props.history.push("/livemonitoring");
    }
    onClick_elTracker= (ev) =>{
		this.props.history.push("/customtracker");
    }
    onClick_elTrending= (ev) =>{
		this.props.history.push("/trending");
    }
    onClick_elResults= (ev) =>{
		this.props.history.push("/results");
    }
    onClick_elContent= (ev) =>{
		this.props.history.push("/content3");
    }
    onClick_elProfile= (ev) =>{
		this.props.history.push("/profile");
    }
    onClick_elNoPermission= () =>{
		this.props.history.push("/access-denied");
    }
    onClick_elGuidedDemo= () =>{
		this.props.history.push("/take-a-tour");
    }
    onClick_elGotoStore= () =>{
		this.props.history.push("/pre-built-boards");
    }
    onClick_elQuicksight= () =>{
		this.props.history.push("/quicksight");
    }
    onClick_elDashboard= () =>{
        //this.setState({openModal2:true})
        this.props.history.push("/smart-dashboard");
    }
    onClick_elPop= () =>{
        this.setState({openModal2:true})
    }
    closeModal2=()=>{
        this.setState({openModal2: false});
    }
    onClick_elFeed= () =>{
        console.log("HUM",this.state.hamburgerImg);
        var a=document.getElementById("feedSubmenu_agentChat").style.display;
        if(a==='none' || a==='')
        {
            document.getElementById("feedSubmenu_agentChat").style.display='block';
            document.getElementById("Hamburger_agentChat").style.display='none';
            this.setState({
                humbergerclass:'tab type_a',
                hamburgerImg:humberger
            });
        }
        else{
            document.getElementById("feedSubmenu_agentChat").style.display='none';
            document.getElementById("Hamburger_agentChat").style.display='none';
            this.setState({
                humbergerclass:'tab type_a',
                hamburgerImg:humberger
            });
        }
        
    }
    
    onClick_elHumberger= () =>{
        var b=document.getElementById("Hamburger_agentChat").style.display;
        if(b==='none' || b==='')
        {
            document.getElementById("Hamburger_agentChat").style.display='block';
            document.getElementById("feedSubmenu_agentChat").style.display='none';
            this.setState({
                humbergerclass:'tab type_a NavActive_Tab_submenu',
                hamburgerImg:img_elClose2
            });
        }
        else{
            document.getElementById("Hamburger_agentChat").style.display='none';
            document.getElementById("feedSubmenu_agentChat").style.display='none';
            this.setState({
                humbergerclass:'tab type_a',
                hamburgerImg:humberger
            });
        }
        
    }
    
  
    render() {
        if(window.location.pathname=='/take-a-tour')
          {
            document.body.style.backgroundColor = "#13A5FF";
          }
          else{
            //document.body.style.backgroundColor = "#cde8ff";
            document.body.style.backgroundColor = "#fff";
          }
        let imgUrl=(<img id="Photo_collapse" src={userIcon} alt="profile"/>)
        if(this.props.appActions===true)
        {
            
            imgUrl=(<img id="Photo_collapse" src={this.props.sendProfImgUrl} alt="profile"/>)
        }
        else
        {
            if(this.state.profilePictureUrl===undefined || this.state.profilePictureUrl===null || this.state.profilePictureUrl==='')
            {
                imgUrl=(<img id="Photo_collapse" src={userIcon} alt="profile"/>)
                
            }
            else{
                imgUrl=(<img id="Photo_collapse" src={this.state.profilePictureUrl} alt="profile"/>)         
            }
        }
        
        return (
            <div id="Side_Menu___Channels_collapse">
                <div id="side_menu_collapse">
                    <div id="bl_logo___blue_collapse" className="bl_logo___blue type_a" onClick={this.onClick_elPop}>
                        <img className="logoImg_collapse" src={Logo} alt="blueleap"/>  
                    </div>
                    <div id="Group_collapse" className="tabs">
                        <div className="tab_collapse type_a" onClick={this.onClick_elDashboard}>
                            <div id="Page_1_c_sd">
                                <img className="menu_icon" src={sd} alt="sd"/>
                            </div>
                        </div>
                        <div className="tab_collapse type_a" onClick={this.state.interactionPermission===true?this.onClick_elInteraction:this.onClick_elNoPermission}>
                            <div id="Page_1_c">
                                <img className="menu_icon" src={mi} alt="mi"/>
                            </div>
                        </div>
                        <div className="tab_collapse type_a" onClick={this.state.lmPermission===true?this.onClick_elLiveMonitoring:this.onClick_elNoPermission}>
                            <div id="Page_1_c_c">
                                <img className="menu_icon" src={lm} alt="lm"/>
                            </div>
                        </div>
                        <div className="divider_collapse"></div>
                        
                        <div className="tab_collapse type_a" onClick={this.onClick_elFeed}>
                            <div id="Page_1_c_c">
                                <img className="menu_icon" src={sf} alt="sf"/>
                            </div>


                            <div id="feedSubmenu_agentChat" className="overlay_collapseble_feed">
                                
                                <div className="overlay-content-collapseble-feed">
                                    <div className="col-md-12" style={{marginTop:'10px',marginBottom:'10px'}} onClick={this.state.smartFeedPermission===true?this.onClick_elSentiment:this.onClick_elNoPermission}>
                                        My Feed
                                    </div>
                                    <div className="col-md-12" style={{marginTop:'10px',marginBottom:'10px'}} onClick={this.state.smartFeedPermission===true?this.onClick_elTrending:this.onClick_elNoPermission}>
                                        Discover
                                    </div>
                                    <div className="col-md-12" style={{marginTop:'10px',marginBottom:'10px'}} onClick={this.state.smartFeedPermission===true?this.onClick_elTracker:this.onClick_elNoPermission}>
                                        Social Monitor
                                    </div>
                                </div>
                            </div>

                        </div>

                            

                        <div className="tab_collapse type_a" onClick={this.onClick_elContent}>
                            <div id="Page_1_c_d">
                                <img className="menu_icon" src={cm} alt="cm"/>
                            </div>  
                        </div>
                        <div className="divider_collapse"></div>
                        
                        <div className="tab_collapse type_a" onClick={this.state.resultPermission===true?this.onClick_elResults:this.onClick_elNoPermission}>
                            <div id="Page_1_c_a">
                                <img className="menu_icon" src={analyse} alt="analyse"/>
                            </div>
                        </div>

                        <div className="tab_collapse type_a NavActive_Tab_collapse">
                            <div id="Page_1_c_c">
                                <img className="menu_icon_ac" src={ac} alt="ac" style={{marginLeft:'19px'}}/>
                            </div>
                        </div>
                    </div>
                    
                    
                    
                </div>
                <div className="fixedFooter_collapse">
                        <div className={this.state.humbergerclass} style={{textAlign:'center',position: 'unset',height:'46px'}} onClick={this.onClick_elHumberger}>
                            <img src={this.state.hamburgerImg} alt="humberger" className="mt15"/>

                            <div id="Hamburger_agentChat" className="overlay_collapseble_Hamburger">
                                
                                <div className="overlay-content-collapseble-Hamburger">
                                    <div className="row ml0 mr0" style={{padding:'10px'}}>
                                        <div className="col-md-4">
                                            <img src={cart} alt="cart"  onClick={this.onClick_elGotoStore} style={{height:'20px'}}/>
                                        </div>
                                        <div className="col-md-4">
                                            <div className={this.state.activeSettingsTab} onClick={this.state.rolePermission===true?this.onClick_elCreateUser:this.onClick_elProfile}>
                                                <img src={cog} alt="cog" />
                                            </div>
                                        </div>
                                        <div className="col-md-4" onClick={this.onClick_elGuidedDemo}>
                                            <img src={call} alt="call" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="User_collapse" className="col-md-12 tab type_a" onClick={this.openModal}>
                            <div id="Group_5_da_collapse">
                                {imgUrl}
                            </div>                       
                        </div>
                        <div id="_2019_Copyright_Blue_collapse">
                            <span>©2020 <br/>BlueLeap Inc.</span>
                        </div>
                </div>


        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          style={{
            overlay: {
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'transparent'
            },
            content : {
              top                   : 'auto',
              left                  : '20em',
              right                 : 'auto',
              bottom                : '100px',
              marginRight           : '-50%',
              //transform             : 'translate(-50%, -50%)',
              borderRadius          : '10px',
              backgroundColor       : '#ffffff',
              overflow              : 'hidden',
              width                 : '277px',
              border                :'1px solid #008CFF'
            }
          }}
        >
          <div className="type_a close_style" onClick={this.closeModal}>
            <img src={img_elClose} alt="close"/>
          </div>
          <div className="row margin-align">
            <div className="col-md-12 text-center">
                {imgUrl}
            </div>
            <div className="col-md-12 margin-align_30 text-center">
                {this.state.defaultUserName===''?(
                    <p id="profilePopText"></p>
                ):(
                    <p id="profilePopText">{this.state.defaultUserName}</p>
                )}
                <p id="profilePopText">{this.state.defaultUserEmail}</p>
                {this.state.defaultPhoneNumber===''?(
                    <p id="profilePopText"></p>
                ):(
                    <p id="profilePopText">{this.state.defaultPhoneNumber}</p>
                )}
            </div>
            <div className="col-md-12 margin-align_30 text-center">
                <button className="button" style={{width:'100px',marginRight: '0px'}} onClick={this.onClick_elProfile}><i className="fa fa-pencil-square-o"></i> Edit</button>
                <button className="button" style={{width:'111px',marginLeft:'19px'}} onClick={this.logOut}><i className="fa fa-sign-out" aria-hidden="true"></i> Sign Out</button>
            </div>
            
          </div>
        </Modal>
        <Modal
                isOpen={this.state.openModal2}
                onRequestClose={this.closeModal2}
                style={{
                    overlay: {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 140, 255, 0.45)'
                    },
                    content : {
                    top                   : '50%',
                    left                  : '50%',
                    right                 : 'auto',
                    bottom                : 'auto',
                    marginRight           : '-50%',
                    transform             : 'translate(-50%, -50%)',
                    borderRadius          : '18px',
                    backgroundColor       : '#f2f7ff',
                    overflow              : 'hidden',
                    width                 : '500px'
                    }
                }}
                >
                
                <div style={{display:'flex',flexWrap:'wrap',justifyContent: 'space-between'}}>
                    <div className="mt15">
                        <div id="VersionHeader">Blueleap Dialogue</div>
                        <p id="VersionNumber">Version 3.2</p>
                    </div>
                    <div className="type_a" onClick={this.closeModal2}>
                        <img src={img_elClose2} alt="close" className="img_close2"/>
                    </div>
                </div>
                <div className="mT24">
                    <span className="labelText">Organisation Name : </span>
                    <span className="versionResult">{this.state.defaultOrgName}</span>
                </div>  
                <div className="mt12">
                    <span className="labelText">Website : </span>
                    <span className="versionResult type_a hyperlink" onClick={this.onClick_elDetailTour}>{this.state.websiteName}</span>
                </div>
                <div className="mt12">
                    <span className="labelText">Users Registered : </span>
                    <span className="versionResult">{this.state.userRegistered}</span>
                </div>
                <div className="mt12">
                    <span className="labelText">Active Interactions : </span>
                    <span className="versionResult">{this.state.numberOfActiveInteractions}</span>
                </div>
                
                <div className="col-md-12">
                    <img className="pull-right" src={Logo} alt="blueleap" style={{height:'32px'}}/>   
                </div>
                    
                
                </Modal>
                
        
</div>

        );
    }
    
}
export default withRouter(SideMenuCollapsble)