import React, { Component } from 'react';
import SideMenu from './sideMenu.js';


import img_calender from './images/buttons/calendar.svg';
import page_done from './images/buttons/page_done.svg';
import Calendar from 'react-calendar';

import img_wa from './images/ec/whatsapp.png';
import img_fb from './images/ec/messenger.png';
import img_send_contacts from './images/buttons/send_contacts.svg';


import prev from './images/buttons/prev_carousel.svg';
import "./css/Calendar.css";
import img_flAu from './images/Flag_of_Australia.png';
var wc = require('which-country');


export default class Content6 extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            countryFlag: img_flAu,
            countryCode:'AUS',
            date: new Date(),
            options:{day: '2-digit',year: 'numeric', month: 'long'}
        };
        this.showPosition = this.showPosition.bind(this);
      }
    componentDidMount()
    {
        this.getLocation();
    }
    onClick_elContentNext= (ev) =>{
		this.props.history.push("/content6");
    }
    onClick_elContentPrev= (ev) =>{
		this.props.history.push("/content4");
    }
    calendarChange=date=>{
        console.log(date);
        this.setState({date:date})
    }
      getLocation() {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(this.showPosition);
        } else {
          console.log("BROWSER NOT SUPPORTED");
        }
      }
      
      showPosition(position) {
        var getCountryCode=wc([position.coords.longitude,position.coords.latitude ]);
        //var getCountryCode=wc([151,-33]);
        console.log("COUNTRY CODE",getCountryCode);
        var tempCountrycode=getCountryCode.substring(0,getCountryCode.length-1)
        var getFlagUrl="https://www.countryflags.io/"+tempCountrycode+"/flat/64.png"
        
            this.setState({
                countryFlag: getFlagUrl,
                countryCode:tempCountrycode
            })
      }
      
    render() {
        return (
            <div id="Smart_Feed___Content3" className="commonWidthHeight">
                <SideMenu/>
                <div className="middlePanelOthers">
                <div id="HeaderOthers">
                    <div id="Interaction_Board">
                        <span>Digital Broadcasting</span>
                    </div>
                    <div className="pull-right flagDiv" style={{marginTop:'-15px'}}>
                        <span><img src={this.state.countryFlag} alt="au" className="mr7 flagSize"/></span>
                        <span>{this.state.countryCode}</span>
                    </div>
                </div>
                
                    <div id="Digital_broadcasting">
                        <div className="row_content_1">
                            <div className="col-md-8-content_c5">
                                <div style={{width:'100%'}}>
                                    <div className="db_c5_header_holder">
                                        <div className="db_content_header_text">Schedule Braodcast</div>
                                        <div className="db_content_header_text" style={{marginLeft:'195px'}}>Overview</div>
                                    </div>

                                    <div className="db_content_details">
                                        <div className="db_content_frm_details">
                                            <div className="db_ct">
                                                <div className="labelText db_label_text">Launch Time</div>
                                                <div className="mt12">
                                                    <button className="db_add_button">
                                                        <div style={{width:'80px'}}>
                                                            <img src={img_calender} className="db_button_add" alt="img_calender"/>
                                                            <span className="ml11">Calender</span>
                                                        </div>
                                                    </button>
                                                    <span className="text_content_5 ml25">{new Date(this.state.date).toLocaleDateString([],this.state.options)}</span>
                                                </div>
                                                <div className="mT24">
                                                    <Calendar
                                                    value={this.state.date}
                                                    onChange={this.calendarChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="db_content_img_details_c5">
                                            
                                            <div className="up_content_4_label_holder">
                                                <div className="left_img_holder_content_4">
                                                    <img src={img_wa} className="db_content_2_text_icon" alt="img_wa"/>
                                                    <span className="db_content_2_text_icon_name">Whatsapp</span>
                                                </div>
                                                <div className="send_contact">
                                                    <img src={img_send_contacts} alt="img_send_contacts"/>
                                                    <span className="send_contact_text_c4">Sending to all contacts</span>
                                                </div>
                                            </div>
                                            <div className="dividerfromuploader_content2_c5"></div>
                                            <div className="up_content_4_label_holder mt19">
                                                <div className="left_img_holder_content_4">
                                                    <img src={img_fb} className="db_content_2_text_icon" alt="img_wa"/>
                                                    <span className="db_content_2_text_icon_name">Messenger</span>
                                                </div>
                                                <div className="send_contact">
                                                    <img src={img_send_contacts} alt="img_send_contacts"/>
                                                    <span className="send_contact_text_c4">Sending to all contacts</span>
                                                    <div className="xls_name_c5 ml24">all-social-contact-list.xlsx</div>
                                                </div>
                                            </div>
                                            <div className="dividerfromuploader_content2_c5"></div>
                                            <div className="endList_c5 mt27">End of the List</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4-content">
                                <div className="db_all_step">
                                    <div className="db_serial_step">
                                        <div className="db_step_divider">
                                        <div className="db_step_done_box"><img src={page_done} alt="page_done"/></div>
                                        <div className="db_step_subtext_disable">Create<br/> Content</div>
                                        </div>
                                    </div>
                                    <div className="db_step_line_ct_2"></div>
                                    <div className="db_serial_step" style={{marginTop:'48px'}}>
                                        <div className="db_step_divider">
                                        <div className="db_step_done_box"><img src={page_done} alt="page_done"/></div>
                                        <div className="db_step_subtext_disable">Select Channels<br/>& Audience</div>
                                        </div>
                                    </div>
                                    <div className="db_step_line_1_ct_4"></div>
                                    <div className="db_serial_step_ct_2" style={{marginTop:'50px'}}>
                                        <div className="db_step_divider">
                                            <div className="db_step_done_box"><img src={page_done} alt="page_done"/></div>
                                            <div className="db_step_subtext_disable">Preview<br/>Content</div>
                                        </div>
                                    </div>
                                    <div className="db_step_line_2_ct_5"></div>
                                    <div className="db_serial_step" style={{marginTop:'49px'}}>
                                        <div className="db_step_divider">
                                            <div className="db_step_box">4</div>
                                            <div className="db_active_step">
                                                <div className="db_step_header_ct_2">Schedule <br/>Broadcast</div>
                                                <div className="db_step_subtext_ct_2">Send it at a later point<br/>of time?</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="post_now_button_holder">
                                    
                                </div>
                                <div className="mt190">
                                    <div className="text-right mr22"><button className="button db_postnow_button" onClick={this.onClick_elContentNext}>POST NOW</button></div>
                                    <div className="next_prev_button_holder mt18">
                                        <img src={prev} className="type_a"  alt="prev" onClick={this.onClick_elContentPrev}/>
                                        <button className="button ml35 db_next_button" onClick={this.onClick_elContentNext}>SCHEDULE</button>
                                    </div>
                                </div>
                            </div>
                        </div>   
                    </div>

                </div>
                
            </div>
        )
    }
}