import React, { Component } from 'react';
import './css/ReactCrop.css';
import SideMenu from './sideMenu.js';
import img_wa from './images/ec/whatsapp.png';
import db_img_1 from './images/no-image.jpg';
import db_vdo from './images/no-video.png';
import img_channel from './images/screen/img_channel.svg';
import black_friday from './images/screen/black_friday.svg';
import red_inf from './images/buttons/red_inf.svg';
import page_done from './images/buttons/page_done.svg';
import img_fb from './images/ec/messenger.png';
import img_crop from './images/buttons/crop.svg';
import img_send_contacts from './images/buttons/send_contacts.svg';
import crop_img_desc from './images/screen/crop_img_desc.svg';

import img_ac_1 from './images/screen/ac_1.svg';
import img_ac_2 from './images/screen/ac_2.svg';

import db_1 from './images/screen/db_1.png';
import db_2 from './images/screen/db_2.png';
import db_3 from './images/screen/db_3.png';
import db_4 from './images/screen/db_4.png';
import db_5 from './images/screen/db_5.png';
import db_6 from './images/screen/db_6.png';
import db_7 from './images/screen/db_7.png';
import db_8 from './images/screen/db_8.png';
import db_9 from './images/screen/db_9.png';
import db_10 from './images/screen/db_10.png';

import image_gallery from './images/screen/image_gallery.svg';
import vdo_player from './images/screen/vdo_player.svg';
import prev from './images/buttons/prev_carousel.svg';

import img_flAu from './images/Flag_of_Australia.png';
import img_elClose from './images/arrow_prev.png';
import Modal from 'react-modal';
import ReactCrop from 'react-image-crop';
import Carousel from "react-multi-carousel";
import "./css/carousel.css";
var wc = require('which-country');

//const [crop, setCrop] = useState({ aspect: 16 / 9 });
const responsive = {
    desktop: {
        breakpoint: { max: 4000, min: 0 },
        items: 3
      },
  };
export default class Content4 extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            countryFlag: img_flAu,
            countryCode:'AUS',
            modalAdjustIsOpen: false,
            modalCropIsOpen: false,
            headerTextActive:0,
            whatsappTextHeader:'The Big Black Friday sale is',
            headerDescActive:0,
            campaignTitle:'',
            getCampaignData:'',
            getDescription:'',
            imgActiveIcon:7,
            cropForimg:db_7,
            csvSmsHeader:[],
            firstImg:'',
            secondImg:'',
            videoFile:'',
            descText:'Get your hands dirty with the unbelievable deals we have to offer this holiday season. Use BLKFRDY4ALL as the coupon code & get a flat 61% off on everything!',
            crop: {
                unit: '%',
                width: 30,
                aspect: 16 / 9,
              },
            imgForCrop:[
                {"imgcrop":db_1},
                {"imgcrop":db_2},
                {"imgcrop":db_3},
                {"imgcrop":db_4},
                {"imgcrop":db_5},
                {"imgcrop":db_6},
                {"imgcrop":db_7},
                {"imgcrop":db_8},
                {"imgcrop":db_9},
                {"imgcrop":db_10},
            ],
        };
        this.showPosition = this.showPosition.bind(this);
      }
    componentDidMount()
    {
        this.getLocation();
        Modal.setAppElement('body');
        
        this.setState({
            campaignTitle:this.props.location.state.campaigntitle,
            getCampaignData:this.props.location.state.sendData,
            csvSmsHeader:this.props.location.state.csvSmsHeader,
            getDescription:JSON.parse(this.props.location.state.sendData)['content'],
            firstImg:this.props.location.state.FirstImage,
            secondImg:this.props.location.state.SecondImage,
            videoFile:this.props.location.state.VideoFile,
        })
    }
    onClick_elContentNext= (ev) =>{
		this.props.history.push({pathname:"/content5",state: { campaigntitle: this.state.campaignTitle,sendData:this.state.getCampaignData}});
    }
    onClick_elContentPrev= (ev) =>{
		this.props.history.push({pathname:"/content2",state: { campaigntitle: this.state.campaignTitle,sendData:this.state.getCampaignData,csvHeader: this.state.csvSmsHeader}});
    }
    onClick_elModalAdjust1= (ev) =>{
		this.setState({modalAdjustIsOpen: true});
    }
    closeModalAdjust1= (ev) =>{
        this.setState({modalAdjustIsOpen: false});
    }
    onClick_elSetHeader= (event,text,keyIndex) =>{
        this.setState({
            whatsappTextHeader:text,
            headerTextActive:keyIndex,
        });
    }
    onClick_elSetDesc= (event,text,keyIndex) =>{
        this.setState({
            descText:text,
            headerDescActive:keyIndex,
        });
    }
    onClick_elSetImgforCrop= (event,img,keyIndex) =>{
        this.setState({
            cropForimg:img,
            imgActiveIcon:keyIndex,
        });
    }
    
    onClick_elModalCrop= (ev) =>{
        this.setState({
            modalCropIsOpen: true,
            modalAdjustIsOpen: false,
        });
    }
    closeModalCrop= (ev) =>{
		this.setState({modalCropIsOpen: false});
    }
    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({ crop });
      };
      getLocation() {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(this.showPosition);
        } else {
          console.log("BROWSER NOT SUPPORTED");
        }
      }
      
      showPosition(position) {
        var getCountryCode=wc([position.coords.longitude,position.coords.latitude ]);
        //var getCountryCode=wc([151,-33]);
        console.log("COUNTRY CODE",getCountryCode);
        var tempCountrycode=getCountryCode.substring(0,getCountryCode.length-1)
        var getFlagUrl="https://www.countryflags.io/"+tempCountrycode+"/flat/64.png"
        
            this.setState({
                countryFlag: getFlagUrl,
                countryCode:tempCountrycode
            })
      }

    createCarousel()
    {
        var cards=[
            {"text":"Get Your\nCreative Hat On"},
            {"text":"The Big\nBlack Friday…"},
            {"text":"Have no fear,\nPromo is here."},
            {"text":"Have no Test1,\nPromo is here."},
            {"text":"Have no Test,\nPromo is here."},
        ]
        return(
            <Carousel 
                responsive={responsive}
                >
                {cards.map((value, index) => (
                    <div key={index} className={this.state.headerTextActive === index ?"normal_box activeIcon_text_db progressFull_text_icon type_a":"normal_box type_a"} onClick={(e) => this.onClick_elSetHeader(e,value.text,index)}>{value.text}</div>
                    
                ))}
            </Carousel>
        )
    }
    createCarouselText()
    {
        var cards=[
            {"text":"It totally serves to\nthe best as an\nattention-grabber"},
            {"text":"Get your hands\ndirty with the\nunbelievable deals…"},
            {"text":"It totally serves to\nthe best as an\nattention-grabber1"},
            {"text":"It totally serves to\nthe best as an\nattention-grabber2"},
            {"text":"It totally serves to\nthe best as an\nattention-grabber3"},
        ]
        return(
            <Carousel 
                responsive={responsive}
                >
                {cards.map((value, index) => (
                    <div key={index} className={this.state.headerDescActive === index ?"normal_desc_box activeIcon_text_db progressFull_desc_icon type_a":"normal_desc_box type_a"} onClick={(e) => this.onClick_elSetDesc(e,value.text,index)}>{value.text}</div>
                ))}
            </Carousel>
        )
    }
      
    render() {
        return (
            <div id="Smart_Feed___Content3" className="commonWidthHeight">
                <SideMenu/>
                <div className="middlePanelOthers">
                <div id="HeaderOthers">
                    <div id="Interaction_Board">
                        <span>Digital Broadcasting</span>
                    </div>
                    <div className="pull-right flagDiv" style={{marginTop:'-15px'}}>
                        <span><img src={this.state.countryFlag} alt="au" className="mr7 flagSize"/></span>
                        <span>{this.state.countryCode}</span>
                    </div>
                </div>
                
                    <div id="Digital_broadcasting">
                        <div className="row_content_1">
                            <div className="col-md-8-content_4">
                                <div style={{width:'100%'}}>
                                    <div className="db_content_header_text">Preview Content</div>
                                    <div className="col-md-12-content mt41">
                                        <div className="content_4_text_holder">
                                            <div className="labelText db_label_text">Text</div>
                                            <div className="textBox_holder_content_4 mt20">
                                                <div className="title_txt">Title :</div>
                                                <div className="title_name_txt">{this.state.campaignTitle}</div>
                                                <div className="title_txt mt11">Content :</div>
                                                <div className="description_txt">{this.state.getDescription}</div>
                                            </div>
                                        </div>
                                        <div className="db_content_img">
                                            <div className="labelText db_label_text">Images</div>
                                            <div className="db_img_src">
                                                <img src={this.state.firstImg} alt="db_img_2" className="db_img"/>
                                                <img src={this.state.secondImg} alt="db_img_1" className="ml10 db_img"/>
                                            </div>
                                        </div>
                                        <div className="db_content_img">
                                                <div className="labelText db_label_text">Videos</div>
                                                
                                                <div className="db_img_src" style={{width:'263px'}}>
                                                
                                                    <video className="video_loader_db" controls>
                                                        <source src={this.state.videoFile} type="video/mp4"/>
                                                    </video>
                                                
                                                </div>
                                            </div>
                                            <div className="content_4_divider mt20"></div>
                                    </div>



                                    

                                </div>
                                
                            </div>
                            <div className="col-md-4-content">
                                <div className="db_all_step">
                                    <div className="db_serial_step">
                                        <div className="db_step_divider">
                                        <div className="db_step_done_box"><img src={page_done} alt="page_done"/></div>
                                        <div className="db_step_subtext_disable">Select Channels<br/>& Audience</div>
                                        </div>
                                    </div>
                                    <div className="db_step_line_ct_2"></div>
                                    <div className="db_serial_step" style={{marginTop:'48px'}}>
                                        <div className="db_step_divider">
                                        <div className="db_step_done_box"><img src={page_done} alt="page_done"/></div>
                                        <div className="db_step_subtext_disable">Create<br/> Content</div>
                                        </div>
                                    </div>
                                    <div className="db_step_line_1_ct_4"></div>
                                    <div className="db_serial_step_ct_2" style={{marginTop:'50px'}}>
                                        <div className="db_step_divider">
                                            <div className="db_step_box">3</div>
                                            <div className="db_active_step">
                                                <div className="db_step_header_ct_2">Preview<br/>Content</div>
                                                <div className="db_step_subtext_ct_2">Choose the streams & <br/>Audience for your content</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="db_step_line_2_ct_4"></div>
                                    <div className="db_serial_step" style={{marginTop:'45px'}}>
                                        <div className="db_step_divider">
                                            <div className="db_step_box_disable">4</div>
                                            <div className="db_step_subtext_disable">Schedule <br/>Broadcast</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt190">
                                    <img src={prev} className="type_a"  alt="prev" onClick={this.onClick_elContentPrev}/>
                                    <button className="button ml35 db_next_button" onClick={this.onClick_elContentNext}>NEXT</button>
                                </div>
                            </div>
                        </div>   
                    </div>

                </div>
                <Modal
                isOpen={this.state.modalAdjustIsOpen}
                onRequestClose={this.closeModalAdjust1}
                style={{
                    overlay: {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 140, 255, 0.45)'
                    },
                    content : {
                    top                   : '50%',
                    left                  : '50%',
                    right                 : 'auto',
                    bottom                : 'auto',
                    marginRight           : '-50%',
                    transform             : 'translate(-50%, -50%)',
                    borderRadius          : '18px',
                    backgroundColor       : '#f2f7ff',
                    overflow              : 'hidden',
                    width                 : '736px',
                    padding               : '0px'
                    }
                }}
                >
                    <div className="modal-md-12-c4">
                        <div className="modal-md-6-c4">
                            <div className="row_content_modal">
                                <div className="modal_header">
                                    <div className="modal_close_holder">
                                        <img src={img_elClose} alt="close" className="img_close type_a" onClick={this.closeModalAdjust1}/>
                                    </div>
                                    <div className="modal_header_text_holder ml20">
                                        <div className="content_modal_header">Preview Content</div>
                                        <div className="content_modal_sub_header">Adjust Content</div>
                                    </div>

                                </div>
                                <div className="modal_body_content4 ml27 mt20">
                                    <div className="modal_body_left">
                                        <div className="left_img_holder_content_4">
                                            <div className="labelText db_label_text">Media</div>
                                            <img src={img_crop} alt="img_crop" className="type_a" onClick={this.onClick_elModalCrop}/>
                                        </div>
                                        <div className="ac_4_1">
                                            
                                        {this.state.imgForCrop.map((value, index) => (
                                            <div key={index} className={this.state.imgActiveIcon===index?'img_holder_md_2 type_a activeImgCrop progressFull_img_icon':'img_holder_md_2 type_a'} onClick={(e) => this.onClick_elSetImgforCrop(e,value.imgcrop,index)}>
                                                <img src={value.imgcrop}/>
                                            </div>
                                        ))}
                                            
                                        </div>
                                        <div className="ac_4_2 mt23">
                                            <div className="left_img_holder_content_4">
                                                <div className="labelText db_label_text">Text 1</div>
                                                <div className="apply_text">Edit</div>
                                            </div>
                                            <div className="change_text_holder">
                                                
                                                {this.createCarousel()}
                                            </div>
                                            <div className="left_img_holder_content_4 mt29">
                                                <div className="labelText db_label_text">Text 2</div>
                                                <div className="apply_text">Edit</div>
                                            </div>
                                            <div className="change_text_holder">
                                                {this.createCarouselText()}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-md-6-c4-color-change">
                            <div className="left_content_4_2">
                                <div className="up_content_4_label_holder">
                                    <div className="left_img_holder_content_4">
                                        <img src={img_wa} className="db_content_2_text_icon" alt="img_wa"/>
                                        <span className="db_content_2_text_icon_name">Whatsapp</span>
                                    </div>
                                    <div className="msgtype_content_4 mt9">Direct Message</div>
                                </div>
                                <div className="middle_content_holder_content4 mt12">
                                    <img src={img_channel} alt="img_channel"/>
                                    <div className="middile_text_holder">
                                        <div className="bigSale">{this.state.whatsappTextHeader}</div>
                                        <div className="description_txt mt11">{this.state.descText}</div>
                                    </div>
                                </div>
                                <div className="no_audio_content_4 mt7">
                                    <img src={red_inf} alt="red_inf"/>
                                    <span className="no_audio_text_content_4 ml7">Audio not available</span>
                                </div>
                            </div>
                            <div className="contact_holder_c4 mt20">
                                <div className="send_contact">
                                    <img src={img_send_contacts} alt="img_send_contacts"/>
                                    <span className="send_contact_text_c4">Sending to all contacts</span>
                                </div>
                                <div className="est_text mt7">Est. Reach 12K</div>
                            </div>
                            <div className="contact_holder_c4 mt100">
                                <div className="apply_text mt14">Apply to Direct Messages</div>
                                <button className="button db_next_button" onClick={this.closeModalAdjust1}>SAVE</button>
                            </div>
                        </div>
                    </div>
                </Modal>


                <Modal
                isOpen={this.state.modalCropIsOpen}
                onRequestClose={this.closeModalCrop}
                style={{
                    overlay: {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 140, 255, 0.45)'
                    },
                    content : {
                    top                   : '50%',
                    left                  : '50%',
                    right                 : 'auto',
                    bottom                : 'auto',
                    marginRight           : '-50%',
                    transform             : 'translate(-50%, -50%)',
                    borderRadius          : '18px',
                    backgroundColor       : '#f2f7ff',
                    overflow              : 'hidden',
                    width                 : '736px',
                    
                    padding               :'27px'
                    }
                }}
                >
                    <div className="row_content_modal">
                        <div className="modal_header">
                            <div className="modal_close_holder">
                                <img src={img_elClose} alt="close" className="img_close type_a" onClick={this.closeModalCrop}/>
                            </div>
                            <div className="modal_header_text_holder ml20">
                                <div className="content_modal_header">Preview Content</div>
                                <div className="content_modal_sub_header">Adjust Content</div>
                            </div>
                        </div>
                        <div className="crop_img_holder_c4 mt14">
                            
                            <ReactCrop src={this.state.cropForimg} className="cropImgHeightWidth" crop={this.state.crop} onChange={this.onCropChange}/>
                            <button className="button db_next_button mt26">SAVE</button>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}