import React, { Component } from 'react';
import SideMenu from './sideMenu.js';
import img_loader from './images/BL-PreLoader.gif';
import denied from './images/screen/denied.svg';

export default class DeniedScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            overlay:'block',
            changeOpacity:1
        }}
    timeout2 = setInterval(() => {
        this.setState({
            overlay:'none',
            changeOpacity:0
        })
    }, 2000)
    createLoader()
    {
        return(
            <div className="overlayLoader" style={{display:this.state.overlay,opacity:this.state.changeOpacity}}>
                <img src={img_loader} className="loaderClass" alt="loader"/>
            </div>
        )
    }
    render() {
        return (
            <div id="not_found" className="commonWidthHeight" style={{backgroundColor:'#F7FAFF'}}>
                <SideMenu/>
                <div className="middlePanelOthers">
                    <div style={{overflow:'hidden'}}>
                        <img src={denied} alt="404bot" style={{padding: '140px 140px 0px 0px'}}/>
                    </div>
                </div>
                {this.createLoader()}
            </div>
        )
    }
}