import React, { Component } from 'react';
import SideMenu from './sideMenu.js';

import wa from './images/svgicons/whatsapp.svg';
import wc from './images/svgicons/wechat.svg';
import line from './images/svgicons/line.svg';
import sc from './images/svgicons/snapchat.svg';
import insta from './images/svgicons/instagram.svg';
import twitter from './images/svgicons/twitter.svg';
import viber from './images/svgicons/viber.svg';
import telegram from './images/svgicons/telegram.svg';
import sms from './images/svgicons/sms.svg';
import qq from './images/svgicons/qq.svg';
import pin from './images/svgicons/pinterest.svg';
import linkd from './images/svgicons/linkedin.svg';
import yt from './images/svgicons/youtube.svg';
import msg from './images/svgicons/messenger.svg';
import kik from './images/svgicons/kik.svg';
import fb from './images/svgicons/facebook.svg';
import rd from './images/svgicons/reddit.svg';
import angry from './images/em_angry.png';
import happy from './images/em_happy.png';
import smiley from './images/em_smiley.png';
import au from './images/au.png';
import {Auth} from "aws-amplify";
import axios from 'axios';
const jsonConfig = require("./Config.json");
export default class Content extends Component {

    constructor(props) {
        super(props);
        this.state={
            currentScreen:''
        }
    }
    componentDidMount()
    {
        this.getPageAccess();
    }
    async getPageAccess()
    {
        try {
            var user = await Auth.currentAuthenticatedUser();
            var getUserById = await axios.get(jsonConfig.rdsApi+'users/'+user.attributes.sub,{
                headers: {
                  'X-API-KEY': jsonConfig.ApiKey,
                  'Content-Type': 'application/json'
                }});
            if(getUserById===null || getUserById===undefined)
            {
                this.props.appActions.goToScreen('notfound',{ transitionId: 'fadeIn' });
            }
            else{
            if(getUserById.data.data[0].role.name==='System' || getUserById.data.data[0].role.name==='Admin')
            {
                //this.setState({currentScreen:'interaction'});
            }
            else{
                var resScreen = await axios.get(jsonConfig.rdsApi+'screenPermissionByRole/'+getUserById.data.data[0].roleId,{
                    headers: {
                      'X-API-KEY': jsonConfig.ApiKey,
                      'Content-Type': 'application/json'
                    }});
                console.log("RES",resScreen);
                if(resScreen.status===200)
                {
                    if(resScreen.data.data[5].status==='false')
                    {
                        this.props.appActions.goToScreen('notfound',{ transitionId: 'fadeIn' });
                    }
                }
            }
            }
            
        } catch (error) {
            this.props.appActions.goToScreen('notfound',{ transitionId: 'fadeIn' });
        }
    }
    onClick_elContent2= (ev) =>{
        this.props.history.push("/content2");
    }
    render() {
        return (
            <div id="Smart_Feed___Content" className="commonWidthHeight">
                <SideMenu />
                <div className="middlePanelOthers">
                <div id="HeaderOthers">
                    <div id="Interaction_Board">
                        <span>Marketing & Content</span>
                    </div>
                    <div className="pull-right">
                        <img src={au} alt="au"/>
                    </div>
                </div>
                
                <div id="Group_2_A1_Group_52">
                        <div className="col-md-12 margin-align_30 content_bg">
                            <div className="row">
                                <div className="col-md-3">
                                    <div id="ContentHeaderTxt">Sentiment Based</div>
                                </div>
                                <div className="col-md-3">
                                    <div id="ContentHeaderTxt2">Broadcast</div>
                                </div>
                                <div className="col-md-3">
                                    <div id="ContentHeaderTxt3">List Based</div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-9 margin-align_30">
                                <div className="row">
                                <div className="margin-align_30">
                                    <div className="divHeaderTxt">
                                        <div id="SentimentHeaderTxt">Select Channels</div>
                                        <p id="SentimentSubHeaderTxt">Choose the social media streams you'd want your content to flow</p>
                                    </div>
                                </div>
                                </div>
                                    <div className="row margin-align_30">
                                        <div className="col-md-2 text-center" style={{marginTop:'20px'}}>
                                            <div className="col-md-12 activeIconContent">
                                                <img src={wa} alt={wa}/>
                                                <p id="SentimentSubHeaderTxtContent">Whatsapp</p>
                                            </div>
                                        </div>
                                        <div className="col-md-2 text-center" style={{marginTop:'20px'}}>
                                            <div className="col-md-12 activeIconContent">
                                                <img src={wc} alt={wc}/>
                                                <p id="SentimentSubHeaderTxtContent">WeChat</p>
                                            </div>
                                        </div>
                                        <div className="col-md-2 text-center" style={{marginTop:'20px'}}>
                                            <div className="col-md-12 activeIconContent">
                                                <img src={line} alt={line}/>
                                                <p id="SentimentSubHeaderTxtContent">Line</p>
                                            </div>
                                        </div>
                                        <div className="col-md-2 text-center" style={{marginTop:'20px'}}>
                                            <div className="col-md-12 activeIconContent">
                                                <img src={sc} alt={sc}/>
                                                <p id="SentimentSubHeaderTxtContent">SnapChat</p>
                                            </div>
                                        </div>
                                        <div className="col-md-2 text-center" style={{marginTop:'20px'}}>
                                            <div className="col-md-12">
                                                <img src={insta} alt={insta}/>
                                                <p id="SentimentSubHeaderTxtContent">Instagram</p>
                                            </div>
                                        </div>
                                        <div className="col-md-2 text-center" style={{marginTop:'20px'}}>
                                            <div className="col-md-12">
                                                <img src={twitter} alt={twitter}/>
                                                <p id="SentimentSubHeaderTxtContent">Twitter</p>
                                            </div>
                                        </div>
                                        <div className="col-md-2 text-center" style={{marginTop:'20px'}}>
                                            <div className="col-md-12">
                                                <img src={viber} alt={viber}/>
                                                <p id="SentimentSubHeaderTxtContent">Viber</p>
                                            </div>
                                        </div>
                                        <div className="col-md-2 text-center" style={{marginTop:'20px'}}>
                                            <div className="col-md-12">
                                                <img src={telegram} alt={telegram}/>
                                                <p id="SentimentSubHeaderTxtContent">Telegram</p>
                                            </div>
                                        </div>
                                        <div className="col-md-2 text-center" style={{marginTop:'20px'}}>
                                            <div className="col-md-12">
                                                <img src={sms} alt={sms}/>
                                                <p id="SentimentSubHeaderTxtContent">SMS</p>
                                            </div>
                                        </div>
                                        <div className="col-md-2 text-center" style={{marginTop:'20px'}}>
                                            <div className="col-md-12">
                                                <img src={qq} alt={qq}/>
                                                <p id="SentimentSubHeaderTxtContent">QQ</p>
                                            </div>
                                        </div>
                                        <div className="col-md-2 text-center" style={{marginTop:'20px'}}>
                                            <div className="col-md-12 activeIconContent">
                                                <img src={pin} alt={pin}/>
                                                <p id="SentimentSubHeaderTxtContent">Pinterest</p>
                                            </div>
                                        </div>
                                        <div className="col-md-2 text-center" style={{marginTop:'20px'}}>
                                            <div className="col-md-12 activeIconContent">
                                                <img src={linkd} alt={linkd}/>
                                                <p id="SentimentSubHeaderTxtContent">Linkedin</p>
                                            </div>
                                        </div>
                                        <div className="col-md-2 text-center" style={{marginTop:'20px'}}>
                                            <div className="col-md-12 activeIconContent">
                                                <img src={yt} alt={yt}/>
                                                <p id="SentimentSubHeaderTxtContent">Youtube</p>
                                            </div>
                                        </div>
                                        <div className="col-md-2 text-center" style={{marginTop:'20px'}}>
                                            <div className="col-md-12">
                                                <img src={msg} alt={msg}/>
                                                <p id="SentimentSubHeaderTxtContent">Messenger</p>
                                            </div>
                                        </div>
                                        <div className="col-md-2 text-center" style={{marginTop:'20px'}}>
                                            <div className="col-md-12 activeIconContent">
                                                <img src={kik} alt={kik}/>
                                                <p id="SentimentSubHeaderTxtContent">Kik</p>
                                            </div>
                                        </div>
                                        <div className="col-md-2 text-center" style={{marginTop:'20px'}}>
                                            <div className="col-md-12">
                                                <img src={fb} alt={fb}/>
                                                <p id="SentimentSubHeaderTxtContent">Facebook</p>
                                            </div>
                                        </div>
                                        <div className="col-md-2 text-center" style={{marginTop:'20px'}}>
                                            <div className="col-md-12">
                                                <img src={rd} alt={rd}/>
                                                <p id="SentimentSubHeaderTxtContent">Reddit</p>
                                            </div>
                                        </div>
                                        
                                    </div>
                                
                            </div>
                                <div className="col-md-3 margin-align_30 text-center border-left">
                                    <div className="row">
                                        <div className="margin-align_30">
                                            <div className="divHeaderTxt">
                                                <div id="SentimentHeaderTxt">Sentiments</div>
                                                <p id="SentimentSubHeaderTxt">Choose the emotions</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 margin-align_30">
                                        <img src={angry} className="img_emoji" alt="angry"/>
                                        <p className="percent_Result_2">Angry</p>
                                    </div>
                                    <div className="col-md-12 margin-align_30">
                                        <img src={happy} className="img_emoji" alt="happy"/>
                                        <p className="percent_Result_2">Disguasted</p>
                                    </div>
                                    <div className="col-md-12 margin-align_30">
                                        <img src={smiley} className="img_emoji" alt="smiley"/>
                                        <p className="percent_Result_2">Happy</p>
                                    </div>
                                    <div className="col-md-12 margin-align_30">
                                        <button className="button btn_content" onClick={this.onClick_elContent2}>Next Step</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                </div>
                </div>
            </div>
        )
    }
}