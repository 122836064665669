import React, { Component } from 'react';
import './css/guided_demo.css';

import Img_appMockUP from './images/guidedDemo/appMockup-1.svg';
import Img_appMockUP2 from './images/guidedDemo/appMockup-2.svg';
import Img_appMockUP3 from './images/guidedDemo/appMockup-3.svg';
import Img_appMockUP4 from './images/guidedDemo/appMockup-4.svg';

import Img_prev from './images/guidedDemo/leftArrow.svg';
import Img_next from './images/guidedDemo/rightArrow.svg';
import Img_Bl from './images/guidedDemo/BL-Horizontal.svg';
import img_loader from './images/BL-PreLoader.gif';

import axios from 'axios';
import {Auth} from "aws-amplify";
var AWS = require("aws-sdk");
const jsonConfig = require("./Config.json");
var wc = require('which-country');
export default class GuidedDemo extends Component {
    constructor(props) {
        super(props);
        this.state = {
          slideCount: 1,
          guidedDemoVal:false,
          overlay:'none',
          changeOpacity:0,
          defaultCognitoId:'',
          countryCode:''

        };
        this.myElement = null;
        this.myTween = null;
        
        this.onClick_elNext = this.onClick_elNext.bind(this);
        this.onClick_elPrevious = this.onClick_elPrevious.bind(this);
        this.showPosition = this.showPosition.bind(this);
        
      }
    componentDidMount()
    {
        document.body.style.backgroundColor = "#13A5FF";

        AWS.config.update({
            credentials: new AWS.CognitoIdentityCredentials({
              IdentityPoolId: jsonConfig.awsConfigCredentials.IdentityPoolId,
              RoleArn: jsonConfig.awsConfigCredentials.RoleArn,
            }),
            region: jsonConfig.awsConfigRegion,
          });
          
          this.getUserDetails();
          this.getLocation();
    }
    getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.showPosition);
      } else {
        console.log("BROWSER NOT SUPPORTED");
      }
    }
    
    showPosition(position) {
      //var getCountryCode=wc([151,-33]);
      var getCountryCode=wc([position.coords.longitude,position.coords.latitude ]);
      this.setState({countryCode:getCountryCode})
    }
    async getUserDetails()
    {
      var user = await Auth.currentAuthenticatedUser();
      this.setState({defaultCognitoId:user.username});
      this.getTourSettings();
    }
    getTourSettings()
    {
      var profileSettingsTable = "profileSettings";
      var docClient = new AWS.DynamoDB.DocumentClient();
        var params = {
          TableName : profileSettingsTable,
          FilterExpression : "cognitoId = :cognitoid",
          ExpressionAttributeValues: {
              ":cognitoid": this.state.defaultCognitoId
          }
      };
      docClient.scan(params, (err, data)=> {
        if (err) {
            console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
            this.setState({guidedDemoVal:false}) 
        } else {
          if(data.Items.length>0)
          {
            this.setState({guidedDemoVal:data.Items[0].tourSettings})
          }
          else{
            this.setState({guidedDemoVal:false})
          }
        }
      });
    }
  getPageAccess()
	{
        axios.get(jsonConfig.rdsApi+'users/'+this.state.defaultCognitoId,{
                headers: {
                'X-API-KEY': jsonConfig.ApiKey,
                'Content-Type': 'application/json'
                }}).then((response) => {
                if(response.data.data[0].organizationId===null || response.data.data[0].organizationId===undefined)
                {
                    this.props.history.push("/access-denied");
                }
                else{
                    if(response.data.data[0].role.name==='System' || response.data.data[0].role.name==='Admin')
                    {
                        this.props.history.push("/interaction");
                    }
                    else{
                        axios.get(jsonConfig.rdsApi+'screenPermissionByRole/'+response.data.data[0].roleId,{
                            headers: {
                            'X-API-KEY': jsonConfig.ApiKey,
                            'Content-Type': 'application/json'
                            }}).then((result) => {
                                if(result.data.data[3].status==='true')
                                {
                                    this.props.history.push("/interaction");
                                }
                                else{
                                    this.props.history.push("/access-denied");
                                }
                            },(error) => {
                                this.props.history.push("/access-denied");
                            });
                    }
                    
                }
            },(error) => {
                this.props.history.push("/access-denied");
            });
    }
    createLoader()
    {
        return(
        <div className="overlayLoader" style={{display:this.state.overlay,opacity:this.state.changeOpacity}}>
          <img src={img_loader} className="loaderClass" alt="loader"/>
        </div>
        )
    }
    onClick_elNext()
    {
        if(this.state.slideCount<4)
        {
            this.setState({slideCount:this.state.slideCount+1})
        }
        else{
            this.setState({slideCount:1})
            this.props.history.push("/interaction");
        }
        
    }
    onClick_elPrevious()
    {
        if(this.state.slideCount>1)
        {
            this.setState({slideCount:this.state.slideCount-1})
        }
        else{
            this.setState({slideCount:4})
        }
        
    }
    onClick_elInteraction= (ev) =>{
		  this.getPageAccess();
    }
   
    onOffGuideddemo = event =>{
      var profileSettingsTable = "profileSettings";
      var docClient = new AWS.DynamoDB.DocumentClient();

      this.setState({guidedDemoVal:event.target.checked})

      var profileSettingsParams = {
        TableName:profileSettingsTable,
        Key:{
          "cognitoId": this.state.defaultCognitoId,
        },
        "UpdateExpression": "set tourSettings = :val1",
        "ExpressionAttributeValues": {
          ":val1": event.target.checked,
        },
      };
      docClient.update(profileSettingsParams,(err, data)=> {
        if (err) {
          console.error("Unable to update item. Error JSON:", JSON.stringify(err, null, 2));
        } else {
          console.log("UpdateItem succeeded:", JSON.stringify(data, null, 2));
        }
      });


    }
    onClick_elDetailTour=(ev)=>
    {
      if(this.state.countryCode==='AUS')
      {
        window.open('https://blueleap.com.au/demo', "_blank")
      }
      else{
        window.open('https://blueleap.com/demo', "_blank")
      }
    }
render() {
    
    
return (
<div id="Live_monitoring" className="commonWidthHeightForGuided">
    <div className="demoBody">
      
    <div className="appMockup" id="appMockup1" style={{display:this.state.slideCount===1?'block':'none'}}>
          <img src={Img_appMockUP} className="mockup" alt=""/>
          <div className="sliderGuided">
            <div className="sliderIndi">
                <div className="indi active"></div>
                <div className="indi"></div>
                <div className="indi"></div>
                <div className="indi"></div>
            </div>
          </div>
          <div className="toolTip toolTip1">
            <h3>Main Menu</h3>
            <p>This is where most of the functions of this app can be accessed from. Every time you feel the need to navigate to any function of this app, you can click on it here based on your requirements.</p>
          </div>
        </div>

        <div className="appMockup" id="appMockup2" style={{display:this.state.slideCount===2?'block':'none'}}>
            <img src={Img_appMockUP2} className="mockup" alt=""/>
            <div className="sliderGuided">
                <div className="sliderIndi">
                    <div className="indi"></div>
                    <div className="indi active"></div>
                    <div className="indi"></div>
                    <div className="indi"></div>
                </div>
            </div>
                <div className="toolTip toolTip2">
                    <h3>Inventory</h3>
                    <p>This is where all your channels, AI services & CRMs are present to make a connection. You’ll need to see if they’re properly setup, the ones that are yet to be setup or not available are also here but they will be Inactive until they’re available for use. Once your inventory is ready, you can double-click and add them to the interaction board above.</p>
                </div>
            </div>

            <div className="appMockup" id="appMockup3" style={{display:this.state.slideCount===3?'block':'none'}}>
            <img src={Img_appMockUP3} className="mockup" alt=""/>
            <div className="sliderGuided">
                <div className="sliderIndi">
                    <div className="indi"></div>
                    <div className="indi"></div>
                    <div className="indi active"></div>
                    <div className="indi"></div>
                </div>
            </div>
                <div className="toolTip toolTip3">
                    <h3>Interaction Board</h3>
                    <p>This is where all the interactions happen in real-time (virtually) and you can see how your data will flow from one end to another. You can edit your connections, you can create multiple interaction boards or delete as you need. Essentially, you can make changes, save changes & manage your interactions here.</p>
                </div>
            </div>

            <div className="appMockup" id="appMockup4" style={{display:this.state.slideCount===4?'block':'none'}}>
            <img src={Img_appMockUP4} className="mockup" alt=""/>
            <div className="sliderGuided">
                <div className="sliderIndi">
                    <div className="indi"></div>
                    <div className="indi"></div>
                    <div className="indi"></div>
                    <div className="indi active"></div>
                </div>
            </div>
                <div className="toolTip toolTip4">
                    <h3>Properties Panel</h3>
                    <p>Based on your selection from your inventory or your interaction board, you will be able to see all the properties, parameters and variables associated with your selection. Essentially, this is where you<br/>get to see all the details<br/>of everything you’re<br/>interacting with.</p>
                </div>
            </div>
      
      <div className="section3">
            
              <div className="controlTray">
                <div className="controls">
                  <div className="block1">
                    <p>Visit our Demo Website &</p>
                    <button type="primary" className="button1 type_a" name="CTA" onClick={this.onClick_elDetailTour} >TAKE A DETAILED TOUR</button>
                  </div>
                  <div className="logo">
                    <img src={Img_prev} className="previousButton type_a" alt="" onClick={this.onClick_elPrevious}/>
                    <img src={Img_Bl} className="blLogo "alt=""/>
                    <img src={Img_next} className="nextButton type_a" alt="" onClick={this.onClick_elNext}/>
                    
                  </div>
                  <div className="block3">
                    <button type="secondary" className="button_secondary type_a" name="button" onClick={this.onClick_elInteraction}>SKIP</button>
                    <div className="input">
                      <input type="checkbox" 
                      className="checkbox" 
                      checked={this.state.guidedDemoVal} 
                      style={{marginRight:'10px'}}
                      onChange={this.onOffGuideddemo}
                      />
                      <p>Don't Show this again</p>
                    </div>
                  </div>
                </div>
              </div>
            
        </div>

    </div>
    </div>
        )
    }
}