import React, { Component } from 'react';
import SideMenu from './sideMenu.js';


import img_banner1 from './images/screen/pbb_banner.svg';
import img_banner2 from './images/screen/pbb_banner2.svg';


import img_Add from './images/buttons/add.svg';
import img_elClose from './images/close.svg';
import img_elBack from './images/screen/pbb_back.svg';
import boardDetails from './images/screen/boardDetails.svg';
import img_save from './images/buttons/save.svg';
import img_remove_disable from './images/buttons/remove_disable.svg';
import img_new_disable from './images/buttons/new_disable.svg';
import {NotificationContainer, NotificationManager} from 'react-notifications';

import {Auth} from "aws-amplify";
import axios from 'axios';
var AWS = require("aws-sdk");
const jsonConfig = require("./Config.json");

export default class PreBuiltBot extends Component {
    constructor(props) {
        super(props);
        this.state = {

        defaultOrgName:'',
        defaultOrgId:'',
        defaultUserName:'',
        defaultRoleName:'',
        defaultCognitoId:'',

        overlay:'none',
        changeOpacity:0,
        slideIndex:0,
        bannerTextColor:'#FFF',
        changeBorderColor:'',
        modalDisplay:'none',
        boardName:'',
        interactionDetails:[],
        headerText:'Pre-Built Boards',
        displayBoardImg:'none',
        displaysliderImg:'block',
        staffDisplay:'flex',
        descDisplay:'none',
        backButton:'none',
        pbb_board_list_length:0,
        showAllList:4,
        activeInteractions:[],
        modalDisplaydesc:'none',
        editBoardNamefromDesc:'',
        pbb_board_list:[],
        longDesc:'',
        benifits:'',

        staffpicksButtonStatus:false,
        zendeskButtonStatus:false,
        salesforceButtonStatus:false,
        saButtonStatus:false,
        chatButtonStatus:false,
        msngrButtonStatus:false,
        }
        this.updateToInteraction = this.updateToInteraction.bind(this);
        
      }
    componentDidMount()
    {
          AWS.config.update({
            credentials: new AWS.CognitoIdentityCredentials({
              IdentityPoolId: jsonConfig.awsConfigCredentials.IdentityPoolId,
              RoleArn: jsonConfig.awsConfigCredentials.RoleArn,
            }),
            region: jsonConfig.awsConfigRegion,
          });
          this.getuserDetails();
          this.showSlides();
          this.getPbbData();
    }
    async getuserDetails()
    {
        var user = await Auth.currentAuthenticatedUser();
        var getUserById = await axios.get(jsonConfig.rdsApi+"users/"+user.attributes.sub,{
          headers: {
            'X-API-KEY': jsonConfig.ApiKey,
            'Content-Type': 'application/json'
          }});

        this.setState({
          defaultOrgName:getUserById.data.data[0].organization.name,
          defaultOrgId:getUserById.data.data[0].organizationId,
          defaultUserName:user.attributes.name,
          defaultRoleName:getUserById.data.data[0].role.name,
          defaultCognitoId:user.username,
        })
    }
getPbbData()
  {
    
    var pbbTable = "prebuiltBot";
    var docClient = new AWS.DynamoDB.DocumentClient();
      var params = {
        TableName : pbbTable,
        FilterExpression : "boardStatus = :val1",
        ExpressionAttributeValues: {
            ":val1": 'Active'
        }
    };
    docClient.scan(params, (err, data)=> {
        if (err) {
            
            console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
            
        } else {
            this.setState({
                pbb_board_list:data.Items,
                pbb_board_list_length:data.Items.length,
            });
        }
    });
  }
  filterPbbCategory=(category)=>
  {
    if(category==='staffPicks')
    {
        this.setState({
            staffpicksButtonStatus:true,
            zendeskButtonStatus:false,
            salesforceButtonStatus:false,
            saButtonStatus:false,
            chatButtonStatus:false,
            msngrButtonStatus:false,
        });
    }
    else if(category==='zendesk')
    {
        this.setState({
            staffpicksButtonStatus:false,
            zendeskButtonStatus:true,
            salesforceButtonStatus:false,
            saButtonStatus:false,
            chatButtonStatus:false,
            msngrButtonStatus:false,
        });
    }
    else if(category==='salesforce')
    {
        this.setState({
            staffpicksButtonStatus:false,
            zendeskButtonStatus:false,
            salesforceButtonStatus:true,
            saButtonStatus:false,
            chatButtonStatus:false,
            msngrButtonStatus:false,
        });
    }
    else if(category==='sentiment')
    {
        this.setState({
            staffpicksButtonStatus:false,
            zendeskButtonStatus:false,
            salesforceButtonStatus:false,
            saButtonStatus:true,
            chatButtonStatus:false,
            msngrButtonStatus:false,
        });
    }
    else if(category==='chat')
    {
        this.setState({
            staffpicksButtonStatus:false,
            zendeskButtonStatus:false,
            salesforceButtonStatus:false,
            saButtonStatus:false,
            chatButtonStatus:true,
            msngrButtonStatus:false,
        });
    }
    else if(category==='messenger')
    {
        this.setState({
            staffpicksButtonStatus:false,
            zendeskButtonStatus:false,
            salesforceButtonStatus:false,
            saButtonStatus:false,
            chatButtonStatus:false,
            msngrButtonStatus:true,
        });
    }
    else
    {
        this.setState({
            staffpicksButtonStatus:false,
            zendeskButtonStatus:false,
            salesforceButtonStatus:false,
            saButtonStatus:false,
            chatButtonStatus:false,
            msngrButtonStatus:false,
        });
    }
    var pbbTable = "prebuiltBot";
    var docClient = new AWS.DynamoDB.DocumentClient();
      var params = {
        TableName : pbbTable,
        FilterExpression : category+" = :val1",
        ExpressionAttributeValues: {
            ":val1": 'Y'
        }
    };
    docClient.scan(params, (err, data)=> {
        if (err) {
            console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
            
        } else {
            if(data.Items.length>0)
            {
                this.setState({pbb_board_list:data.Items});
            }
            else{
                this.setState({pbb_board_list:[]});
            }
        }
    });
  }
showSlides=()=>
{
        var tempIndex=this.state.slideIndex;
        var i;
        var slides = document.getElementsByClassName("pbb_img_banner");
        if(slides.length>0)
        {
        for (i = 0; i < slides.length; i++) {
          slides[i].style.display = "none";  
        }
        tempIndex++;
        if(tempIndex===2)
        {
            this.setState({
                bannerTextColor:'#24527E',
                changeBorderColor:'changeBorder'
            });
        }
        else{
            this.setState({
                bannerTextColor:'#FFF',
                changeBorderColor:''
            })
        }
        if (tempIndex > slides.length) 
        {
            tempIndex=1;
        }
        slides[tempIndex-1].style.display = "block";  
        this.setState({slideIndex:tempIndex})
        setTimeout(this.showSlides, 5000); // Change image every 2 seconds
    }
}
changeBoardStat(boardname,boardData)
{
    var tempInteraction=JSON.parse(boardData);
    for(var p=0;p<tempInteraction.length;p++)
    {
        tempInteraction[p]["key"]=this.state.defaultOrgId;
    }
        this.setState({
            modalDisplay:'flex',
            boardName:boardname,
            interactionDetails:tempInteraction
        })
}
closeModal=()=> {
    this.setState({modalDisplay: 'none'});
  }
  boardNameHandler= event => {
    this.setState({
        boardName: event.target.value
      });
  }
  updateToInteraction()
  {
    if(this.state.boardName==='')
    {
        NotificationManager.error('Please enter the board name','Error');
    }
    else{
        var tempInteraction=this.state.interactionDetails;
        var tempBoardName=this.state.boardName;  
        var boardid=Math.floor((Math.random()*1000000000000000000)+1);
        var docClient = new AWS.DynamoDB.DocumentClient();
        var interactionBoardTable = "interactions";

        var saveParams = {
        TableName:interactionBoardTable,
        Item:{
            interactionId:boardid.toString(),
            cognitoid: this.state.defaultCognitoId,
            interactionName:tempBoardName,
            boardStatus:'Active',
            json: btoa(JSON.stringify(tempInteraction)),
            createdAt:new Date().toString(),
            updatedAt:new Date().toString(),
            organizationId:this.state.defaultOrgId,
            organizationRole:this.state.defaultRoleName,
            createdBy:this.state.defaultUserName,
            updatedBy:this.state.defaultUserName,
        }
        };

        docClient.put(saveParams, (err, data) => {
            if (err) {
            //console.error("Unable to add board. Error JSON:", JSON.stringify(err, null, 2));
            NotificationManager.error('Failed to board add','Error');
            } else {
            NotificationManager.success('Board added successfully','Success');
            this.closeModal();
            }
        });
    }
    
  }
  getInteractionDetails=(boardname,boardJson,longDesc,benifits)=>{
    var tempInteraction=JSON.parse(boardJson);
    for(var p=0;p<tempInteraction.length;p++)
    {
        tempInteraction[p]["key"]=this.state.defaultOrgId;
    }
      this.setState({
        headerText:boardname,
        displayBoardImg:'block',
        displaysliderImg:'none',
        staffDisplay:'none',
        descDisplay:'block',
        backButton:'block',
        activeInteractions:tempInteraction,
        editBoardNamefromDesc:boardname,
        longDesc:longDesc,
        benifits:benifits,
      })
  }
  backToPrev=()=>{
    this.setState({
        headerText:'Pre-Built Boards',
        displayBoardImg:'none',
        displaysliderImg:'block',
        staffDisplay:'flex',
        descDisplay:'none',
        backButton:'none'
      })
  }
  showAll=()=>
  {
    if(this.state.staffpicksButtonStatus===false && this.state.zendeskButtonStatus===false
        && this.state.salesforceButtonStatus===false && this.state.saButtonStatus===false
        && this.state.chatButtonStatus===false && this.state.msngrButtonStatus===false
    )
    {
        var tempList=this.state.pbb_board_list_length;
        if(tempList<=4)
        {
        this.setState({showAllList:4})
        }
        else{
        this.setState({showAllList:tempList})
        }
    }
    else{
        this.setState({
            staffpicksButtonStatus:false,
            zendeskButtonStatus:false,
            salesforceButtonStatus:false,
            saButtonStatus:false,
            chatButtonStatus:false,
            msngrButtonStatus:false,
        })
        this.getPbbData();
    }
      
      
  }
  openPopupDesc=()=>{
      this.setState({modalDisplaydesc:'block'})
  }
  closeModalDesc=()=>{
    this.setState({modalDisplaydesc:'none'})
  }
  boardNameHandlerDesc= event => {
    this.setState({
        editBoardNamefromDesc: event.target.value
      });
  }
  updateToInteractionDetails()
  {
    if(this.state.editBoardNamefromDesc==='')
    {
        NotificationManager.error('Please enter the board name','Error');
    }
    else{
        var tempInteraction=this.state.activeInteractions;
        var tempBoardName=this.state.editBoardNamefromDesc;  
        var boardid=Math.floor((Math.random()*1000000000000000000)+1);
        var docClient = new AWS.DynamoDB.DocumentClient();
        var interactionBoardTable = "interactions";

        var saveParams = {
        TableName:interactionBoardTable,
        Item:{
            interactionId:boardid.toString(),
            cognitoid: this.state.defaultCognitoId,
            interactionName:tempBoardName,
            boardStatus:'Active',
            json: btoa(JSON.stringify(tempInteraction)),
            createdAt:new Date().toString(),
            updatedAt:new Date().toString(),
            organizationId:this.state.defaultOrgId,
            organizationRole:this.state.defaultRoleName,
            createdBy:this.state.defaultUserName,
            updatedBy:this.state.defaultUserName,
        }
        };

        docClient.put(saveParams, (err, data) => {
            if (err) {
            //console.error("Unable to add board. Error JSON:", JSON.stringify(err, null, 2));
            NotificationManager.error('Failed to board add','Error');
            } else {
            NotificationManager.success('Board added successfully','Success');
            this.closeModalDesc();
            }
        });
    }
    
  }
    render() {
        
        return (
            <div id="Live_monitoring" className="commonWidthHeight">
                <SideMenu/>
                <div className="middlePanelOthers">
                    <div id="HeaderOthers" style={{position:'relative'}}>
                        <div id="Interaction_Board" className="w-500">
                            <img src={img_elBack} className="pbb_back_img type_a" alt="back" style={{display:this.state.backButton}} onClick={(e) => this.backToPrev()}/>
                            <span className="pull-left">{this.state.headerText}</span>
                            
                        </div>
                    </div>
                
                    <div id="Group_2_A1_Group_47" style={{marginTop:'29px'}}>
                        <div className="pbb_intractionDetails" style={{display:this.state.displayBoardImg}}>
                            <div id="Workflow_pbb">

                            <img src={img_save} className="img_button_updt type_a" onClick={(e) => this.openPopupDesc()} alt="save"/>
                            <img src={img_remove_disable} className="img_button_rmv type_a" alt="remove"/>
                            <img src={img_new_disable} className="img_button_new_disable type_a" alt="update"/>

                                <div id="workflow_illustration_v5_pbb">
                                        {this.state.activeInteractions.length===3 ? (
                                            this.state.activeInteractions.map((value, key) => (
                                                <div key={key} onClick={(e) => this.activeMake(e,key,value.name)}>
                                                    <div className={`icon${key}`}>
                                                        <div className={`arrow${key}`}/>
                                                    </div>
                                                    <div className={`item${key}`}>
                                            <div className={`iconName${key} `+value.extraClassforIconName}>
                                                <div className={`TextLeftBorder${key}`}></div>
                                                <div className={`TextRightBorder${key}`}></div>
                                                {value.displayName}
                                            </div>
                                            <div className={this.state.activeIndex === key ? 'activeState': 'hoverState'}></div> 
                                                        <img className={'divServices '+value.extraClass} src={value.icon} alt={this.state.activeInteractions.length} />
                                                    </div>
                                                </div>
                                            ))
                                        ):(
                                            this.state.activeInteractions.map((value, key) => (
                                                <div key={key} onClick={(e) => this.activeMake(e,key,value.name)}>
                                                    <div className={`icon${key}_${key}`}>
                                                        <div className={`arrow${key}_${key}`}/>
                                                    </div>
                                                    <div className={`item${key}_${key}`}>
                                            <div className={`iconName${key}_${key} `+value.extraClassforIconName}>
                                                <div className={`TextLeftBorder${key}_${key}`}></div>
                                                <div className={`TextRightBorder${key}_${key}`}></div>
                                                {value.displayName}
                                            </div>
                                            <div className={this.state.activeIndex === key ? 'activeState': 'hoverState'}></div>
                                                        <img  className={'divServices '+value.extraClass} src={value.icon} alt={this.state.activeInteractions.length} />
                                                    </div>
                                                </div>
                                            ))
                                        )}
                                </div>
                            </div>
                                <div id="myModal" className="modalBoardDesc" style={{display:this.state.modalDisplaydesc}}>
                                    <div id="boxShadow" className="modal_content_board_Desc">
                                        <div className="type_a close_img" onClick={this.closeModalDesc} style={{color:'#82C7FF'}}>
                                            <img src ={img_elClose} alt="close" style={{height:'14px',width:'14px'}}/>
                                        </div>
                                        <div className="mt20" style={{overflow:'hidden'}}>
                                            <div className="divHeaderTxt">
                                                <div id="modalSubHeaderTxtContent">Edit Board Name</div>
                                            </div>
                                        </div>  
                                        <div className="mt16 ml25">
                                            <textarea 
                                            type="text" 
                                            className="txtType bordernWidthforPop" 
                                            placeholder="New Board Name"
                                            value={this.state.editBoardNamefromDesc}
                                            onChange={this.boardNameHandlerDesc}
                                            rows="2"
                                            cols="2"
                                            />
                                        </div>
                                        
                                        <div className="mt16 ml25 mb15">
                                            <button className="button" onClick={(e) => this.updateToInteractionDetails()}>CREATE</button>
                                        </div>
                                    </div>
                                </div>
                        </div>
                        <div className="slideshow-container" style={{display:this.state.displaysliderImg}}>

                                <div className="mySlides">
                                    <div className="pbb_banner_text" style={{color:this.state.bannerTextColor}}>
                                        Every Business is Unique.
                                        Find the board 
                                        that’s right for you.
                                    </div>
                                    <img className="pbb_img_banner fade" src={img_banner1} alt="1"/>
                                    <img className="pbb_img_banner fade" src={img_banner2} alt="2"/>
                                    <div className="pbb_category">
                                        <button className={this.state.changeBorderColor+" button btn_pbb_category"} onClick={(e) => this.filterPbbCategory('staffPicks')} disabled={this.state.staffpicksButtonStatus}>Staff Picks</button>
                                        <button className={this.state.changeBorderColor+" button btn_pbb_category"} onClick={(e) => this.filterPbbCategory('zendesk')} disabled={this.state.zendeskButtonStatus}>Zendesk</button>
                                        <button className={this.state.changeBorderColor+" button btn_pbb_category"} onClick={(e) => this.filterPbbCategory('salesforce')} disabled={this.state.salesforceButtonStatus}>Salesforce</button>
                                        <button className={this.state.changeBorderColor+" button btn_pbb_category"} onClick={(e) => this.filterPbbCategory('sentiment')} disabled={this.state.saButtonStatus}>Sentiment Analysis</button>
                                        <button className={this.state.changeBorderColor+" button btn_pbb_category"} onClick={(e) => this.filterPbbCategory('chat')} disabled={this.state.chatButtonStatus}>Chat</button>
                                        <button className={this.state.changeBorderColor+" button btn_pbb_category"} onClick={(e) => this.filterPbbCategory('messenger')} disabled={this.state.msngrButtonStatus}>Messenger</button>
                                    </div>
                                </div>

                                
                        </div>
                        <div style={{overflow:'hidden',padding:'0px 40px 0px 40px',position:'relative',display:this.state.descDisplay}}>
                            <div className="pbb_board_description">
                                <div className="mt12">
                                    <p className="pbb_board_heading fs18">Description</p>
                                    <p className="pbb_board_text">{this.state.longDesc}</p>
                                </div>
                            </div>
                            <div className="pbb_board_benifits">
                                <div className="mt12">
                                    <p className="pbb_board_heading fs18">Benefits</p>
                                    <p className="pbb_board_text" style={{whiteSpace:'pre-wrap'}}>{this.state.benifits}</p>
                                </div>
                            </div>
                        </div>
                        <div style={{display:'flex',flexWrap:'wrap',position:'relative',display:this.state.staffDisplay}}>
                            <div style={{display:'flex',flexWrap:'wrap',justifyContent: 'space-between',width:'100%',padding:'0px 36px 0px 36px'}}>
                                <div className="pull-left" style={{marginTop:'6px'}}>
                                    <span className="labelSubText lh16">Staff Picks</span>
                                </div>
                                <div className="pull-right">
                                    <span className="ml10 fs12 clBlue type_a" onClick={this.showAll}>Show all</span>
                                </div>
                            </div>
                        <div className="" style={{display:'flex',flexWrap:'wrap',height:'431px',overflowY:'auto',padding:'0px 36px 30px 36px'}}>
                            <div className="pbb_row">
                                {this.state.pbb_board_list.slice(0, this.state.showAllList).map((value, key) => (
                                    <div className={key===0 || key===4?'pbb_board_details mt12':'pbb_board_details ml16 mt12'} key={key}>
                                        <div className="img_bordered_pbb">
                                            <img src={value.thumbnailUrl} alt={key}/>
                                        </div>
                                        <div className="boardImgAdd type_a" onClick={(e) => this.changeBoardStat(value.boardName,value.boardJson)}>
                                            <img className="addImgSize" src={img_Add} alt="add1"/>
                                        </div>
                                        <div className="mt12">
                                            <p className="pbb_board_heading type_a" onClick={(e) => this.getInteractionDetails(value.boardName,value.boardJson,value.longDesc,value.benifits)}>{value.boardName}</p>
                                            <p className="pbb_board_text">{value.shortDesc}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>   
                                <div id="myModal" className="modalPpbBoard" style={{display:this.state.modalDisplay}}>
                                    <div id="boxShadow" className="modal_content_board">
                                        <div className="type_a close_img" onClick={this.closeModal} style={{color:'#82C7FF'}}>
                                            <img src ={img_elClose} alt="close" style={{height:'14px',width:'14px'}}/>
                                        </div>
                                        <div className="mt20" style={{overflow:'hidden'}}>
                                            <div className="divHeaderTxt">
                                                <div id="modalSubHeaderTxtContent">Edit Board Name</div>
                                            </div>
                                        </div>  
                                        <div className="mt16 ml25">
                                            <textarea 
                                            type="text" 
                                            className="txtType bordernWidthforPop"
                                            value={this.state.boardName}
                                            onChange={this.boardNameHandler}
                                            rows="2"
                                            cols="2"
                                            />
                                        </div>
                                        
                                        <div className="mt16 ml25 mb15">
                                            <button className="button" onClick={(e) => this.updateToInteraction()}>UPDATE</button>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
                <NotificationContainer/>
            </div>
        )
    }
}