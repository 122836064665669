import React, { Component } from 'react';
import SideMenu from './sideMenu.js';

import pannel3_1 from './images/screen/pannel3_1.png';
import discover1 from './images/screen/discover1.png';
import discover2 from './images/screen/discover2.png';

export default class Trending extends Component {

    
      
    render() {
        return (
            <div id="Smart_Feed___Trending" className="commonWidthHeight">
                <SideMenu />
                <div className="middlePanel">
                    <div id="Header">
                        <div id="Interaction_Board">
                            <span>Discover</span>
                        </div>
                    </div>
                    <div id="WorkflowDiscover">
                        <div className="tabset_discover">
                            <input type="radio" name="tabset" id="tab1" aria-controls="profiles" defaultChecked/>
                            <label htmlFor="tab1" className="tabHeading tabDeactive_discover" style={{marginLeft:'-31px'}}>Profiles</label>

                            <input type="radio" name="tabset" id="tab2" aria-controls="posts"/>
                            <label htmlFor="tab2" className="tabHeading tabDeactive_discover">Posts</label>

                            <div className="tab-panels hideTab_discover" style={{backgroundColor:'#F8FBFF',padding:'11px'}}>
                                <section id="profiles" className="tab-panel-discover">
                                    <div>
                                        <img src={discover1} alt="discover1" style={{width:'737px'}}/>
                                    </div>
                                </section>
                                <section id="posts" className="tab-panel-discover">
                                    <div>
                                        <img src={discover2} alt="discover2" style={{width:'737px'}}/>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div id="Panel_4___Board" className="Panel_4___Board">
                    <img src={pannel3_1} alt="p31"/>
                </div>
                
            </div>
        )
    }
}